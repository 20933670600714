import { Modal, Row } from 'antd'
import { FC } from 'react'
import { CenteredColumn } from '../../../components/CenteredColumn'
import {
  InputMaskForm,
  PrimaryButton,
  SecondaryButton,
} from '../../../global.styles'
import { useParticipantContext } from '../context/participant-context'
import { PinChecker } from '../../../components/pin-checker/pin-checker'
import { formatCellphone } from '../../../utilities/formatters'
import { useSelector } from 'react-redux'
import { RequestingSelector } from '@aberto/react-common'
import { REQUEST_CHANGE_CELLPHONE_NUMBER, REQUEST_FINISHE_CHANGE_CELLPHONE_NUMBER } from '../../../stores/participant/participant-action'
import { ApiException, isApiException } from '../../../api/promo-api'
import { CheckCircleOutlined } from '@ant-design/icons'

export enum ChangeParticipantCellphoneSteps {
  ValidationStep,
  OtpStep,
  SuccessAlert
}

const ValidationStepModalFooter: FC = () => {
  const participantContext = useParticipantContext()

  const isRequesting = useSelector(state =>
    RequestingSelector.selectRequesting(state, [
      REQUEST_CHANGE_CELLPHONE_NUMBER,
    ])
  )

  return (
    <Row>
      <CenteredColumn md={{ span: 6, offset: 5 }}>
        <PrimaryButton
          style={{ marginBottom: 0 }}
          onClick={participantContext.closeChangeCellphoneModal}
        >
          Cancelar
        </PrimaryButton>
      </CenteredColumn>
      <CenteredColumn md={{ span: 6, offset: 2 }}>
        <SecondaryButton
          disabled={participantContext.newCellphone.length < 11}
          style={{ marginBottom: 0 }}
          onClick={participantContext.goToOtpOnChangePasswordModal}
          loading={isRequesting}
        >
          Continuar
        </SecondaryButton>
      </CenteredColumn>
    </Row>
  )
}

const ValidationStepContent: FC = () => {
  const participantContext = useParticipantContext()

  const handleCellPhoneValue = (event: any) => {
    const newCellphone = event.target.value.replace(/\D/g, '')
    participantContext.setNewCellphone(newCellphone)
  }

  if (!participantContext.isChangeCellphoneModalOpened)
    return <></>

  return (
    <>
      <h4>Ao trocar o telefone, o historico de compra será perdido</h4>

      <CenteredColumn
        md={{ span: 12, offset: 6 }}
        style={{ flexDirection: 'column' }}
      >
        <p style={{ marginBottom: 0 }}>Novo número:</p>
        <InputMaskForm
          placeholder="(00) 00000-0000"
          mask="(00) 00000-0000"
          autoFocus={true}
          defaultValue={participantContext.newCellphone}
          value={participantContext.newCellphone}
          onChange={handleCellPhoneValue}
          allowClear
        />
        {isApiException(participantContext.changeCellphoneOrderResponse.error) && (
          <p style={{ color: "red", marginBottom: 0 }}>
            {participantContext.changeCellphoneOrderResponse.error.message?.message ||
              "Erro inesperado, favor tentar novamente em alguns minutos"}
          </p>
        )}
      </CenteredColumn>
    </>
  )
}

const OtpStepContent: FC = () => {
  const participantContext = useParticipantContext()

  const isRequesting = useSelector(state =>
    RequestingSelector.selectRequesting(state, [
      REQUEST_FINISHE_CHANGE_CELLPHONE_NUMBER,
    ])
  )

  const handleFinish = async (_data: any) => {
    let verificationCode = ''

    for (const key in _data) {
      verificationCode += _data[key]
    }

    participantContext.finalizeChangeCellphone(verificationCode)
  }

  return (
    <PinChecker
      setErrorMessage={console.log}
      errorMessage={participantContext.finalizeChangeCellphoneOrderResponse.error.message?.message}
      onFinish={handleFinish}
      text={'Validar código'}
      isLoading={isRequesting}
      cellPhone={formatCellphone(participantContext.newCellphone)}
      reducesize={null}
      resend={participantContext.resendVerificationCode}
    />
  )
}

const SuccessAlertFooter = () => {
  const participantContext = useParticipantContext()

  return (
    <Row>
      <CenteredColumn md={{ span: 6, offset: 9 }}>
        <PrimaryButton
          style={{ marginBottom: 0 }}
          onClick={participantContext.closeChangeCellphoneModal}
        >
          Fechar
        </PrimaryButton>
      </CenteredColumn>
    </Row>
  )
}

const steps = {
  [ChangeParticipantCellphoneSteps.ValidationStep]: {
    footer: ValidationStepModalFooter,
    content: ValidationStepContent,
    title: 'Deseja realmente trocar o telefone?',
  },
  [ChangeParticipantCellphoneSteps.OtpStep]: {
    footer: () => <></>,
    content: OtpStepContent,
    title: 'Insira o OTP enviada no número informado',
  },
  [ChangeParticipantCellphoneSteps.SuccessAlert]: {
    footer: SuccessAlertFooter,
    content: () => (
      <Row>
        <CenteredColumn md={{ span: 6, offset: 9 }}>
          <CheckCircleOutlined style={{ color: "green", fontSize: 40 }} />
        </CenteredColumn>
      </Row>
    ),
    title: 'Telefone alterado com sucesso!',
  },
}

export const ChangeParticiantCellphoneModal: FC = () => {
  const participantContext = useParticipantContext()

  const title = steps[participantContext.changePasswordModalStep].title
  const Content = steps[participantContext.changePasswordModalStep].content
  const Footer = steps[participantContext.changePasswordModalStep].footer

  return (
    <Modal
      open={participantContext.isChangeCellphoneModalOpened}
      title={title}
      footer={<Footer />}
      onCancel={participantContext.closeChangeCellphoneModal}
    >
      <Content />
    </Modal>
  )
}
