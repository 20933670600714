/**
 *
 * @param cellphone
 * @returns (00) 00000-0000
 */
export const formatCellphone = (cellphone: string) =>
  cellphone?.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')

/**
 *
 * @param cpf
 * @returns 000.000.000-00
 */
export const formatCPF = (cpf: string) =>
  cpf?.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4')

/**
 *
 * @param currency
 * @returns R$ 9.999,99 | R$ 0,00
 */
export const formatCurrency = (currency: number) =>
  currency?.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  }) || 'R$ 0,00'