import {
  Button,
  Col,
  Collapse,
  Row,
  Switch,
  Modal,
  notification,
  Spin,
} from 'antd';
import Page from '../../components/layout/page';
import { withOnlyAuthenticated } from '../../context/authentication-provider';
import styled, { css } from 'styled-components';
import { GroupTitle } from '@aberto/react-common';
import { DefaultListContent } from '../../components/styled-components/default-list-content';
import { useState, useEffect, useCallback } from 'react';
import { SalesList, SaleItem, useSales } from './useSales';
import { useGetTerm } from '../../api/queries/useGetTerm';

const { confirm } = Modal;
const { Panel } = Collapse;

const SaleToggle = styled(Switch)`
  ${({ theme }) => css`
    margin: 0px 2rem 0px 1rem;
    &.ant-switch-checked {
      background: ${theme['dark-blue']};
    }
  `}
`;

const PanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SaleTitle = styled.div`
  width: 100%;
`;

const SaleShowMore = styled(Button)`
  ${({ theme }) => css`
    &.ant-btn-link {
      background: ${theme['dark-blue']};
      color: ${theme['white']};
      border-radius: 9999px;
    }
    &.ant-btn-link:hover {
      background: ${theme['dark-blue']};
    }
  `}
`;

const Sales = () => {
  const { getSalesList, toggleSaleStatus } = useSales();
  const { data: termsData, isError } = useGetTerm();
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [salesList, setSalesList] = useState<SalesList>();

  useEffect(() => {
    setLoading(true);
    if (termsData && termsData.storeId) {
      getSalesList(termsData.storeId)
        .then(({ data }) => {
          setSalesList(data);
        })
        .finally(() => setLoading(false));
    }
  }, [termsData]);

  const handleSaleStatusChange = useCallback(
    (item: SaleItem, status: boolean) => {
      setConfirmLoading(true);

      const handleUpdateSaleState = (newStatus: boolean) => {
        setSalesList(prev => {
          if (prev) {
            const newPrev = { ...prev };
            newPrev.data[
              prev.data.findIndex(sale => item.saleId === sale.saleId)
            ].isParticipating = newStatus;

            return newPrev;
          }
        });
      };

      if (termsData && termsData.storeId) {
        handleUpdateSaleState(status);

        toggleSaleStatus(item.saleId, {
          status,
          storeId: termsData.storeId,
        })
          .catch(() => {
            handleUpdateSaleState(!status);

            notification.error({
              message: 'Houve um erro ao atualizar sua oferta.',
              duration: 2,
            });
          })
          .finally(() => {
            setConfirmLoading(false);
          });
      }
    },
    [termsData, toggleSaleStatus]
  );

  const handleSaleConfirm = useCallback(
    (
      status: boolean,
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      item: SaleItem
    ) => {
      event.stopPropagation();

      if (status) {
        handleSaleStatusChange(item, status);
      } else {
        confirm({
          open: confirmLoading,
          title: 'Desativar oferta',
          content: (
            <span>
              Deseja desativar a oferta <b>{item?.saleName}</b>?
            </span>
          ),
          okText: 'OK',
          cancelText: 'Cancelar',
          onOk() {
            handleSaleStatusChange(item, status);
          },
        });
      }
    },
    [confirmLoading, handleSaleStatusChange]
  );

  return (
    <>
      <Page>
        <Row>
          <Col
            md={{ span: 12, offset: 6 }}
            xs={24}
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <DefaultListContent>
              <GroupTitle>Ofertas</GroupTitle>

              <Spin
                spinning={loading}
                size="large"
                style={{ minHeight: '10vh' }}
              >
                <Collapse accordion>
                  {salesList &&
                    salesList.data.map((item: SaleItem, i) => (
                      <Panel
                        showArrow={false}
                        header={
                          <PanelHeader>
                            <SaleToggle
                              defaultChecked={item.isParticipating}
                              checked={item.isParticipating}
                              onChange={(status, event) =>
                                handleSaleConfirm(status, event, item)
                              }
                              disabled={confirmLoading}
                            />
                            <SaleTitle>
                              <h2 style={{ marginBottom: 0 }}>
                                {item.campaignName}
                              </h2>
                              <div>{item.saleName}</div>
                            </SaleTitle>
                            <SaleShowMore className="ant-btn-link">
                              Saiba mais
                            </SaleShowMore>
                          </PanelHeader>
                        }
                        key={i}
                      >
                        <p>{item.saleDescription}</p>
                      </Panel>
                    ))}
                </Collapse>
              </Spin>
            </DefaultListContent>
          </Col>
        </Row>
      </Page>
    </>
  );
};

export default withOnlyAuthenticated(Sales);
