import { environment } from '../../environments'
import promoApi from '../../api/promo-api'
import { EffectUtility } from '@aberto/react-common'
import qs from 'qs'

const endpoint = environment.promoApi.endpoints.order

export const createSaleEffect = async data => {
  return await EffectUtility.post(promoApi, endpoint.createOrder, data)
}

export const revertBenefit = async orderId => {
  return await EffectUtility.post(
    promoApi,
    endpoint.revertBenefit.replace(':orderId', orderId)
  )
}

export const searchOrdersEffect = async (pagination, filter, qsSort) => {
  const sort = [qsSort]

  const queryString = qs.stringify(
    { pagination, filter, sort },
    { allowDots: true, skipNulls: true }
  )

  return await EffectUtility.get(
    promoApi,
    `${endpoint.searchOrders}?${queryString}`
  )
}

export const createSaleFirstOrderEffect = async data => {
  return await EffectUtility.post(promoApi, endpoint.firstOrder, data)
}

export const calculateCashbackEffect = async data => {
  return await promoApi.post(endpoint.calculateCashback, data)
}

export const applyCashbackEffect = async data => {
  return await promoApi.post(endpoint.applyCashback.replace(':orderId', data))
}

export const createSaleAnonymousOrderEffect = async data => {
  console.log('data', data)
  return await EffectUtility.post(promoApi, endpoint.anonymous, data)
}

export const sendOrderEffect = async id => {
  return await EffectUtility.post(
    promoApi,
    endpoint.sendOrder.replace(':id', id)
  )
}

export const resendCodeEffect = async (id, resendType) => {
  return await EffectUtility.post(
    promoApi,
    `${endpoint.sendVerificationCode.replace(':id', id)}?type=${resendType}`
  )
}

export const resendCodeToManagerEffect = async (id, type) => {
  return await EffectUtility.post(
    promoApi,
    endpoint.sendVerificationCodeToManager
      .replace(':id', id)
      .replace(':type', type)
  )
}

export const checkOrderBonus = async (purchaseValue, bonusToUse, cellPhone) => {
  const queryString = qs.stringify(
    {
      purchaseValue,
      cellPhone,
      bonusToUse,
    },
    { skipNulls: true }
  )

  const response = await EffectUtility.get(
    promoApi,
    `${endpoint.checkOrderBonus}?${queryString}`
  )

  return response
}

export const validateCodeEffect = async request => {
  return await promoApi.post(endpoint.validate, request)
}

export const requestCheckBenefits = async purchasePrice => {
  const queryString = qs.stringify({ purchasePrice })

  return await EffectUtility.get(
    promoApi,
    `${endpoint.getOrderCheckBenefits}?${queryString}`
  )
}

export const getOrderByIdEffect = async id => {
  const queryString = qs.stringify({ id })

  return await EffectUtility.get(
    promoApi,
    `${endpoint.getOrderId.replace(':id', id)}?${queryString}`
  )
}
