import { useSelector } from 'react-redux'
import { OrderSummaryContainer, SumaryContent } from '../order.styles'
import { Col } from 'antd'
import { BallonMessage } from '../../../global.styles'
import { SpanBold } from '../../../components/styled-components/global.styled'

export const OrderSummary = ({
  visible,
  isLoading,
  registerSale,
  isLastStep,
  isError,
}) => {
  const order = useSelector(state => state.order.order)

  return (
    <>
      {isLoading ? (
        <SumaryContent visible={visible}>
          <BallonMessage>
            <OrderSummaryContainer gutter={20}>
              <Col span={24} lg={20}>
                <p>
                  <SpanBold>Carregando o bônus...</SpanBold>
                </p>
              </Col>
            </OrderSummaryContainer>
          </BallonMessage>
        </SumaryContent>
      ) : (
        <SumaryContent visible={visible}>
          <BallonMessage>
            <OrderSummaryContainer gutter={20}>
              <Col span={24} lg={20}>
                {isError && (
                  <>
                    <p>Por favor, repita a operação.</p>
                    <p>
                      Se o problema persistir, entre em contato com o suporte.
                    </p>
                  </>
                )}

                {isLastStep && !isError && (
                  <p style={{ fontSize: '20px !important' }}>
                    {order?.benefit?.burn?.value
                      ? 'Aplique o valor de bônus resgatado / desconto no sistema de frente de caixa.'
                      : 'Finalize a compra no sistema de frente de caixa.'}
                  </p>
                )}

                {!isLastStep && !isError && (
                  <>
                    <p style={{ fontSize: '20px !important' }}>
                      {order?.benefit?.messageContent?.replaceAll('@', ' ') || (
                        <>
                          <SpanBold>
                            {order?.benefit?.earn?.value?.toLocaleString(
                              'pt-br',
                              {
                                style: 'currency',
                                currency: 'BRL',
                              }
                            )}
                          </SpanBold>{' '}
                          para o cliente utilizar na próxima compra
                        </>
                      )}
                    </p>
                  </>
                )}
              </Col>
            </OrderSummaryContainer>
          </BallonMessage>
        </SumaryContent>
      )}
    </>
  )
}
