import moment from 'moment';
import { FC, createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Actions from '../../../stores/summary/summary-action';
import { Button, Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import * as OrderActions from '../../../stores/order/order-action';
import { useProfile } from '../../../context/authentication-provider';
import styled from 'styled-components';

type Pagination = {
  pageSize: number;
  pageNumber: number;
};

export const CustomDangerButton = styled(Button)`
  &.ant-btn {
    background-color: red;
    color: white;
  }

  &.ant-btn:hover {
    background-color: tomato;
    border-color: red;
  }

  &.ant-btn:focus {
    background-color: tomato;
    border-color: red;
  }
`;

type Filter = {
  participantId: string;
  storeId: string;
  initialRangeDate: moment.Moment;
  finalRangeDate: moment.Moment;
};

type TransactionListContextType = {
  filter: Filter;
  pagination: Pagination;
  setFilter: (filter: Filter) => void;
  setPagination: (pagination: Pagination) => void;
  openRevertOrderModal: (orderId: string) => void;
};

const TransactionListContext = createContext<TransactionListContextType>(
  {} as any
);

export const useTransactionListContext = () =>
  useContext(TransactionListContext);

export const TransactionListProvider: FC<{
  children: JSX.Element | JSX.Element[];
}> = ({ children }) => {
  const dispatch = useDispatch();

  const [isReversalModalOpen, setIsReversalModalOpen] = useState(false);
  const [revertOrderId, setRevertOrderId] = useState('');
  const profile = useProfile();

  const [filter, setFilter] = useState({
    participantId: '',
    storeId: '',
    initialRangeDate: moment().startOf('week'),
    finalRangeDate: moment().endOf('week'),
  });

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1,
  });

  useEffect(() => {
    if (filter.storeId.length > 0) {
      dispatch(
        Actions.getSummary({
          pagination,
          filter: {
            ...filter,
            initialRangeDate: filter.initialRangeDate.toISOString(),
            finalRangeDate: filter.finalRangeDate.toISOString(),
          },
        }) as any
      );
    }
  }, [dispatch, pagination, filter]);

  const openRevertOrderModal = (orderId: string) => {
    setRevertOrderId(orderId);
    setIsReversalModalOpen(true);
  };

  const revertBenefit = async () => {
    await dispatch(OrderActions.revertBenefit(revertOrderId) as any);
    setPagination({
      pageSize: 10,
      pageNumber: 1,
    });

    closeModal();
  };

  const closeModal = () => {
    setRevertOrderId('');
    setIsReversalModalOpen(false);
  };

  return (
    <TransactionListContext.Provider
      value={{
        pagination,
        filter,
        setFilter,
        setPagination,
        openRevertOrderModal,
      }}
    >
      {children}
      <Modal
        title="Estorno/Cancelamento de venda"
        open={isReversalModalOpen}
        footer={
          <>
            <Button type="primary" onClick={closeModal}>
              NÃO
            </Button>
            <CustomDangerButton onClick={revertBenefit}>SIM</CustomDangerButton>
          </>
        }
        onOk={revertBenefit}
        onCancel={closeModal}
      >
        <WarningOutlined style={{ color: 'red', fontSize: 20 }} />
        <h3>Deseja realmente Estornar/Cancelar essa venda?</h3>
      </Modal>
    </TransactionListContext.Provider>
  );
};
