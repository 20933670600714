import { withManagerProfileAllowed } from '../../../context/authentication-provider';
import { Col, DatePicker, Form, Row, Alert } from 'antd';
import {
  InputForm,
  InputMaskForm,
  PickerDateForm,
  PrimaryButton,
} from '../../../global.styles';
import Page from '../../../components/layout/page';
import { ShadowGroup } from './shadow-group';
import { GroupTitle } from '@aberto/react-common';
import { useDispatch } from 'react-redux';
import * as SellersActions from '../../../stores/sellers/seller-action';
import { push } from 'connected-react-router';
import RouteEnum from '../../../constants/RouteEnum';
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';
import { maskedDate } from '../../../helpers/date-mask-helper';
import React from 'react';

const CustomDatePicker = styled(DatePicker)`
  border-radius: 500px;
  width: 100%;
  border: 1px solid #001d42;
  margin-top: 4px;
`;

export type SellerForm = {
  document: string;
  name: string;
  cellphone: string;
  email: string;
};

const SellerForm = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [error, setError] = React.useState<string>('');

  const { sellerId } = useParams<{ sellerId: string }>();

  const removeAllNonNumberFromValue = (value: string) =>
    value.replace(/\D/g, '');

  const handleOnFinish = async (data: SellerForm) => {
    const normalizedData: SellerForm = {
      ...data,
      document: removeAllNonNumberFromValue(data.document),
      cellphone: removeAllNonNumberFromValue(data.cellphone),
    };

    let response;

    if (sellerId)
      response = await dispatch(
        SellersActions.updateSeller(sellerId, normalizedData) as any
      );
    else
      response = await dispatch(
        SellersActions.createSeller(normalizedData) as any
      );

    if (response) {
      dispatch(push(RouteEnum.SellerList));
    } else {
      setError('Houve um erro ao Cadastrar o colaborador');
    }
  };

  useEffect(() => {
    const runAsync = async () => {
      form.setFieldsValue({});

      if (sellerId) {
        const response = await dispatch(
          SellersActions.getById(sellerId) as any
        );

        if (!response) dispatch(push(RouteEnum.SellerList));

        form.setFieldsValue({
          ...response,
          birthDate: moment(response.birthDate),
        });
      }
    };

    runAsync();
  }, [dispatch]);

  return (
    <Page>
      <Row>
        <Col
          md={{ span: 22, offset: 1 }}
          xs={24}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <ShadowGroup>
            <Link to={RouteEnum.SellerList}>
              <ArrowLeftOutlined />
              Voltar para listagem
            </Link>

            <GroupTitle>Novo colaborador</GroupTitle>
            <Form form={form} onFinish={handleOnFinish}>
              <Row style={{ margin: '1.5rem 0px' }}>
                <Col span={24}>
                  {error.length > 0 && (
                    <Alert message={error} type="error" showIcon />
                  )}
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col md={12}>
                  <Form.Item
                    wrapperCol={{
                      span: 24,
                    }}
                    hasFeedback={true}
                    id="name"
                    name="name"
                    label="Nome"
                    rules={[
                      {
                        required: true,
                        message: 'O nome é obrigatório',
                      },
                    ]}
                    labelCol={{ span: 24 }}
                  >
                    <InputForm />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    wrapperCol={{
                      span: 24,
                    }}
                    hasFeedback={true}
                    id="cellphone"
                    name="cellphone"
                    label="Celular"
                    rules={[
                      {
                        required: true,
                        message: 'O telefone é obrigatório',
                      },
                      {
                        len: 11,
                        message: 'O telefone deve ter 11 dígitos',
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    normalize={removeAllNonNumberFromValue}
                  >
                    <InputMaskForm
                      placeholder="(00) 00000-0000"
                      mask="(00) 00000-0000"
                      autoFocus={true}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    wrapperCol={{
                      span: 24,
                    }}
                    hasFeedback={true}
                    id="document"
                    name="document"
                    label="CPF"
                    rules={[
                      {
                        required: true,
                        message: 'O CPF é obrigatório',
                      },
                      {
                        len: 11,
                        message: 'O CPF deve ter 11 dígitos',
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    normalize={removeAllNonNumberFromValue}
                  >
                    <InputMaskForm
                      placeholder="000.000.000-00"
                      mask="000.000.000-00"
                      autoFocus={true}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    wrapperCol={{
                      span: 24,
                    }}
                    id="email"
                    name="email"
                    label="Email"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        type: 'email',
                        message: 'Email inválido.',
                      },
                    ]}
                  >
                    <InputForm />
                  </Form.Item>
                </Col>

                <Col md={6}>
                  <Form.Item
                    wrapperCol={{
                      span: 24,
                    }}
                    id="birthDate"
                    name="birthDate"
                    label="Data de nascimento"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: 'Deve passar a data de nascimento.',
                      },
                    ]}
                  >
                    <CustomDatePicker
                      format={'DD/MM/YYYY'}
                      onKeyDown={maskedDate}
                    />
                  </Form.Item>
                </Col>

                <Col span={24 - 6} />

                <Col>
                  <PrimaryButton htmlType="submit">Finalizar</PrimaryButton>
                </Col>
              </Row>
            </Form>
            <br />
          </ShadowGroup>
        </Col>
      </Row>
    </Page>
  );
};

export default withManagerProfileAllowed(SellerForm);
