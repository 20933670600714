import styled from 'styled-components'
import {
  AppstoreOutlined,
  CaretUpOutlined,
  EditOutlined,
  MenuOutlined,
} from '@ant-design/icons'
import { Select, Tooltip } from 'antd'
import { InputFormCustom, InputMaskCustom } from '../../global.styles'

export const ContainerButtonHeader = styled.div`
  //display: flex;
  //justify-content: space-between;
`

export const MenuOutlinedIcon = styled(MenuOutlined)`
  font-size: 1.3rem;

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 600px) {
    display: none;
  }
`
export const AppstoreOutlinedIcon = styled(AppstoreOutlined)`
  font-size: 1.3rem;

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

export const CardContainerSwitchVisualization = styled.div`
  display: grid;
  margin-top: 20px;
  gap: ${props => (props.showlistactive ? '10px' : '0px')};
  grid-template-columns: ${props =>
    props.showlistactive ? 'repeat(2, 1fr)' : '1fr'};
}

@media (max-width: 900px) {
  grid-template-columns: 1fr;
  place-items: center;
`

export const CardContentBodySwitchVisualization = styled.div`
  display: grid;
  gap: 2px;
  grid-template-columns: ${props =>
    props.showlistactive ? '1fr' : 'repeat(2, 1fr)'};
}
`

export const CardSwitchVisualization = styled.div`
  position: relative;
  min-height: ${props => (props.showlistactive ? '80px' : '30px')};
  background: ${props => (props.showlistactive ? 'white' : 'transparent')};
  border-radius: 10px;
  box-shadow: ${props =>
    props.showlistactive ? 'rgba(0, 0, 0, 0.24) 0 3px 8px' : 'none'};

  &::before {
    content: '';
    width: 100%;
    display: ${props => (props.showlistactive ? 'none' : 'block')};
    border-bottom: 1px solid ${({ theme }) => theme['border-gray']};
  }

  @media (max-width: 900px) {
    width: 500px !important;
    max-width: 750px !important;
  }
  @media (max-width: 500px) {
    width: 320px !important;
  }
`

export const CardBodyList = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;

  span:nth-of-type(2) {
    font-weight: 300;
  }
`

export const CardContent = styled.div`
  padding: 10px 20px;
`

export const CardHeaderContent = styled.div`
  //gap: 10px;
  span:first-child {
    font-size: 1.125rem;
  }
`

export const ButtonEditParticipant = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`

export const ParticipantChecked = styled.div`
  padding: 0 5px;
  color: green;
`

export const ParticipantNoChecked = styled.div`
  padding: 0 5px;
  color: #ffa200;
`

export const ParticipantEdit = styled(EditOutlined)`
  color: orange;
  font-size: 12px !important;
`

export const TooltipVerified = styled(Tooltip)`
  margin-left: 5px;
  text-align: center;

  .ant-tooltip-inner {
    color: #001d42 !important;
  }
`

export const InputFormSearch = styled(InputFormCustom)`
  flex: 1;
  border: 2px solid #001d42;

  &:disabled {
    border: 2px solid #e6e6e6;
  }
`

export const SelectSearch = styled(Select)`
  flex: 1;
  border: 2px solid #001d42;
  border-radius: 13px;
  font-size: 10px !important;
  font-weight: lighter !important;
  font-family: ${({ theme }) => theme['font-regular']} !important;

  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
  }

  placeholder {
    font-size: 5px !important;
  }

  .ant-select {
  }
`

export const InputFormParticipant = styled(InputFormCustom)`
  flex: 1;

  ant-input:focus,
  .ant-input-focused {
    border-color: #40a9ff !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`
export const InputMaskCustomParticipant = styled(InputMaskCustom)`
  flex: 1;

  &:focus {
    border: 1px solid #40a9ff !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`

// export const SelectParticipant = styled(Select)`
//   flex: 1;
//   width: 100%;
//   border: 1px solid ${({theme}) => theme['dark-blue']} !important;
//   border-radius: 13px;
// `

export const CaretUpOutlinedIcon = styled(CaretUpOutlined)`
  color: red;
`
