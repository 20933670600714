export const externalConfig = {
  route: {
    baseRoute: '/',
  },
  dashboard: {
    store_id: '__{ATLAS_CHARTS_STORE_DASHBOARD_ID}__',
    chain_id: '__{ATLAS_CHARTS_CHAIN_DASHBOARD_ID}__',
    baseUrl: '__{ATLAS_CHARTS_BASE_URL}__',
  },
  authentication: {
    aws_project_region: '__{COGNITO_REGION}__',
    aws_cognito_identity_pool_id: '__{COGNITO_IDENTITY_POOL_ID}__',
    aws_cognito_region: '__{COGNITO_REGION}__',
    aws_user_pools_id: '__{COGNITO_IDENTITY_POOL_ID}__',
    aws_user_pools_web_client_id: '__{COGNITO_APP_CLIENT_ID}__',
  },
  promoApi: {
    baseUrl: '__{SELF_PROMO_BASE_URL}__',
    subscriptionKey: '__{APIM_SUBSCRIPTION_KEY}__',
  },
}
