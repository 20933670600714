import { FC, useContext, useEffect, useState } from 'react'
import { GroupTitle } from '@aberto/react-common'
import { Form, Row } from 'antd'
import { PrimaryButton } from '../../../global.styles'
import { ColNewOrder, NewOrderButtonContainer } from '../order.styles'
import { WrapOnlyShadow } from './steps-style'
import { OrderStepContext } from './context/order-step-context'
import { useDispatch } from 'react-redux'
import { InputForm } from '../../../components/styled-components/global.styled'
import { createParticipant } from '../../../stores/participant/participant-action'
import {
  activeBonus,
  currentParticipant,
} from '../../../selectors/participant/participant-selector'
import { useSelector } from 'react-redux'
import {
  createOrder,
  REQUEST_CREATE_ORDER,
} from '../../../stores/order/order-action'
import { push } from 'connected-react-router'
import RouteEnum from '../../../constants/RouteEnum'
import { RequestingSelector } from '@aberto/react-common'

const UserInfoStep: FC = () => {
  const dispatch = useDispatch()
  const orderStepContext = useContext(OrderStepContext)

  const participant = useSelector(state => currentParticipant(state)) as any

  const [form] = Form.useForm()

  const onFinish = (data: any) => {
    dispatch(
      createParticipant({
        ...data,
      }) as any
    )

    dispatch(push(RouteEnum.BenefitSummary))
  }

  const handleGoToPinVerification = async (sellerName: string) => {
    const orderId = await dispatch(
      createOrder({
        value: orderStepContext.purchasePrice,
        cellPhone: orderStepContext.cellphone,
        sellerName,
        useBonus: orderStepContext.bonusToUse !== '',
        bonusToUse: orderStepContext.bonusToUse,
      }) as any
    )

    orderStepContext.setOrderId(orderId)

    dispatch(push(RouteEnum.UserPinOrderStep))
  }

  const isLoading = useSelector(state =>
    RequestingSelector.selectRequesting(state, [REQUEST_CREATE_ORDER])
  )

  return (
    <Form form={form} onFinish={onFinish} style={{ marginTop: 50 }}>
      <WrapOnlyShadow>
        <GroupTitle>Para finalizar...</GroupTitle>

        <Row gutter={20}>
          <ColNewOrder
            xs={{ span: 20, offset: 2 }}
            span={24}
            md={16}
            offset={5}
          >
            <Form.Item
              hasFeedback={false}
              initialValue={participant?.name}
              id="name"
              name="name"
              label="Nome do cliente"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputForm autoFocus={true} />
            </Form.Item>
          </ColNewOrder>

          <ColNewOrder
            xs={{ span: 20, offset: 2 }}
            span={24}
            md={16}
            offset={5}
          >
            <Form.Item
              hasFeedback={false}
              id="document"
              name="document"
              initialValue={participant?.document}
              label="CPF do cliente"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputForm />
            </Form.Item>
          </ColNewOrder>

          <ColNewOrder
            xs={{ span: 20, offset: 2 }}
            span={24}
            md={16}
            offset={5}
          >
            <Form.Item
              hasFeedback={false}
              id="cellPhone"
              name="cellPhone"
              initialValue={participant.cellPhone}
              label="Celular do cliente"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputForm />
            </Form.Item>
          </ColNewOrder>
        </Row>
        <Row>
          <ColNewOrder md={{ span: 8, offset: 8 }}>
            <NewOrderButtonContainer alignCenter>
              <PrimaryButton
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Finalizar
              </PrimaryButton>
            </NewOrderButtonContainer>
          </ColNewOrder>
        </Row>
      </WrapOnlyShadow>
    </Form>
  )
}

export default UserInfoStep
