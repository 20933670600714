import { FC, useEffect, useMemo, useRef, useState } from 'react'
import ChartsEmbedSdk, {
  Dashboard as DashboardSdk,
  EmbedDashboardOptions,
} from '@mongodb-js/charts-embed-dom'
import { Auth } from 'aws-amplify'
import Page from '../../components/layout/page'
import { Col, Row } from 'antd'
import { DashboardFilter, Filter } from './components/dashboard-filter'
import moment from 'moment'
import { environment } from '../../environments'
import {
  useProfile,
  withProfileAllowed,
} from '../../context/authentication-provider'

const DashboardComponent: FC = () => {
  const dashboardContainerRef = useRef<any>()

  const [user, setUser] = useState<any>()
  const [dashboard, setDashboard] = useState<DashboardSdk>()

  const profile = useProfile()

  const [filter, setFilter] = useState<Filter>({
    initialDateRange: moment().startOf('month'),
    finalDateRange: moment().endOf('month'),
  })

  const getDashboardFilters = () => {
    const currentFilter: any = {}

    currentFilter['createdDate'] = {
      $gte: {
        $date: (filter.initialDateRange
          ? filter.initialDateRange
          : moment().startOf('month')
        ).toISOString(true),
      },
      $lte: {
        $date: (filter.finalDateRange
          ? filter.finalDateRange
          : moment().endOf('month')
        ).toISOString(true),
      },
    }

    if (filter.storeId) currentFilter['storeId'] = filter.storeId

    return currentFilter
  }

  const embedSdk = useMemo(
    () =>
      new ChartsEmbedSdk({
        baseUrl: environment.dashboard.baseUrl,
        maxDataAge: 300,
        getUserToken: () => {
          return user?.signInUserSession?.idToken?.jwtToken
        },
      }),
    [user]
  )

  const getDashboardOptions = (): Partial<EmbedDashboardOptions> => {
    let dashboardId: null | string

    if (profile == 'Owner')
      dashboardId = filter.storeId
        ? environment.dashboard.store_id
        : environment.dashboard.chain_id
    else dashboardId = environment.dashboard.store_id

    return {
      dashboardId: dashboardId,
      autoRefresh: true,
      showAttribution: false,
      background: 'transparent',
      filter: getDashboardFilters(),
      heightMode: 'scale',
      widthMode: 'scale',
    }
  }

  useEffect(() => {
    if (dashboard)
      dashboard.render(dashboardContainerRef.current).catch(console.log)
  }, [dashboard])

  useEffect(() => {
    ;(async function () {
      const user = await Auth.currentAuthenticatedUser()

      setUser(user)
    })()
  }, [])

  useEffect(() => {
    if (embedSdk) {
      setDashboard(embedSdk.createDashboard(getDashboardOptions()))
    }
  }, [embedSdk, profile, filter])

  return (
    <Page>
      <DashboardFilter filter={filter} setFilter={setFilter} />
      <Row>
        <Col
          md={{ span: 24 }}
          xs={24}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <div
            ref={dashboardContainerRef}
            style={{ height: window.innerHeight, width: '100%' }}
          />
        </Col>
      </Row>
    </Page>
  )
}

export const Dashboard = DashboardComponent
