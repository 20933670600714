import Page from '../../components/layout/page';
import { Spin } from 'antd';
import styled from 'styled-components';
import { useSellersAvailable } from './useSellersAvailable';
import React from 'react';

export const Input = styled.input`
  height: var(--default-height);
  border-radius: var(--default-height);
  border: 1px solid var(--brand);
  padding: 0px 1rem;
  width: 100%;
  margin-bottom: 2rem;
  outline: none;
`;

const SellersAvailable = () => {
  const { sellersAvailable, isLoading, hasError } = useSellersAvailable();
  const [search, setSearch] = React.useState('');

  return (
    <Page>
      <Spin spinning={isLoading} size="large" style={{ minHeight: '10vh' }}>
        <div className="sellers-available-page">
          {hasError ? (
            <div className="error-container">
              Não foi possível encontrar a Loja participante.
            </div>
          ) : (
            <div className="container">
              <div className="sale">
                {sellersAvailable?.chainImage && (
                  <img
                    src={sellersAvailable?.chainImage}
                    alt={sellersAvailable?.saleName}
                  />
                )}
                <h2>{sellersAvailable?.saleName}</h2>
              </div>
              <h1>Confira as lojas participantes</h1>
              <div className="content">
                <Input
                  type="search"
                  name="search"
                  placeholder="Busque por Estado, Cidade ou Unidade"
                  autoFocus
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
                <table className="table-sellers-available">
                  <thead>
                    <th style={{ width: '10%', textAlign: 'center' }}>
                      Estado
                    </th>
                    <th style={{ width: '30%' }}>Cidade</th>
                    <th style={{ width: '50%' }}>Unidade</th>
                    <th style={{ width: '10%' }}></th>
                  </thead>
                  <tbody id="table-body">
                    {sellersAvailable?.stores
                      .filter(
                        filter =>
                          filter.name.includes(search) ||
                          filter.address.state.includes(search) ||
                          filter.address.city.includes(search) ||
                          search === ''
                      )
                      .map((store, i) => (
                        <tr key={i}>
                          <td className="text-center">{store.address.state}</td>
                          <td>{store.address.city}</td>
                          <td>{store.name}</td>
                          <td className="action">
                            <a
                              href={`https://maps.google.com/?q=${store.location.latitude},${store.location.longitude}`}
                              className="marker"
                              target="_blank"
                              rel="noreferrer"
                            ></a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </Spin>
    </Page>
  );
};

export default SellersAvailable;
