import styled, { createGlobalStyle } from 'styled-components'
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Pagination,
  Radio,
  Select,
  Switch,
} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { MaskedInput } from 'antd-mask-input'
import IntlCurrencyInput from 'react-intl-currency-input'
import InputMask from 'react-input-mask'

export const GlobalStyle = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }



  html {
    @media (max-width: 900px) {
      font-size: 90%;
    }

    @media (max-width: 500px) {
      font-size: 80%;
    }
  }

  h1, h2, h3, h4, h5, h6, strong, b {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: ${({ theme }) => theme['dark-blue']};
  }

  .ant-checkbox-input:focus {
    outline-color: black;
  }

  .ant-input:hover {
      //border-color: ${({ theme }) => theme.green} !important;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    color: ${({ theme }) => theme['dark-blue']} !important;
    font-weight: lighter !important;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    display: flex !important;
    align-items: center !important;
    gap: 5px;
  }

  .ant-select-item-option-content {
    display: flex !important;
    align-items: center !important;
    gap: 5px;
  }

  #site .ant-btn span {
    font-weight: lighter !important;
  }


`

export const ContainerGlobal = styled.div`
  margin: 0 auto;
  max-width: 700px;
  height: 100%;
`

export const SwitchPromo = styled(Switch)`
  .ant-switch {
    background-image: none !important;
  }

  .ant-switch-handle::before {
    background-color: ${({ theme }) => theme.black};
  }
`

export const RadioGroupPromo = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  gap: 30px;

  .ant-radio-wrapper {
    color: ${({ theme }) => theme.white} !important;
    font-size: 0.9rem !important;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${({ theme }) => theme.green} !important;
  }

  .ant-radio-inner::after {
    background-color: ${({ theme }) => theme.green} !important;
  }

  .ant-radio-inner {
    background-color: ${({ theme }) => theme['dark-blue']} !important;
  }
`

// Buttons

export const BackButton = styled(ArrowLeftOutlined)`
  font-size: 20px;
  color: ${({ theme }) => theme['dark-blue']} !important;
  margin-bottom: 15px;
`

export const BackButtonText = styled.span`
  color: ${({ theme }) => theme['dark-blue']} !important;
`

export const PrimaryButton = styled(Button)`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 30px !important;
  min-width: 9.5rem !important;
  margin-bottom: 10px;
  border-radius: 40px !important;
  background: ${({ theme }) => theme['dark-blue']} !important;
  border: 2px solid ${({ theme }) => theme['dark-blue']} !important;
  color: ${({ theme }) => theme.white} !important;
  font-size: 0.875rem !important;

  &:hover {
    scale: 1.05;
  }

  &:focus {
    border-color: #40a9ff !important;
    box-shadow: 0 0 0 4px rgba(172, 187, 210, 0.2) !important;
  }

  &:disabled {
    background: ${({ theme }) => theme['border-gray']} !important;
    scale: 1;
    color: ${({ theme }) => theme['dark-blue']} !important;
  }

  @media (max-width: 768px) {
    padding: 0 9rem !important;
  }
`

export const SecondaryButton = styled(PrimaryButton)`
  background: ${({ theme }) => theme.white} !important;
  color: ${({ theme }) => theme['dark-blue']} !important;
  border: 1px solid ${({ theme }) => theme['dark-blue']} !important;
`

export const OtherButton = styled(Button)`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 30px !important;
  min-width: 100px !important;
  margin-bottom: 20px !important;
  border-radius: 40px !important;
  padding: 0 30px !important;
  margin-right: 20px;
  font-size: 0.875rem !important;
  font-weight: normal !important;
`

export const PlusButton = styled(Button)`
  height: 30px !important;
  padding: 10px 30px !important;
  width: 180px;
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  color: #27f3b5 !important;
  font-size: 13px !important;
  font-weight: 100 !important;
  margin: 10px auto;
`

export const Links = styled(PrimaryButton)`
  background: transparent !important;
  color: ${({ theme }) => theme['dark-blue']} !important;
  border: 1px solid ${({ theme }) => theme.white} !important;
  box-shadow: none !important;

  &:hover {
    scale: 1;
    opacity: 0.8;
  }
`

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px auto;
  flex-direction: column;
`

export const ContainerButtonForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 10px;
  margin: 20px auto;
`

export const CenterContainerButton = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
`

export const LeftContainerButton = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 15px;
`

export const RightContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`

export const FullWidthContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
`

// Filter

export const FilterDrawer = styled(Drawer)`
  .ant-drawer-header {
    background: #001c3f;
    color: #fff !important;
    font-weight: 400 !important;
  }

  .ant-drawer-title {
    color: #fff !important;
    font-weight: 400 !important;
  }

  .ant-drawer-wrapper-body {
    background: ${({ theme }) => theme.background};
  }

  .ant-form-item {
    margin-bottom: 8px !important;
  }

  .ant-btn {
    border-radius: 13px;
  }

  .ant-drawer-close {
    color: #fff;
    text-align: right;
    order: 2 !important;
    margin-right: 0 !important;
  }

  .ant-form-item-label > label {
    font-weight: 500;
    color: #001d42;
    display: flex;
    justify-content: space-between;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    color: blue !important;
  }

  .ant-picker {
    border: 1px solid #001d42;
    border-radius: 13px;
    color: #fff !important;
    height: 50px;
  }
`

export const FilterButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
`

export const ContainerButtonSearch = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

// Inputs

export const FormPromo = styled(Form)`
  .ant-form-item {
    margin-bottom: 10px !important;
  }
`

export const InputMaskForm = styled(MaskedInput)`
  align-items: flex-end !important;
  text-align: center !important;
  justify-content: center !important;
  align-items: center !important;
  float: center !important;
  background: #ffffff;
  border-radius: 13px;
  border: 1px solid #001d42;
  width: 100%;
  height: 30px;
  margin: 5px 0 !important;
  padding-left: 15px;
  color: #001d42;
  font-size: 0.9rem !important;
  flex: 1;

  &::placeholder {
    font-size: 0.875rem !important;
    font-family: 'Poppins', sans-serif !important;
    color: #c1bfbf;
  }
`

export const InputFormCustom = styled(Input)`
  background: #ffffff;
  border-radius: 13px;
  border: 1px solid ${({ theme }) => theme['dark-blue']};
  width: 100%;
  height: 30px;
  font-size: 0.9rem;
  font-weight: lighter !important;
  margin: 5px 0 !important;
  text-align: left;
  padding-left: 15px;

  &::placeholder {
    font-size: 0.875rem !important;
    font-family: 'Poppins', sans-serif !important;
    color: #c1bfbf;
  }

  &:disabled {
    border: 1px solid #e6e6e6;
    background: #f5f5f5 !important;
    color: #001d42;
  }
`

export const InputForm = styled(Input)`
  background: #ffffff;
  border-radius: 13px;
  border: 1px solid #001d42;
  min-width: 250px;
  height: 30px;
  font-size: 0.9rem;
  font-weight: lighter !important;
  margin: 5px 0 !important;
  text-align: left;
  padding-left: 15px;
  width: 100%;
  flex: 1;

  &::placeholder {
    font-size: 13px !important;
    font-family: 'Poppins', sans-serif;
  }

  &:disabled {
    border: 1px solid #e6e6e6;
    background: #f5f5f5 !important;
    color: #001d42;
  }
`

export const PasswordInputForm = styled(Input.Password)`
  background: #ffffff;
  border-radius: 13px;
  border: 1px solid #001d42;
  min-width: 250px;
  height: 30px;
  font-size: 0.9rem;
  font-weight: lighter !important;
  margin: 5px 0 !important;
  text-align: left;
  padding-left: 15px;
  width: 100%;
  flex: 1;

  &::placeholder {
    font-size: 13px !important;
    font-family: 'Poppins', sans-serif;
  }

  &:disabled {
    border: 1px solid #e6e6e6;
    background: #f5f5f5 !important;
    color: #001d42;
  }
`

export const CurrencyInput = styled(IntlCurrencyInput)`
  background: #ffffff;
  border-radius: 13px;
  border: 1px solid ${({ theme }) => theme['dark-blue']};
  width: 100%;
  height: 30px;
  font-size: 0.9rem;
  font-weight: lighter !important;
  margin: 5px 0 !important;
  text-align: left;
  padding-left: 15px;

  &::placeholder {
    font-size: 0.875rem !important;
    font-family: 'Poppins', sans-serif !important;
    color: #c1bfbf;
  }

  &:disabled {
    border: 1px solid #e6e6e6;
    background: #f5f5f5 !important;
    color: #001d42;
  }
`

export const SelectCustom = styled(Select)`
  background: #ffffff;
  border-radius: 13px;
  border: 1px solid #001d42;
  width: 100%;
  height: 30px;
  margin: 5px 0 !important;
  text-align: left;
  padding-left: 15px;
  color: black;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500 !important;

  &::placeholder {
    color: #c1bfbf;
  }

  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    font-family: 'Poppins', sans-serif;
    color: #001d42;

    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }
`

export const SelectCustomWithoutBorder = styled(Select)`
  height: 30px;
  text-align: left;
  color: ${({ theme }) => theme['dark-blue']};
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500 !important;
  min-width: 150px;
  flex: 1;
`

export const PickerDateForm = styled(DatePicker)`
  border: none !important;
`

export const BallonMessage = styled.div`
  display: flex;
  gap: 0.5rem;
  color: #001d42;
  font-size: 12px;
  font-weight: lighter;
  line-height: 0.8;
  background: #f3f3f3;
  border-radius: 13px;
  margin: 25px 0;
  padding: 10px;
  box-shadow: 0px 0px 10px #00000029;

  justify-content: center;
`

export const PaginationGeral = styled(Pagination)`
  //width: 100% !important;
  margin-top: 30px;
  text-align: right;
`

export const GroupSubtitle = styled.h5`
  font-weight: 700;
  font-size: 16px !important;
  white-space: nowrap;
  padding-right: 20px;
  display: flex;
  align-items: center;
  margin: 40px 0 20px;

  &::after {
    content: '';
    background: var(--tb-green) !important;
    display: block;
    height: 1px !important;
    width: 100%;
    margin-left: 10px;
    min-width: 10px;
  }
`

export const InputMaskCustom = styled(InputMask)`
  background: #ffffff;
  border-radius: 13px;
  border: 1px solid #001d42;
  width: 100%;
  height: 30px;
  margin: 5px 0 !important;
  text-align: left;
  padding-left: 15px;
  color: #001d42;
  font-size: 0.9rem;

  &::placeholder {
    font-size: 13px !important;
    font-family: 'Poppins', sans-serif;
    color: #c1bfbf;
  }

  &:disabled {
    border: 1px solid #e6e6e6;
    background: #f5f5f5 !important;
    color: #001d42;
    cursor: no-drop;
  }
`

export const SpanBold = styled.span`
  font-weight: bold;
`
