import { FC } from 'react'
import { Button, Col, Row } from 'antd'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import * as ParticipantActions from '../../../stores/participant/participant-action'
import { RequestingSelector } from '@aberto/react-common'
import {
  REQUEST_GET_STATEMENTS,
  REQUEST_GET_STATEMENTS_NEXT_PAGE,
} from '../../../stores/participant/participant-action'

export const ParticipantTransactionListTablePagination: FC = () => {
  const currentPage = useSelector(
    (state: any) => state.participant.statements.page
  )

  const hasContentOnNextPage = useSelector(
    (state: any) => state.participant.statements.nextPageData?.length > 0
  )

  const isRequestingStatements = useSelector((state: any) =>
    RequestingSelector.selectRequesting(state, [
      REQUEST_GET_STATEMENTS,
      REQUEST_GET_STATEMENTS_NEXT_PAGE,
    ])
  )

  const dispatch = useDispatch()

  const handleOnClickNextPage = () => {
    dispatch(ParticipantActions.setStatementsPagination(currentPage + 1) as any)
    dispatch(ParticipantActions.getStatements() as any)
  }

  const handleOnClickPreviousPage = () => {
    if (currentPage == 1) return
    dispatch(ParticipantActions.setStatementsPagination(currentPage - 1) as any)
    dispatch(ParticipantActions.getStatements(true) as any)
  }

  return (
    <Row
      style={{
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: '10px',
      }}
    >
      <Col span={1}>
        <Button
          type="text"
          disabled={!hasContentOnNextPage || isRequestingStatements}
          icon={<RightOutlined />}
          onClick={handleOnClickNextPage}
        />
      </Col>
      <Col md={3} xs={10} style={{ textAlign: 'center' }}>
        <Button style={{ marginLeft: '5px' }} type="primary">
          {currentPage}
        </Button>
        {hasContentOnNextPage && !isRequestingStatements && (
          <Button style={{ marginLeft: '5px' }} onClick={handleOnClickNextPage}>
            {currentPage + 1}
          </Button>
        )}
      </Col>
      <Col span={1}>
        <Button
          type="text"
          icon={<LeftOutlined />}
          disabled={isRequestingStatements}
          onClick={handleOnClickPreviousPage}
        />
      </Col>
    </Row>
  )
}
