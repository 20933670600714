import { FC, useContext, useEffect, useState } from 'react'
import { RequestingSelector } from '@aberto/react-common'
import { GroupTitle } from '@aberto/react-common'
import { Col, Form, Row, Spin } from 'antd'
import {
  InputMaskForm,
  PrimaryButton,
  SelectCustom,
} from '../../../global.styles'
import { ColNewOrder, NewOrderButtonContainer } from '../order.styles'
import { CustomGroupTitle, WrapCenter } from './steps-style'
import { OrderStepContext } from './context/order-step-context'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import RouteEnum from '../../../constants/RouteEnum'
import * as OrderActions from '../../../stores/order/order-action'
import * as ParticipantActions from '../../../stores/participant/participant-action'
import { isApiException } from '../../../api/promo-api'
import * as SellerActions from '../../../stores/sellers/seller-action'
import { IdentificationInfoResponse } from '../../../stores/sellers/seller-reducer'
import {
  getCurrentSellerSelected,
  setCurrentSellerSelected,
} from '../../../utilities/seller-store'
import {
  ParticipantKeyType,
  useGetStoreConfigurations,
} from '../../../api/queries/useGetStoreConfigurations'

const CellphoneStep: FC = () => {
  const { data: storeConfigurations, isLoading: storeConfigurationsIsLoading } =
    useGetStoreConfigurations()

  const dispatch = useDispatch()
  const orderStepContext = useContext(OrderStepContext)
  const [selectedSeller, setSelectedSeller] = useState(
    getCurrentSellerSelected()
  )

  const isGettingParticipant = useSelector(state =>
    RequestingSelector.selectRequesting(state, [
      ParticipantActions.REQUEST_GET_BY_CELL_PHONE,
    ])
  )

  const [form] = Form.useForm()

  const handleCellPhoneValue = (event: any) => {
    let cellPhone = event.target.value.replace(/\D/g, '')

    orderStepContext.setCellphone(cellPhone)
  }

  const goToValueStep = async () => {
    dispatch(OrderActions.clearOrder() as any)
    dispatch(ParticipantActions.clearParticipant() as any)
    orderStepContext.clearState()

    if (orderStepContext.isCellphoneValid()) {
      const result = await dispatch(
        ParticipantActions.getByCellPhone(orderStepContext.cellphone) as any
      )

      if (isApiException(result)) {
        orderStepContext.setApiError()
        return
      }

      dispatch(push(RouteEnum.CheckValueOrderStep))
    }
  }

  useEffect(() => {
    dispatch(SellerActions.getIdentificationInfo() as any)
  }, [])

  const identificationInfos = useSelector(
    (state: any) => state.sellers.identificationInfo
  ) as IdentificationInfoResponse[]

  const handleOnChangeSeller = (sellerId: any) => {
    setCurrentSellerSelected(sellerId)

    setSelectedSeller(sellerId)
  }

  return (
    <Form form={form} onFinish={goToValueStep}>
      <WrapCenter style={{ top: '50%' }}>
        <GroupTitle>Nova venda</GroupTitle>

        <Row>
          <ColNewOrder md={{ span: 10, offset: 8 }} xs={24}>
            <CustomGroupTitle>
              Quem está realizando esta venda?
            </CustomGroupTitle>
            <SelectCustom
              onChange={handleOnChangeSeller}
              value={selectedSeller}
              options={identificationInfos.map(x => ({
                label: x.name,
                value: x.id,
              }))}
            />
          </ColNewOrder>
        </Row>

        <br />
        <br />

        {storeConfigurationsIsLoading ? (
          <Spin />
        ) : (
          <>
            <CustomGroupTitle>
              Insira o{' '}
              {storeConfigurations?.participantKeyType ===
              ParticipantKeyType.Cellphone
                ? 'Celular'
                : 'CPF'}{' '}
              do cliente
            </CustomGroupTitle>

            <Row gutter={20}>
              <ColNewOrder
                md={{ span: 10, offset: 8 }}
                style={{ padding: '5px 0' }}
              >
                {storeConfigurations?.participantKeyType ===
                ParticipantKeyType.Cellphone ? (
                  <Form.Item
                    hasFeedback={false}
                    id="cellPhone"
                    name="cellPhone"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <InputMaskForm
                      placeholder="(00) 00000-0000"
                      mask="(00) 00000-0000"
                      autoFocus={true}
                      onChange={handleCellPhoneValue}
                      allowClear
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    hasFeedback={false}
                    id="cellPhone"
                    name="document"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <InputMaskForm
                      placeholder="000.000.000-00"
                      mask="000.000.000-00"
                      autoFocus={true}
                      onChange={handleCellPhoneValue}
                      allowClear
                    />
                  </Form.Item>
                )}
              </ColNewOrder>
            </Row>
          </>
        )}
        <Row>
          <ColNewOrder md={{ span: 10, offset: 8 }}>
            <NewOrderButtonContainer alignCenter>
              <PrimaryButton
                loading={isGettingParticipant}
                disabled={!orderStepContext.isCellphoneValid()}
                onClick={goToValueStep}
              >
                Continuar
              </PrimaryButton>
            </NewOrderButtonContainer>
          </ColNewOrder>
        </Row>
      </WrapCenter>
    </Form>
  )
}

export default CellphoneStep
