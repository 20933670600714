import { Col, Collapse, Row } from 'antd';
import Page from '../../components/layout/page';
import { withOnlyAuthenticated } from '../../context/authentication-provider';
import styled from 'styled-components';
import { GroupTitle } from '@aberto/react-common';
import { DefaultListContent } from '../../components/styled-components/default-list-content';
import { push } from 'connected-react-router';
import RouteEnum from '../../constants/RouteEnum';
import { useDispatch } from 'react-redux';

const { Panel } = Collapse;

const OlStyled = styled.ol`
  margin-left: 20px;
`;

const LiStyled = styled.ul`
  margin-left: 20px;
`;

const Faq = () => {
  const dispatch = useDispatch();

  return (
    <Page>
      <Row>
        <Col
          md={{ span: 12, offset: 6 }}
          xs={24}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <DefaultListContent>
            <GroupTitle>FAQ</GroupTitle>

            <Collapse accordion>
              <Panel header="O que é Cashback?" key="1">
                <p>
                  Cashback é um valor de desconto dado ao cliente para ser usado
                  em uma próxima compra. O cliente paga um valor e &quot;recebe
                  de volta&quot; um percentual desse valor. Um exemplo: o
                  cliente faz uma compra de R$100,00 na primeira vez, o
                  colaborador o registra na plataforma VERTEM FRANQUIAS e avisa
                  o cliente que ele terá um valor de 5% dessa compra para usar
                  em uma próxima compra - no caso, R$5,00. O valor de % desconto
                  é determinado pela franquia/loja participante, assim como o
                  valor mínimo necessário para gerar o cashback. Consulte seu
                  gerente.
                </p>
              </Panel>
              <Panel header="O que é Token?" key="2">
                <p>
                  Token é uma chave (senha) de segurança de seis dígitos
                  aleatórios, que será enviada ao cliente por SMS para confirmar
                  a identidade do cliente no momento do primeiro cadastro.
                  Assegurando a validação das informações no sistema.
                </p>
              </Panel>
              <Panel
                header="O cliente não recebeu o Token, o que eu faço?"
                key="3"
              >
                <p>
                  Se o cliente não recebeu o Token, primeiro, confirme se ele já
                  não está cadastrado no sistema. Caso não esteja, verifique com
                  o cliente se ele bloqueou as notificações do site ou
                  aplicativo. Se o cliente afirma que não bloqueou as
                  notificações e ainda não recebeu o Token, é possível que ele
                  tenha sido perdido ou excluído. Nesse caso, você pode gerar um
                  novo Token e enviá-lo para o cliente por e-mail ou WhatsApp.
                  Se o problema persistir, anote as informações do cliente e
                  entre em contato com o Suporte.
                </p>
              </Panel>
              <Panel
                header="Como faço para gerar cashback para o meu cliente?"
                key="4"
              >
                <OlStyled>
                  <li>
                    Acesse a plataforma VERTEM FRANQUIAS usando suas
                    credenciais;
                  </li>
                  <li>
                    Na página inicial, localize e clique na opção
                    &quot;Início&quot;;
                  </li>
                  <li>
                    Insira o número de telefone do cliente no campo indicado;
                  </li>
                  <li>
                    Informe e insira na plataforma o valor total da compra
                    realizada pelo cliente;{' '}
                  </li>
                  <li>
                    Clique na opção &quot;Calcular bônus do cliente&quot; para
                    determinar o valor do cashback;
                  </li>
                  <li>
                    Digite o código enviado para o número do cliente por meio de
                    SMS;
                  </li>
                  <li>
                    Clique em &quot;Validar código&quot; para confirmar e
                    aplicar o cashback;
                  </li>
                  <li>
                    Após validar o código, o cashback será aplicado com sucesso,
                    e a venda estará finalizada.
                  </li>
                </OlStyled>
              </Panel>
              <Panel
                header="Como resgatar o cashback do cliente em uma nova compra?"
                key="5"
              >
                <OlStyled>
                  <li>
                    Na página inicial, localize e clique na opção
                    &quot;Início&quot;;
                  </li>
                  <li>Insira (telefone) do cliente no campo indicado;</li>
                  <li>Selecione a aba &quot;ATIVAR RESGATE DE BÔNUS&quot;;</li>
                  <li>
                    Encontre e clique na aba destinada para utilização do saldo
                    disponível;
                  </li>
                  <li>
                    Informe corretamente o valor total da compra realizada pelo
                    cliente;
                  </li>
                  <li>
                    Execute a operação clicando no botão designado para calcular
                    o bônus;
                  </li>
                  <li>
                    Clique em &quot;Validar código&quot; para confirmar e
                    aplicar o cashback;
                  </li>
                  <li>
                    Após validar o código, o cashback será aplicado com sucesso
                    e descontado do valor final da compra, e a venda estará
                    finalizada.
                  </li>
                </OlStyled>
              </Panel>
              <Panel
                header="Como alterar o telefone cadastrado de um cliente?"
                key="6"
              >
                <p>
                  Para modificar o telefone cadastrado de um cliente, siga estes
                  passos:
                </p>

                <ol>
                  <li>Acesse a aba “CLIENTES”;</li>
                  <li>
                    No campo buscar, pesquise pelo nome do cliente que o
                    telefone precisa ser alterado;
                  </li>
                  <li>
                    Na frente do nome do cliente, clique na opção para alterar o
                    telefone do cliente;
                  </li>
                  <li>Insira o novo número de telefone desejado;</li>
                  <li>Clique em &quot;Salvar&quot;.</li>
                </ol>
                <p>
                  Esses passos garantirão a atualização bem-sucedida do telefone
                  cadastrado para o cliente em questão.{' '}
                </p>
              </Panel>
              <Panel
                header="Como faço para acompanhar as vendas realizadas?"
                key="7"
              >
                <p>
                  Importante: apenas usuários com nível administrativo (ADM)
                  podem ter acesso a esta guia.
                </p>

                <OlStyled>
                  <li>Clique em &quot;VENDAS REALIZADAS&quot;;</li>
                  <li>
                    Nesta guia o gestor da plataforma ou gerente da loja poderá
                    visualizar as vendas realizadas dia a dia, o volume de
                    cashback gerados, data, CPF e nome dos clientes e o
                    colaborador que os atendeu, poderão também EXPORTAR AQUIVO
                    EM .CSV para conciliação financeira.
                  </li>
                </OlStyled>
              </Panel>
              <Panel
                header="Esqueci meus dados de acesso, como devo proceder?"
                key="8"
              >
                <OlStyled>
                  <li>
                    Acesse a plataforma através do link enviado por e-mail;
                  </li>
                  <li>Clique no botão &quot;Esqueci minha senha&quot;;</li>
                  <li>
                    Forneça as informações solicitadas, geralmente o seu e-mail
                    ou CPF;
                  </li>
                  <li>Clique no botão &quot;Enviar&quot;.</li>
                </OlStyled>

                <p>
                  A plataforma enviará um e-mail contendo um link para redefinir
                  sua senha. Basta clicar no link e seguir as instruções para
                  criar uma nova senha. Se não tiver acesso ao e-mail enviado
                  pela plataforma, entre em contato com o suporte por e-mail
                  (vertemfranquias@vertem.com) para obter assistência. Eles
                  poderão ajudar a redefinir sua senha, garantindo o acesso à
                  sua conta.
                </p>
              </Panel>
              <Panel
                header="Como faço para entrar em contato com o suporte técnico?"
                key="9"
              >
                <p>
                  Enviando e-mail para a vertemfranquias@vertem.com explique de
                  forma resumida a sua solicitação deixando sempre telefone para
                  contato para que o time possa retornar para você!
                </p>
              </Panel>

              <Panel
                header="Como faço para entrar no aplicativo do VIBE?"
                key="10"
              >
                <p>
                  Cada CASHBACK resgatado irá gerar VIBES no Aplicativo Vibe
                  Benefícios. Links para baixar o VIBE:
                </p>
                <p>
                  <a
                    href="https://apps.apple.com/app/vibe-benef%C3%ADcios/id6475898929"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Apple Store (iOS)
                  </a>
                </p>
                <p>
                  <a
                    href="https://play.google.com/store/apps/details?id=br.prd.inovaebiz.vibe"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Google Play (Android)
                  </a>
                </p>
                <p>Ou leia o QR Code abaixo:</p>
                <p>
                  <img
                    src={require('../../assets/imagens/qr-code-vibe.png')}
                    alt="QR Vibe APP"
                  />
                </p>
              </Panel>
              <Panel
                header="Como verificar a quantidade de Vibes acumulados no App?"
                key="11"
              >
                <p>
                  <LiStyled>
                    <li>
                      Acesse{' '}
                      <a
                        href="https://play.google.com/store/apps/details?id=br.prd.inovaebiz.vibe"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Google Play (Android)
                      </a>{' '}
                      ou{' '}
                      <a
                        href="https://apps.apple.com/app/vibe-benef%C3%ADcios/id6475898929"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Apple Store (iOS)
                      </a>{' '}
                      e instale o aplicativo Vibe Benefícios;
                    </li>
                    <li>Faça seu cadastro;</li>
                    <li>Faça seu login;</li>
                    <li>
                      Acessando o app, na página inicial você já conseguirá
                      visualizar a quantidade de VIBES que você possui e
                      conhecer seus benefícios disponíveis;
                    </li>
                    <li>
                      Cumprindo as missões do app, você conseguirá acumular
                      ainda mais VIBES.
                    </li>
                  </LiStyled>
                </p>
              </Panel>
              <Panel header="Como resgatar os meus Vibes?" key="12">
                <p>
                  <LiStyled>
                    <li>Acesse o seu o aplicativo Vibe Benefícios;</li>
                    <li>Faça seu login;</li>
                    <li>
                      Navegue pelo app, escolha o benefício desejado e clique em
                      “Confirmar Resgate”;
                    </li>
                    <li>
                      Você receberá a confirmação por e-mail, em até 10 minutos;
                    </li>
                    <li>
                      Após confirmação de resgate, as ofertas resgatas tem
                      expiração de 14 dias.
                    </li>
                    <li>
                      A cada 30 VIBES resgatados, você receberá um número da
                      sorte em seu e-mail, para concorrer a R$10.000,00,
                      mensalmente.
                    </li>
                  </LiStyled>
                </p>
              </Panel>
              <Panel header="O que é o TEM Saúde?" key="13">
                <p>
                  <LiStyled>
                    <li>
                      Pensando no seu cuidado, a <b>Vertem</b> firmou uma
                      parceria com a <b>TEM Saúde</b> para oferecer um programa
                      de saúde com desconto para consultas médicas, exames e
                      tratamento odontológico de qualidade em todo Brasil.
                    </li>
                  </LiStyled>
                </p>
              </Panel>
              <Panel header="Como acessar o TEM Saúde?" key="14">
                <p>
                  <LiStyled>
                    <li>
                      Acesse o aplicativo TEM Saúde Digital disponível na loja
                      de aplicativos do seu celular:{' '}
                      <a
                        href="https://apps.apple.com/br/app/tem-saúde-digital/id1580949688"
                        target="_blank"
                        rel="noreferrer"
                      >
                        App Store
                      </a>{' '}
                      ou{' '}
                      <a
                        href="https://play.google.com/store/apps/details?id=com.temsaude.hello&hl=pt_BR"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Google Play
                      </a>
                      ;
                    </li>
                    <li>Insira o seu CPF e clique no botão próximo;</li>
                    <li>
                      Digite o código recebido por SMS no celular cadastrado.
                      Obs.: O número do cartão está no certificado enviado para
                      sua residência ou via e-mail;
                    </li>
                    <li>Cadastre uma senha de acesso ao app;</li>
                    <li>
                      Complete o cadastro informando seu e-mail e endereço.
                    </li>
                  </LiStyled>
                </p>
              </Panel>
              <Panel header="Quais benefícios tenho no TEM Saúde?" key="15">
                <p>
                  <LiStyled>
                    <li>
                      <b>Plano Familiar:</b> válido para o titular + até 4
                      dependentes (cônjuge, filhos e enteados até 21 anos);
                    </li>
                    <li>
                      <b>Rede de consultas presenciais:</b> Consultas
                      presenciais com descontos com mais de +50 especialidades
                      médicas;
                    </li>
                    <li>
                      <b>Rede de dentistas:</b> para consultas e tratamentos;
                    </li>
                    <li>
                      <b>Desconto em farmácias:</b> desconto de 15 a 35% em
                      medicamentos em +6 mil farmácias credenciadas em todo
                      território nacional. Após a ativação de seu Programa
                      vertem, aguarde 48h para a liberação do benefício. Após
                      ativação, basta informar seu CPF no caixa e solicitar o
                      benefício TEM Saúde.
                    </li>
                    <li>
                      <b>Telemedicina gratuita:</b> sem custo na especialidade
                      clínico geral, Pronto Atendimento (24 x 7) ou agendada.{' '}
                    </li>
                  </LiStyled>
                </p>
                <h3>Em quais laboratórios tenho direito a descontos?</h3>
                <p>
                  São diversos laboratórios em todo território nacional.Você
                  realiza a busca pela Rede Credenciada para realizar seus
                  exames e procedimentos. <br />
                  <img
                    src={require('../../assets/imagens/localidades-temsaude.png')}
                    alt="Em quais laboratórios tenho direito a descontos?"
                  />
                </p>
                <h3>O quanto economizo utilizando o benefício TEM Saúde?</h3>
                <p>
                  <img
                    src={require('../../assets/imagens/beneficios-temsaude.png')}
                    alt="O quanto economizo utilizando o benefício TEM Saúde?"
                  />
                </p>
              </Panel>
              <Panel header="Qual é o canal de atendimento TEM Saúde?" key="16">
                <h3>Central de atendimento</h3>
                <p>
                  <a href="tel:1140001640">11 4000 1640</a> para capitais e
                  regiões metropolitanas.
                  <br />
                  <a href="tel:08008368836">0800 836 88 36</a> para demais
                  localidades.
                  <br />
                </p>
                <p>
                  Segunda a sexta das 7h às 19h <br />
                  <small>* Exceto feriados nacionais</small>
                </p>
                <p>
                  Ou{' '}
                  <a
                    href="https://www.meutem.com.br/contato/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    clique aqui
                  </a>{' '}
                  para mais informações.
                </p>
              </Panel>
              <Panel
                header="Como se comunicar com o Cliente por WhatsApp?"
                key="17"
              >
                <p>
                  Você pode se comunicar diretamente com seus clientes para
                  informá-los sobre o saldo de Cashback, incentivando-os a
                  utilizar novamente seus serviços e adquirir mais produtos.
                </p>
                <p>
                  <LiStyled>
                    <li>
                      Clique na aba{' '}
                      <a
                        onClick={() =>
                          dispatch(push(RouteEnum.ParticipantList))
                        }
                      >
                        Clientes
                      </a>
                      <LiStyled>
                        <li>
                          Depois{' '}
                          <a
                            onClick={() =>
                              dispatch(push(RouteEnum.ParticipantNotifyList))
                            }
                          >
                            Comunicar clientes
                          </a>
                        </li>
                      </LiStyled>
                    </li>
                  </LiStyled>
                  <img
                    src={require('../../assets/imagens/comunicar-clientes.png')}
                    alt="Comunicar Clientes"
                  />
                  <LiStyled>
                    <li>
                      Clique em “notificar” no símbolo do WhatsApp, que logo
                      abrirá uma página para iniciar a conversa;
                    </li>
                    <li>
                      Clique em “iniciar conversa” e automaticamente será
                      direcionado ao WhatsApp de sua loja, conforme abaixo:
                    </li>
                  </LiStyled>
                  <img
                    src={require('../../assets/imagens/comunicar-whatsapp.png')}
                    alt="Comunicar clientes por whatsapp"
                  />
                  <LiStyled>
                    <li>
                      Será enviada uma mensagem personalizada, conforme exemplo:
                    </li>
                  </LiStyled>
                </p>

                <p>
                  <code>
                    “Olá, <b>(NOME DO CLIENTE)</b>!
                    <br />
                    <br />
                    Aqui é da unidade <b>(NOME DA LOJA)</b>. Você possui um
                    saldo de <b>R$ (VALOR DO SALDO)</b> em cashback em nossa
                    unidade, com validade até <b>(DATA DE EXPIRAÇÃO)</b>.
                    Gostaríamos de convidar você para vir até a loja e
                    aproveitar seu benefício!
                    <br />
                    <br />
                    Esperamos vê-lo em breve!
                    <br />
                    <br />
                    Atenciosamente,
                    <br />
                    Equipe <b>(NOME DA LOJA)</b>.”
                  </code>
                </p>
              </Panel>
            </Collapse>

            <br />

            <Row>
              <Col md={{ span: 12, offset: 6 }} xs={24}>
                <h1>Não encontrou oque procurava?</h1>

                <p style={{ fontSize: 10, textAlign: 'center' }}>
                  Mande uma mensagem para o e-mail{' '}
                  <a href="mailto:vertemfranquias@vertem.com">
                    vertemfranquias@vertem.com
                  </a>
                </p>

                <p style={{ fontSize: 10, textAlign: 'center' }}>
                  Ou envie um whatsapp para{' '}
                  <a
                    href="https://wa.me/5511991179031"
                    target="_blank"
                    rel="noreferrer"
                  >
                    +55 (11) 99117-9031
                  </a>
                </p>
              </Col>
            </Row>
            <p style={{ fontSize: 14, textAlign: 'center' }}>
              Saiba mais em:{' '}
              <a
                href="https://vertem.rds.land/vertem-franquias"
                target="_blank"
                rel="noreferrer"
              >
                Vertem Franquias - Benefícios e Prêmios para vendedores,
                lojistas, franqueadores e clientes!
              </a>
            </p>
          </DefaultListContent>
        </Col>
      </Row>
    </Page>
  );
};

export default withOnlyAuthenticated(Faq);
