import { Row, Col, Collapse, Form, DatePicker } from 'antd';
import { useDispatch } from 'react-redux';
import * as Actions from '../../../stores/summary/summary-action';
import moment from 'moment';
import {
  InputForm,
  SelectCustom,
} from '../../../components/styled-components/global.styled';
import { PrimaryButton, SecondaryButton } from '../../../global.styles';
import styled from 'styled-components';
import { useTransactionListContext } from '../context/transaction-list-context';
import { RequestingSelector } from '@aberto/react-common';
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import {
  OrderStatus,
  OrderStatusLabelName,
} from '../../../stores/summary/summary-reducer';
import { useProfile } from '../../../context/authentication-provider';
import {
  getIdentificationInfo,
  REQUEST_IDENTIFICATION_INFO,
} from '../../../stores/store/store-actions';
import { convertIdentificationInfoToSelectOptions } from '../../../helpers/select-helper';
import { maskedDate } from '../../../helpers/date-mask-helper';
import { IdentificationInfoResponse } from '../../../stores/sellers/seller-reducer';

type FormValues = {
  storeId: string;
  participantId: string;
  period?: moment.Moment[];
  status: OrderStatus;
};

const RangePicker = styled(DatePicker.RangePicker)`
  border-radius: 500px;
  width: 100%;
  border: 1px solid #001d42;
  margin-top: 4px;
`;

export const TransactionListFilter = () => {
  const dispatch = useDispatch();
  const transactionListContext = useTransactionListContext();
  const [form] = Form.useForm();
  const profile = useProfile();
  const storeIdentificationInfo = useSelector(
    (state: any) => state.store.identificationInfo
  ) as IdentificationInfoResponse[];

  const handleSubmit = (data: FormValues) => {
    const newFilter = getRequestFromFormValues(data);

    transactionListContext.setFilter(newFilter);
  };

  const handleCleanFilter = () => {
    form.setFieldsValue({
      period: [moment().startOf('week'), moment().endOf('week')],
      participantId: '',
      storeId: '',
      status: null,
    });

    transactionListContext.setPagination({
      pageNumber: 1,
      pageSize: 10,
    });

    form.submit();
  };

  const selectStatus = useMemo(() => {
    return Object.entries(OrderStatusLabelName)
      .filter(([key, value]) => key != OrderStatus.Generated.toString())
      .map(([key, value]) => ({
        label: value,
        value: key,
      }));
  }, []);

  useEffect(() => {
    dispatch(getIdentificationInfo() as any);
  }, []);

  const handleDownloadCSV = () => {
    const formValues = form.getFieldsValue() as FormValues;

    const request = getRequestFromFormValues(formValues);

    dispatch(
      Actions.downloadCSV({
        ...request,
        initialRangeDate: request.initialRangeDate.toISOString(),
        finalRangeDate: request.finalRangeDate.toISOString(),
      }) as any
    );
  };

  const getRequestFromFormValues = (data: FormValues) => {
    const request = {
      ...data,
      initialRangeDate:
        data.period?.at(0) || transactionListContext.filter.initialRangeDate,
      finalRangeDate:
        data.period?.at(1) || transactionListContext.filter.finalRangeDate,
    };

    delete request.period;

    return request;
  };

  useEffect(() => {
    if (profile !== 'Owner' && storeIdentificationInfo.length > 0) {
      const formValues = form.getFieldsValue() as FormValues;
      const request = getRequestFromFormValues(formValues);

      form.setFieldsValue({
        storeId: storeIdentificationInfo[0].id,
        initialRangeDate: request.initialRangeDate.toISOString(),
        finalRangeDate: request.finalRangeDate.toISOString(),
      });

      transactionListContext.setFilter({
        participantId: '',
        storeId: storeIdentificationInfo[0].id,
        initialRangeDate: request.initialRangeDate,
        finalRangeDate: request.finalRangeDate,
      });
    }
  }, [storeIdentificationInfo, profile]);

  const isLoadingDownload = useSelector(state =>
    RequestingSelector.selectRequesting(state, [
      Actions.REQUEST_DOWNLOAD_SUMMARY_CSV,
    ])
  );

  const isLoadingStores = useSelector(state =>
    RequestingSelector.selectRequesting(state, [REQUEST_IDENTIFICATION_INFO])
  );

  return (
    <Collapse defaultActiveKey={'filter'}>
      <Collapse.Panel header="Filtros" key={'filter'}>
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Row>
            <Col lg={{ span: 8 }} sm={24}>
              <Form.Item label="Identificador de loja" name="storeId">
                <SelectCustom
                  loading={isLoadingStores}
                  options={convertIdentificationInfoToSelectOptions(
                    storeIdentificationInfo
                  )}
                  disabled={profile != 'Owner'}
                />
              </Form.Item>
            </Col>
            <Col lg={{ span: 7, offset: 1 }} sm={24}>
              <Form.Item label="Celular do participante" name="participantId">
                <InputForm />
              </Form.Item>
            </Col>
            <Col lg={{ span: 7, offset: 1 }} sm={24}>
              <Form.Item label="Periodo" name="period">
                <RangePicker
                  defaultValue={[
                    transactionListContext.filter.initialRangeDate,
                    transactionListContext.filter.finalRangeDate,
                  ]}
                  format={'DD/MM/YYYY'}
                  onKeyDown={maskedDate}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 8 }} sm={24}>
              <Form.Item label="Status" name="status">
                <SelectCustom options={selectStatus} allowClear={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={24} lg={{ span: 3, offset: 13 }}>
              <SecondaryButton
                onClick={handleDownloadCSV}
                loading={isLoadingDownload}
                style={{
                  width: '100%',
                }}
              >
                Baixar CSV
              </SecondaryButton>
            </Col>
            <Col sm={24} lg={{ span: 3, offset: 1 }}>
              <SecondaryButton
                onClick={handleCleanFilter}
                style={{
                  width: '100%',
                }}
              >
                Limpar filtros
              </SecondaryButton>
            </Col>
            <Col sm={24} lg={{ span: 3, offset: 1 }}>
              <PrimaryButton
                type="primary"
                htmlType="submit"
                style={{
                  width: '100%',
                }}
              >
                Filtrar
              </PrimaryButton>
            </Col>
          </Row>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};
