import { Col, Form, Row } from 'antd'
import { push } from 'connected-react-router'
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import RouteEnum from '../../../constants/RouteEnum'
import { useCognitoContext } from '../../../context/authentication-provider'
import {
  ContainerButton,
  InputForm,
  PasswordInputForm,
  PrimaryButton,
} from '../../../global.styles'
import { CardVerification } from '../store-authentication.styles'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'

const ConfirmNewPassword: FC = () => {
  const dispatch = useDispatch()
  const authContext = useCognitoContext()
  const [errorMessage, setErrorMessage] = useState('')

  const [form] = Form.useForm()

  const handleSubmit = (data: any) => {
    setErrorMessage('')
    const { password, confirmPassword } = data

    if (password !== confirmPassword)
      setErrorMessage('As senhas digitadas não conferem')
    else authContext.confirmPassword(password)
  }

  useEffect(() => {
    if (
      authContext.temporaryUser === null ||
      authContext.temporaryUser === undefined
    )
      dispatch(push(RouteEnum.Login))

    if (authContext.confirmPasswordError)
      setErrorMessage(authContext.confirmPasswordErrorMessage)
  }, [authContext])

  return (
    <CardVerification>
      <h3>Nova senha</h3>

      <small style={{ color: 'tomato' }}>{errorMessage}</small>

      <Form
        id="forgot-password"
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={true}
      >
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              hasFeedback={false}
              id="password"
              name="password"
              label="Nova senha"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <PasswordInputForm
                iconRender={visible =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                placeholder="*********"
                autoFocus={true}
                type="password"
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              hasFeedback={false}
              id="confirmPassword"
              name="confirmPassword"
              label="Confirmar nova senha"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <PasswordInputForm
                iconRender={visible =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                placeholder="*********"
                autoFocus={true}
                type="password"
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <ContainerButton>
              <PrimaryButton
                onClick={form.submit}
                loading={authContext.confirmPasswordIsLoading}
              >
                Entrar
              </PrimaryButton>
            </ContainerButton>
          </Col>
        </Row>
      </Form>
    </CardVerification>
  )
}

export default ConfirmNewPassword
