import { Col, Row, Spin } from 'antd'
import {
  CustomCheckBox,
  ParticipantLabels,
  ParticipantValues,
  WrapParticipantSummary,
} from '../steps/steps-style'
import { useSelector } from 'react-redux'
import { currentParticipant } from '../../../selectors/participant/participant-selector'
import { useContext } from 'react'
import { OrderStepContext } from '../steps/context/order-step-context'
import { RequestingSelector } from '@aberto/react-common'
import { REQUEST_GET_BY_CELL_PHONE } from '../../../stores/participant/participant-action'

export const ParticipantSummary = ({ isOrderValueStep = false }) => {
  const participant = useSelector(state => currentParticipant(state)) as any

  const isGettingParticipantInfo = useSelector((state: any) =>
    RequestingSelector.selectRequesting(state, [REQUEST_GET_BY_CELL_PHONE])
  )

  const orderStepContext = useContext(OrderStepContext)

  const formatDocument = (document: string) => {
    if (!document) return

    return `${document.substring(0, 3)}.${document.substring(
      3,
      6
    )}.${document.substring(6, 9)}-${document.substring(9, 12)}`
  }

  const formatCellPhone = (cellPhone: string) => {
    if (!cellPhone) return

    return `(${cellPhone.substring(0, 2)}) ${cellPhone.substring(
      2,
      7
    )}-${cellPhone.substring(7, 11)}`
  }

  const handleOnClickShouldUseBurnBalance = (event: any) => {
    orderStepContext.setShouldUseBurnBalance(event.target.checked)
  }

  const handleOnClickShouldUsePinCodeBurn = (event: any) => {
    orderStepContext.setShouldUsePinCodeBurn(event.target.checked)
  }

  const handleKeyPressShouldUseBurnBalance = (event: any) => {
    if (event.key === 'Enter')
      orderStepContext.setShouldUseBurnBalance(
        !orderStepContext.shouldUseBurnBalance
      )
  }

  const handleKeyPressShouldUsePinCodeBurn = (event: any) => {
    if (event.key === 'Enter')
      orderStepContext.setShouldUsePinCodeBurn(
        !orderStepContext.shouldUsePinCodeBurn
      )
  }

  return (
    <Spin spinning={isGettingParticipantInfo}>
      <WrapParticipantSummary>
        <Row>
          <Col span={12}>
            <ParticipantLabels>Celular: </ParticipantLabels>
            <ParticipantValues>
              {formatCellPhone(participant?.cellPhone)}
            </ParticipantValues>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <ParticipantLabels>Saldo Atual: </ParticipantLabels>
            <ParticipantValues>
              {participant?.balance?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              }) || 0}
            </ParticipantValues>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {participant?.name && (
              <>
                <ParticipantLabels>Nome: </ParticipantLabels>
                <ParticipantValues>{participant?.name}</ParticipantValues>
              </>
            )}
          </Col>
          {isOrderValueStep &&
            (participant?.document || participant?.balance > 0) && (
              <>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <CustomCheckBox
                    tabIndex={0}
                    onKeyPress={handleKeyPressShouldUseBurnBalance}
                    defaultChecked={true}
                    checked={orderStepContext.shouldUseBurnBalance}
                    value={orderStepContext.shouldUseBurnBalance}
                    onClick={handleOnClickShouldUseBurnBalance}
                  />
                  <ParticipantValues style={{ marginLeft: '10px' }}>
                    Ativar resgate de bônus
                  </ParticipantValues>
                </Col>
              </>
            )}
        </Row>
        <Row>
          <Col span={12}>
            {participant?.document && (
              <>
                <ParticipantLabels>CPF: </ParticipantLabels>
                <ParticipantValues>
                  {formatDocument(participant?.document)}
                </ParticipantValues>
              </>
            )}
          </Col>
          {isOrderValueStep && (
            <Col span={12} style={{ textAlign: 'right' }}>
              <CustomCheckBox
                tabIndex={1}
                onKeyPress={handleKeyPressShouldUsePinCodeBurn}
                defaultChecked={true}
                checked={orderStepContext.shouldUsePinCodeBurn}
                value={orderStepContext.shouldUsePinCodeBurn}
                onClick={handleOnClickShouldUsePinCodeBurn}
              />
              <ParticipantValues style={{ marginLeft: '10px' }}>
                Utilizar Cupom de Desconto
              </ParticipantValues>
            </Col>
          )}
        </Row>
      </WrapParticipantSummary>
    </Spin>
  )
}
