import { FC } from 'react'
import Page from '../../components/layout/page'
import { GroupTitle } from '@aberto/react-common'
import { Row, Col, Button } from 'antd'
import {
  withManagerProfileAllowed,
  withProfileAllowed,
} from '../../context/authentication-provider'
import TransactionListTable from './components/seller-list-table'
import { PrimaryButton } from '../../global.styles'
import { ShadowGroup } from './components/shadow-group'
import { Link } from 'react-router-dom'
import RouteEnum from '../../constants/RouteEnum'

const SellerList: FC = () => {
  return (
    <Page>
      <Row>
        <Col
          md={{ span: 22, offset: 1 }}
          xs={24}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <Link to={RouteEnum.SellerCreate}>
            <PrimaryButton>Cadastrar novo colaborador</PrimaryButton>
          </Link>
          <ShadowGroup>
            <GroupTitle>Listagem de colaboradores</GroupTitle>
            <TransactionListTable />
            <br />
          </ShadowGroup>
        </Col>
      </Row>
    </Page>
  )
}

export default withManagerProfileAllowed(SellerList)
