import { FC } from 'react'
import Page from '../../components/layout/page'
import { GroupTitle } from '@aberto/react-common'
import { Row, Col } from 'antd'
import TransactionListContent from './components/transaction-list-content'
import { TransactionListFilter } from './components/transaction-list-filter'
import TransactionListTable from './components/transaction-list-table'
import { TransactionListProvider } from './context/transaction-list-context'
import { withProfileAllowed } from '../../context/authentication-provider'

const TransactionList: FC = () => {
  return (
    <TransactionListProvider>
      <Page>
        <Row>
          <Col
            md={{ span: 22, offset: 1 }}
            xs={24}
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <TransactionListContent>
              <GroupTitle>Listagem de transações</GroupTitle>
              <TransactionListFilter />
              <TransactionListTable />
              <br />
            </TransactionListContent>
          </Col>
        </Row>
      </Page>
    </TransactionListProvider>
  )
}

export default withProfileAllowed(TransactionList)
