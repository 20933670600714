import { FC } from 'react'
import { Wrap, WrapOnlyShadow } from './steps-style'
import BenefitOverviewStepItem from './benefit-overview-step-item'
import { ParticipantSummary } from '../components/participant-summary-header'

const BenefitOverviewStep: FC<{ isLastStep: boolean; isError: boolean }> = ({ isLastStep = false, isError = false }) => {
  return (
    <>
      <ParticipantSummary />
      <WrapOnlyShadow>
        <BenefitOverviewStepItem isLastStep={isLastStep} isError={isError} />
      </WrapOnlyShadow>
    </>
  )
}

export default BenefitOverviewStep
