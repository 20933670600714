import { FC } from 'react'
import moment from 'moment'
import {
  Button,
  Space,
  Spin,
  Table,
  TablePaginationConfig,
  Tooltip,
} from 'antd'
import { useSelector } from 'react-redux'
import {
  OrderStatus,
  SummaryResponse,
} from '../../../stores/summary/summary-reducer'
import { RequestingSelector } from '@aberto/react-common'
import { REQUEST_GET_SUMMARY } from '../../../stores/summary/summary-action'
import { useTransactionListContext } from '../context/transaction-list-context'
import { StopOutlined } from '@ant-design/icons'
import * as Actions from '../../../stores/order/order-action'
import styled from 'styled-components'

const CustomStopButton = styled(Button)`
  &.ant-btn-link {
    color: red;
  }
  &.ant-btn-link:hover {
    color: #f5a6a6;
  }
  &.ant-btn-link:focus {
    color: #f5a6a6;
  }
`
const TransactionListTable: FC = () => {
  const transactionListContext = useTransactionListContext()

  const columns = [
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      render: (date: string) => moment(date).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'CPF',
      dataIndex: 'participantDocument',
      key: 'participantDocument',
      render: (cpf: string) => (!cpf ? '-' : cpf),
    },
    {
      title: 'Nome',
      dataIndex: 'participantName',
      key: 'participantName',
      render: (name: string) => (!name ? '-' : name),
    },
    {
      title: 'Celular',
      dataIndex: 'participantCellphone',
      key: 'participantCellphone',
    },
    {
      title: 'Valor da compra',
      dataIndex: 'value',
      key: 'value',
      render: (value: number) => value.toFixed(2),
    },
    {
      title: 'Valor do resgate',
      dataIndex: 'redemptionValue',
      key: 'redemptionValue',
      render: (value: number) => value.toFixed(2),
    },
    {
      title: 'Valor pago',
      dataIndex: 'payedValue',
      key: 'payedValue',
      render: (value: number) => value.toFixed(2),
    },
    {
      title: 'Valor do crédito',
      dataIndex: 'creditValue',
      key: 'creditValue',
      render: (value: number) => value.toFixed(2),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: OrderStatus) => {
        switch (status) {
          case OrderStatus.Generated:
            return 'Gerado'
          case OrderStatus.AppliedWaitingValidation:
            return 'Aplicado'
          case OrderStatus.Confirmed:
            return 'Confirmado'
          case OrderStatus.Reversed:
            return 'Estornado'
          default:
            return '-'
        }
      },
    },
    {
      title: 'Loja',
      dataIndex: 'store',
      key: 'store',
    },
    {
      title: 'Colaborador',
      dataIndex: 'sellerName',
      key: 'sellerName',
      render: (sellerName: string) => (
        <Tooltip title={sellerName}>
          {sellerName?.split('@')?.at(0) || '-'}
        </Tooltip>
      ),
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      key: 'id',
      render: (id: string, obj: { status: OrderStatus }) => {
        if (obj.status === OrderStatus.Reversed) return <></>

        return (
          <Space>
            <Tooltip title="Estornar">
              {isLoadingReversal ? (
                <Spin />
              ) : (
                <CustomStopButton
                  type="link"
                  icon={<StopOutlined />}
                  onClick={() =>
                    transactionListContext.openRevertOrderModal(id)
                  }
                />
              )}
            </Tooltip>
          </Space>
        )
      },
    },
  ]

  const data = useSelector((x: any) => x.summary.response) as SummaryResponse

  const isLoading = useSelector(state =>
    RequestingSelector.selectRequesting(state, [REQUEST_GET_SUMMARY])
  )

  const isLoadingReversal = useSelector(state =>
    RequestingSelector.selectRequesting(state, [
      Actions.REQUEST_REVERSAL_BENEFIT,
    ])
  )

  const handlePagination = (data: TablePaginationConfig) =>
    transactionListContext.setPagination({
      pageNumber: data.current!,
      pageSize: data.pageSize!,
    })

  return (
    <Table
      dataSource={data.items}
      columns={columns}
      loading={isLoading}
      onChange={handlePagination}
      pagination={{
        total: data.total,
      }}
    />
  )
}

export default TransactionListTable
