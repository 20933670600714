import { useMutation } from '@tanstack/react-query'
import postApiInstance from '../promo-api'

export const useAcceptTerm = () =>
  useMutation({
    mutationFn: async (geolocation: any = {}) => {
      const response = await postApiInstance.post('/store/term/accept', {
        geolocation: {
          latitude: geolocation.latitude,
          longitude: geolocation.longitude,
        },
      })

      if (response.status === 200) window.location.reload()
    },
    mutationKey: ['accept-term'],
  })
