import Page from '../../components/layout/page'
import { withProfileAllowed } from '../../context/authentication-provider'
import { FC, useEffect } from 'react'
import { Col, Row } from 'antd'
import { GroupTitle } from '@aberto/react-common'
import { DefaultListContent } from '../../components/styled-components/default-list-content'
import { InputForm } from '../../global.styles'
import { ParticipantListTable } from './components/participant-list-table'
import { useDispatch } from 'react-redux'
import * as ParticipantActions from '../../stores/participant/participant-action'
import { defaultDebounce } from '../../utilities/DebounceUtility'
import { ParticipantModal } from './components/participant-modal'
import { ParticipantProvider } from './context/participant-context'
import { ChangeParticiantCellphoneModal } from './components/change-participant-cellphone-modal'

const ParticipantListComponent: FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(ParticipantActions.searchParticipant() as any)
  }, [dispatch])

  const handleFilterOnChange = (event: any) => {
    dispatch(ParticipantActions.setFilter(event.target.value ?? null) as any)
    dispatch(ParticipantActions.searchParticipant() as any)
  }

  return (
    <ParticipantProvider>
      <Page>
        <Row style={{ textAlign: 'center' }}>
          <Col
            md={{ span: 12, offset: 6 }}
            xs={24}
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <DefaultListContent>
              <GroupTitle>Clientes</GroupTitle>
              <br />
              <InputForm
                allowClear={true}
                onChange={defaultDebounce(handleFilterOnChange)}
                style={{ width: '90%' }}
                placeholder="Buscar por nome, CPF ou celular"
              />
              <ParticipantListTable />
            </DefaultListContent>
          </Col>
        </Row>
      </Page>
      <ParticipantModal />
      <ChangeParticiantCellphoneModal />
    </ParticipantProvider>
  )
}

export const ParticipantList = withProfileAllowed(ParticipantListComponent)
