export default function baseEnv() {
  return {
    route: {
      baseRoute: '/',
    },
    dashboard: {
      store_id: '4c6dc861-f95b-4c21-8648-03f6fd58e5a9',
      baseUrl: 'https://charts.mongodb.com/charts-aberto-hml-bvffn',
      chain_id: '64ef7fb0-cd63-44e7-86a5-8e6beef3e732',
    },
    authentication: {
      aws_project_region: 'us-east-1',
      aws_cognito_identity_pool_id: 'us-east-1_OUhdo64tH',
      aws_cognito_region: 'us-east-1',
      aws_user_pools_id: 'us-east-1_OUhdo64tH',
      aws_user_pools_web_client_id: '2oecqkb3m0vh0mdko6ejuo8u5q',
    },
    promoApi: {
      baseUrl: 'https://abertoselfpromob2chmlapiinnovation.azurewebsites.net/',
      subscriptionKey: '',
      endpoints: {
        participants: {
          getParticipants: '/participant',
          getParticipantsNotify: '/participant/participants-next-expiration',
          markParticipantAsNotified:
            '/participant/:participantId/mark-as-notified',
          createParticipants: '/participant',
          updateParticipants: '/participant/cellPhone/:cellPhone',
          getParticipantsByCellPhone: '/participant/cellPhone/:cellPhone',
          getActiveBonus: '/participant/:cellPhone/active-bonus',
          searchParticipants: '/participant/search',
          statusParticipants: '/participant/status',
          validate: '/participant/:cellPhone/code/:code/validate',
          sendCode: '/participant/cellphone/:cellPhone/send',
          getStatements: '/participant/:cellPhone/statement',
          changeCellphone:
            '/participant/cellphone/:oldCellphone/new-cellphone/:newCellphone',
          finalizeChangeCellphone:
            '/participant/finalize-change-cellphone/:changeCellphoneOrderId',
          resendChangeCellphoneValidationCode:
            '/participant/change-cellphone-order/:changeCellphoneOrderId/resend-validation-code',
        },
        stores: {
          identificationInfo: '/store/identification-info',
        },
        order: {
          create: '/order',
          createOrder: '/order',
          calculateCashback: '/order/calculate-cashback',
          applyCashback: '/order/:orderId/apply-benefit',
          revertBenefit: '/order/:orderId/reversal',
          getOrderId: '/order/:id',
          validate: '/order/confirm-with-verification-code',
          sendVerificationCode: '/order/:id/send-verification-code',
          sendVerificationCodeToManager:
            '/order/:id/resend-verification-code/type/:type/to-manager',
        },
        summary: {
          getSummary: '/order/search',
          report: '/order/report',
        },

        authenticationStore: {
          sendCodeAuthentication:
            '/authenticate/store/cellphone/:cellPhone/send',
          validateCodeAuthentication:
            '/authenticate/store/cellphone/:cellPhone/code/:code/validate',
          getStoresInfo: '/stores-info/basic-info',
        },
        sales: {
          getSalesList: '/sales-hub/search',
          toggleSaleStatus: '/sales-hub/:saleId/change-participation',
        },
      },
    },
  };
}
