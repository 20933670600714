import { FC } from 'react'
import { Col, Row, Table } from 'antd'
import {
  TransactionType,
  TransactionTypeLabel,
} from '../enums/transaction-type-enum'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { ParticipantTransactionListTablePagination } from './participant-transaction-list-table-pagination'
import { RequestingSelector } from '@aberto/react-common'
import {
  REQUEST_GET_STATEMENTS,
  REQUEST_GET_STATEMENTS_NEXT_PAGE,
} from '../../../stores/participant/participant-action'

export const ParticipantTransactionListTable: FC = () => {
  const currentPageStatements = useSelector(
    (state: any) => state.participant.statements.currentPageData
  )

  const isRequestingStatements = useSelector((state: any) =>
    RequestingSelector.selectRequesting(state, [
      REQUEST_GET_STATEMENTS,
      REQUEST_GET_STATEMENTS_NEXT_PAGE,
    ])
  )

  const columns = [
    {
      title: 'Tipo',
      dataIndex: 'type',
      render: (title: TransactionType, record: any) => {
        if (record.operationType == 5) return 'Estorno resgate'

        return TransactionTypeLabel[title]
      },
    },
    {
      title: 'Data',
      dataIndex: 'date',
      render(title: Date) {
        return moment(title).format('DD/MM/YYYY')
      },
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      render: (title: number, record: any) => {
        if (
          record.operationType == 5 ||
          record.type == TransactionType.Redemption
        )
          return <span style={{ color: 'red' }}>- R$ {(title < 0 ? title * -1 : title).toFixed(2)}</span>

        return `R$ ${title.toFixed(2)}`
      },
    },
    // {
    //   title: 'Data Expiração',
    //   dataIndex: 'expirationDate',
    //   render(title: Date) {
    //     if (title == null) return '-'
    //
    //     return moment(title).format('DD/MM/YYYY')
    //   },
    // },
  ]
  return (
    <>
      <Table
        columns={columns}
        dataSource={currentPageStatements}
        pagination={false}
        loading={isRequestingStatements}
      />
      <Row>
        <Col span={24}>
          <ParticipantTransactionListTablePagination />
        </Col>
      </Row>
    </>
  )
}
