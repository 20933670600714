import { ActionUtility } from '@aberto/react-common'
import * as Effects from './store-effects'
export const REQUEST_IDENTIFICATION_INFO = 'store.REQUEST_IDENTIFICATION_INFO'
export const REQUEST_IDENTIFICATION_INFO_FINISHED =
  'store.REQUEST_IDENTIFICATION_INFO_FINISHED'

export function getIdentificationInfo() {
  return async (dispatch: any, getState: any) => {
    return await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_IDENTIFICATION_INFO,
      Effects.getIdentificationInfoEffect
    )
  }
}
