import Page from '../../components/layout/page'
import { OrderCreate } from '../order/order-create'
import { useProfile, withAuthentication } from '../../context/authentication-provider'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import RouteEnum from '../../constants/RouteEnum'

const HomePage = () => {
  const profile = useProfile()
  const dispatch = useDispatch()

  useEffect(() => {
    if (profile === "Owner") dispatch(push(RouteEnum.TransactionList))
  }, [profile, dispatch])

  return (
    <Page>
      <OrderCreate />
    </Page>
  )
}
export default withAuthentication(HomePage)
