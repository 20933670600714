import postApiInstance from '../../api/promo-api';
import { environment } from '../../environments';

const endpoint = environment.promoApi.endpoints.sales;

export type SaleItem = {
  saleId: string;
  saleName: string;
  saleDescription: string;
  campaignName: string;
  isParticipating: boolean;
};

export type SalesList = {
  numberOfRows: number;
  data: SaleItem[];
};

export const useSales = () => {
  const getSalesList = (storeId: string) =>
    postApiInstance.get<SalesList>(endpoint.getSalesList, {
      params: {
        'pagination.pageSize': 50,
        'pagination.pageNumber': 1,
        storeId,
      },
    });

  const toggleSaleStatus = (
    saleId: string,
    data: { status: boolean; storeId: string }
  ) =>
    postApiInstance.patch(
      endpoint.toggleSaleStatus.replace(':saleId', saleId),
      data
    );

  return { getSalesList, toggleSaleStatus };
};
