import axios from 'axios'
import { Auth } from 'aws-amplify'
import { environment } from '../environments'

const businessExceptionStatus = 422

export interface ApiException {
  axiosErrorType: 'ApiException'
  message?: { message: string }
  statusCode: number
}

export const isApiException = (
  object: ApiException
): object is ApiException => {
  return object?.axiosErrorType === 'ApiException' || !object
}

const fifteenSecondsTimeout = 1000 * 15

const postApiInstance = axios.create({
  baseURL: environment.promoApi.baseUrl,
  timeout: fifteenSecondsTimeout,
})

postApiInstance.interceptors.request.use(async config => {
  const user = await Auth.currentAuthenticatedUser()

  config.headers = {
    ...config.headers,
    'Ocp-Apim-Subscription-Key': environment.promoApi.subscriptionKey,
    Authorization: `Bearer ${user?.signInUserSession?.idToken?.jwtToken}`,
    'Content-Type': `application/json`,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': 'Content-Type',
  }

  return config
})

postApiInstance.interceptors.response.use(
  response => response,
  error => {
    const defaultError = {
      message:
        error.response?.data ||
        'Aconteceu um erro inesperado, tente novamente mais tarde!',
      statusCode: error.response?.status || 500,
      axiosErrorType: 'ApiException',
    } as ApiException

    const errorResponse = error?.response

    if (!error || !errorResponse) return defaultError

    if (errorResponse.statusCode === 401) {
      localStorage.clear()
      location.reload()
      return {}
    }

    if (errorResponse.statusCode === businessExceptionStatus) {
      const businessExceptionError = {
        ...errorResponse,
        axiosErrorType: 'ApiException',
      } as ApiException

      return businessExceptionError
    }

    return defaultError
  }
)

export default postApiInstance
