import { produce } from 'immer'
import { BaseReducer } from '@aberto/react-common'
import * as Actions from './participant-action'

export const initialState = {
  currentParticipant: {
    id: null,
    name: null,
    document: null,
    documentType: null,
    cellPhone: null,
    email: null,
    status: null,
    code: null,
    birthDate: null,
    addressDTO: {
      zipCode: null,
      street: null,
      number: null,
      complement: null,
      state: null,
      district: null,
      city: null,
    },
  },
  statements: {
    currentPageData: [],
    nextPageData: [],
    page: 1,
    currentId: null,
    isGoingToPreviousPage: false,
    selectedStoreId: null,
  },
  search: {
    pagination: {
      pageSize: 10,
      pageNumber: 1,
    },
    result: [],
    filter: null,
    sort: {
      descending: false,
      field: 'name',
    },
  },
}

export const participantReducer = BaseReducer(initialState, {
  [Actions.CLEAR_PARTICIPANT](state, { payload }) {
    return initialState
  },
  [Actions.SET_SORT](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.sort.descending = payload
    })
  },
  [Actions.SET_FILTER](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.filter = { identifier: payload }
    })
  },
  [Actions.SET_STATEMENTS_PAGINATION](state, { payload }) {
    return produce(state, draftState => {
      draftState.statements.isGoingToPreviousPage =
        state.statements.page > payload

      draftState.statements.page = payload
    })
  },
  [Actions.SET_STATEMENTS_PHONE](state, { payload }) {
    return produce(state, draftState => {
      draftState.statements.currentId = payload
    })
  },
  [Actions.SET_STATEMENTS_STOREID](state, { payload }) {
    return produce(state, draftState => {
      draftState.statements.selectedStoreId = payload
    })
  },
  [Actions.REQUEST_GET_STATEMENTS_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.statements.currentPageData = payload?.data
    })
  },
  [Actions.REQUEST_GET_STATEMENTS_NEXT_PAGE_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      if (
        !state.statements.isGoingToPreviousPage &&
        state.statements.page !== 1
      ) {
        draftState.statements.currentPageData = [
          ...state.statements.nextPageData,
        ]
      }
      draftState.statements.nextPageData = payload?.data
    })
  },
  [Actions.SET_PAGINATION](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.pagination.pageNumber = payload
    })
  },
  [Actions.REQUEST_SEARCH_PARTICIPANT_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.result = payload
    })
  },
  [Actions.REQUEST_GET_BY_CELL_PHONE_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.currentParticipant = payload
    })
  },
  [Actions.CLEAR_CURRENT_PARTICIPANT](state, { payload }) {
    return produce(state, draftState => {
      draftState.currentParticipant = initialState.currentParticipant
    })
  },

  [Actions.SET_CURRENT_PARTICIPANT](state, { payload }) {
    return produce(state, draftState => {
      draftState.currentParticipant = {
        ...initialState.currentParticipant,
        ...payload,
      }
    })
  },
})
