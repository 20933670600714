import { Col, Collapse, Row } from 'antd';
import Page from '../../components/layout/page';
import { withOnlyAuthenticated } from '../../context/authentication-provider';
import styled from 'styled-components';
import { GroupTitle } from '@aberto/react-common';
import { DefaultListContent } from '../../components/styled-components/default-list-content';

const { Panel } = Collapse;

const OlStyled = styled.ol`
  margin-left: 20px;
`;

const trainingItens = [
  {
    videoUrl: 'https://www.youtube.com/embed/2bW1PEVGmyg',
    title: 'Como gerar cashback',
    content:
      'Aqui você terá o passo a passo de como acessar a plataforma e gerar o seu primeiro cashback.',
  },
  {
    videoUrl: 'https://www.youtube.com/embed/LY_kLTftJV4',
    title: 'Como resgatar voucher',
    content:
      'Aqui toda vez que um cliente vier com um voucher de algum programa parceiro do programa você seguirá este passo a passo para poder validar e queimar o voucher.',
  },
  {
    videoUrl: 'https://www.youtube.com/embed/1ObfZM3ROu4',
    title: 'Plataforma de incentivo',
    content:
      'Colaboradores: cada cashback resgatado vale Vibes e aqui esta o passo a passo de como resgatar e acompanhar seus Vibes obtidos nesta plataforma.',
  },
  {
    videoUrl: 'https://www.youtube.com/embed/JCChqbq_6vQ',
    title: 'Cadastro de colaboradores',
    content:
      'Gestor este é o primeiro passo desta jornada, cadastre seus colaboradores para que possam começar a ofertar cashback, siga o passo a passo deste vídeo para concluir esta etapa.',
  },
  {
    videoUrl: 'https://www.youtube.com/embed/sox8yI-Dsa0',
    title: 'TEM Saúde para colaboradores',
    content:
      'Programa de saúde com desconto para consultas médicas, exames e tratamento odontológico de qualidade em todo Brasil.',
  },
];

const Training = () => {
  return (
    <Page>
      <Row>
        <Col
          md={{ span: 12, offset: 6 }}
          xs={24}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <DefaultListContent>
            <GroupTitle>Treinamentos</GroupTitle>
            <Collapse accordion>
              {trainingItens.map((item, index) => (
                <Panel header={item.title} key={index}>
                  <p>{item.content}</p>
                  <iframe
                    style={{ width: '100%', minHeight: '500px' }}
                    src={item.videoUrl}
                    title={item.title}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  ></iframe>
                </Panel>
              ))}
            </Collapse>
          </DefaultListContent>
        </Col>
      </Row>
    </Page>
  );
};

export default withOnlyAuthenticated(Training);
