export const participants = state => state.participant.participants
export const currentParticipant = state => state.participant.currentParticipant
export const currentParticipantCellPhone = state =>
  state.participant.currentParticipant?.cellPhone?.replace(
    /^(\d\d)(\d{5})(\d{4}).*/,
    '($1) $2-$3'
  )
export const pagination = state => state.participant.search.pagination
export const filter = state => state.participant.search.filter
export const sort = state => state.participant.search.sort

export const activeBonus = state => state.participant.activeBonus
