import { routerMiddleware } from 'connected-react-router'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import thunk from 'redux-thunk'
import rootReducer from './rootReducer'


export default function rootStore(initialState, history) {

  const composeEnhancers = composeWithDevTools({ trace: true })

  const store = createStore(
    rootReducer(history),
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
  )
  return store
}
