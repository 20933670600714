import PropTypes from 'prop-types'
import React from 'react'
import './layout.css'

const Page = ({ children }) => {
  return <div className="page-content">{children}</div>
}
Page.propTypes = { children: PropTypes.any }

export default Page
