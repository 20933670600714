import { ActionUtility } from '@aberto/react-common'
import * as Effects from './order-effects'
import { getCurrentSellerSelected } from '../../utilities/seller-store'

export const REQUEST_CREATE = 'order.REQUEST_CREATE'
export const REQUEST_CREATE_FINISHED = 'order.REQUEST_CREATE_FINISHED'

export const REQUEST_SEARCH = 'order.REQUEST_SEARCH'
export const REQUEST_SEARCH_FINISHED = 'order.REQUEST_SEARCH_FINISHED'

export const REQUEST_CREATE_FIRST_ORDER = 'order.REQUEST_CREATE_FIRST_ORDER'
export const REQUEST_CREATE_FIRST_ORDER_FINISHED =
  'order.REQUEST_CREATE_FIRST_ORDER_FINISHED'

export const REQUEST_ORDER_GET_BY_ID = 'order.REQUEST_ORDER_GET_BY_ID'
export const REQUEST_ORDER_GET_BY_ID_FINISHED = 'order.REQUEST_ORDER_FINISHED'

export const REQUEST_FILTER = 'order.REQUEST_FILTER'
export const CLEAR_FILTER = 'order.CLEAR_FILTER'

export const SET_PAGINATION = 'order.SET_PAGINATION'

export const REQUEST_SUMMARY_ORDER = 'order.REQUEST_SUMMARY_ORDER'

export const REQUEST_CURRENT_ORDER = 'order.REQUEST_CURRENT_ORDER'
export const REQUEST_CURRENT_ORDER_FINISHED =
  'order.REQUEST_CURRENT_ORDER_FINISHED'

export const CLEAR_CURRENT_ORDER = 'order.CLEAR_CURRENT_ORDER'
export const CLEAR_ORDER = 'order.CLEAR_ORDER'

export const REQUEST_CHECK_BENEFITS = 'order.REQUEST_CHECK_BENEFITS'
export const REQUEST_CHECK_BENEFITS_FINISHED =
  'order.REQUEST_CHECK_BENEFITS_FINISHED'

export const REQUEST_SORT = 'order.REQUEST_SORT'

export const REQUEST_CREATE_ANONYMOUS_ORDER =
  'order.REQUEST_CREATE_ANONYMOUS_ORDER'
export const REQUEST_CREATE_ANONYMOUS_ORDER_FINISHED =
  'order.REQUEST_CREATE_ANONYMOUS_ORDER_FINISHED'

export const REQUEST_ORDER_SEND = 'order.REQUEST_ORDER_SEND'
export const REQUEST_VALIDATE_CODE = 'order.REQUEST_VALIDATE_CODE'
export const REQUEST_VALIDATE_CODE_FINISHED =
  'order.REQUEST_VALIDATE_CODE_FINISHED'

export const REQUEST_CHECK_ORDER_BONUS = 'order.REQUEST_CHECK_ORDER_BONUS'
export const REQUEST_CHECK_ORDER_BONUS_FINISHED =
  'order.REQUEST_CHECK_ORDER_BONUS_FINISHED'

export const REQUEST_CALCULATE_CASHBACK = 'order.REQUEST_CALCULATE_CASHBACK'
export const REQUEST_CALCULATE_CASHBACK_FINISHED =
  'order.REQUEST_CALCULATE_CASHBACK_FINISHED'

export const REQUEST_APPLY_CASHBACK = 'order.REQUEST_APPLY_CASHBACK'
export const REQUEST_APPLY_CASHBACK_FINISHED =
  'order.REQUEST_APPLY_CASHBACK_FINISHED'

export const REQUEST_CREATE_ORDER = 'order.REQUEST_CREATE_ORDER'
export const REQUEST_CREATE_ORDER_FINISHED =
  'order.REQUEST_CREATE_ORDER_FINISHED'

export const REQUEST_RESEND_CODE = 'order.REQUEST_RESEND_CODE'
export const REQUEST_RESEND_CODE_FINISHED = 'order.REQUEST_RESEND_CODE_FINISHED'

export const REQUEST_REVERSAL_BENEFIT = 'order.REQUEST_REVERSAL_BENEFIT'
export const REQUEST_REVERSAL_BENEFIT_FINISHED =
  'order.REQUEST_REVERSAL_BENEFIT_FINISHED'

export function revertBenefit(orderId) {
  return async (dispatch, getState) => {
    return await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_REVERSAL_BENEFIT,
      Effects.revertBenefit,
      orderId
    )
  }
}

export function createOrder(data) {
  return async (dispatch, getState) => {
    return await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_CREATE_ORDER,
      Effects.createSaleEffect,
      data
    )
  }
}

export function calculateCashback(data) {
  return async (dispatch, getState) => {
    const response = await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_CALCULATE_CASHBACK,
      Effects.calculateCashbackEffect,
      { ...data, sellerId: getCurrentSellerSelected() }
    )

    return response
  }
}

export function applyCashback(data) {
  return async (dispatch, getState) => {
    return await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_APPLY_CASHBACK,
      Effects.applyCashbackEffect,
      data
    )
  }
}

export function create(data) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_CREATE,
      Effects.createSaleEffect,
      data
    )
  }
}

export const requestSort = sort =>
  ActionUtility.createAction(REQUEST_SORT, sort)

export const search = () => {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_SEARCH,
      Effects.searchOrdersEffect,
      getState().order.search.pagination,
      getState().order.search.filter,
      getState().order.search.sort
    )
  }
}

export function createFirstOrder(data) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_CREATE_FIRST_ORDER,
      Effects.createSaleFirstOrderEffect,
      data
    )
  }
}

export function createAnonymousOrder(data) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_CREATE_ANONYMOUS_ORDER,
      Effects.createSaleAnonymousOrderEffect,
      data
    )
  }
}

export function checkOrderBonus(purchaseValue, bonusToUse, cellPhone) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_CHECK_ORDER_BONUS,
      Effects.checkOrderBonus,
      purchaseValue,
      bonusToUse,
      cellPhone
    )
  }
}

export function sendOrder() {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_CREATE_ANONYMOUS_ORDER,
      Effects.sendOrderEffect,
      getState().order.orderResume.id
    )
  }
}

export function resendCode(orderId, resendType) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_RESEND_CODE,
      Effects.resendCodeEffect,
      orderId,
      resendType
    )
  }
}

export function resendCodeToManager(orderId, resendType) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_RESEND_CODE,
      Effects.resendCodeToManagerEffect,
      orderId,
      resendType
    )
  }
}

export function codeValidate(request) {
  return async (dispatch, getState) => {
    const response = await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_VALIDATE_CODE,
      Effects.validateCodeEffect,
      request
    )
    return response
  }
}

export const requestFilter = filter =>
  ActionUtility.createAction(REQUEST_FILTER, filter)

export const cleanFilter = () => ActionUtility.createAction(CLEAR_FILTER)

export const setPagination = pagination =>
  ActionUtility.createAction(SET_PAGINATION, pagination)

export function requestCheckBenefits(purchasePrice) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_CHECK_BENEFITS,
      Effects.requestCheckBenefits,
      purchasePrice
    )
  }
}

export function getCurrentOrder(id) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_CURRENT_ORDER,
      Effects.getOrderByIdEffect,
      id
    )
    // return !!model.errors
  }
}

export function clearCurrentOrder() {
  return (dispatch, getState) => {
    dispatch(ActionUtility.createAction(CLEAR_CURRENT_ORDER))
  }
}

export function clearOrder() {
  return (dispatch, getState) => {
    dispatch(ActionUtility.createAction(CLEAR_ORDER))
  }
}
