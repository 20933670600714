import { BaseReducer } from '@aberto/react-common'
import * as Actions from '../order/order-action'
import { produce } from 'immer'

export const initialState = {
  orders: [{}, {}, {}, {}, {}, {}],

  search: {
    pagination: {
      pageSize: 10,
      pageNumber: 1,
      numberOfRows: 10,
    },
    filter: {
      id: null,
      name: null,
      purchasePrice: {
        minimum: null,
        maximum: null,
      },
      cellPhone: null,
      status: null,
      createdDate: {
        start: null,
        end: null,
      },
    },
    sort: {
      descending: false,
      field: 'name',
    },
  },

  checkBenefits: {
    benefitsFound: null,
    cashbackReceiveds: [
      {
        cashbackAmount: null,
        name: null,
      },
    ],
    conditionsForPrizes: [
      {
        name: null,
        needToSpend: null,
        cashbackValue: null,
        cashbackType: null,
      },
    ],

    resume: {
      cashbackAmountReceived: null,
      cashbackAmountRedeemed: null,
      purchasePriceWithDiscount: null,
    },
  },

  orderResume: {
    id: null,
    cashbackReceiveds: [],
    resume: {
      participantBalance: null,
      cashbackAmountReceived: null,
      cashbackAmountRedeemed: null,
      purchasePriceWithDiscount: null,
    },
  },

  currentOrder: {
    id: null,
    purchasePrice: null,
    cellPhone: null,
    name: null,

    resume: {
      cashbackAmountReceived: null,
      cashbackAmountRedeemed: null,
      purchasePriceWithDiscount: null,
    },
    status: null,
    createdDate: null,
  },

  orderBonusOverview: {
    purchaseValue: 0,
    bonusToReceive: 0,
    bonusUsed: 0,
    paymentFinalValue: 0,
  },

  order: {}
}

//TODO: Quando response do calculateCashback for definido corretamente, realizar mapeamento do novo estado
export const orderReducer = BaseReducer(initialState, {
  [Actions.REQUEST_SEARCH_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.orders = payload.data
      draftState.search.pagination.numberOfRows = payload.numberOfRows
    })
  },

  [Actions.CLEAR_ORDER](state, { payload }) {
    return initialState
  },

  [Actions.REQUEST_CHECK_ORDER_BONUS_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.orderBonusOverview = payload
    })
  },

  [Actions.SET_PAGINATION](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.pagination = payload
    })
  },

  [Actions.REQUEST_CHECK_BENEFITS_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.checkBenefits = payload
    })
  },

  [Actions.REQUEST_SUMMARY_ORDER](state, { payload }) {
    return produce(state, draftState => {
      draftState.orders.benefitResume = payload
    })
  },

  [Actions.REQUEST_FILTER](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.filter = { ...state.search.filter, ...payload }
    })
  },
  [Actions.CLEAR_FILTER](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.filter = initialState.search.filter
    })
  },
  [Actions.REQUEST_CREATE_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.orderResume = payload
    })
  },
  [Actions.REQUEST_CREATE_FIRST_ORDER_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.orderResume = payload
    })
  },
  [Actions.REQUEST_VALIDATE_CODE_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.orderResume = initialState.orderResume
    })
  },
  [Actions.REQUEST_SORT](state, { payload }) {
    return produce(state, draftState => {
      draftState.search.sort = payload
    })
  },

  [Actions.REQUEST_CURRENT_ORDER_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.currentOrder = payload
    })
  },
  [Actions.CLEAR_CURRENT_ORDER](state, { payload }) {
    return initialState
  },
  [Actions.REQUEST_CALCULATE_CASHBACK_FINISHED](state, { payload }) {
    return produce(state, draftState => {
      draftState.order = payload.data
    })
  }
})
