import { useQuery } from '@tanstack/react-query'
import postApiInstance from '../promo-api'

export enum ParticipantKeyType {
  Cellphone,
  Document,
}

export const useGetStoreConfigurations = () =>
  useQuery<{
    participantKeyType: ParticipantKeyType
  }>({
    queryKey: ['get-store-configurations'],
    queryFn: async () => {
      const response = await postApiInstance.get('/store/configurations')

      return response.data
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 3,
  })
