import { Card, Modal, Row, Spin } from 'antd'
import { FC, useEffect } from 'react'
import { PrimaryButton } from '../../../global.styles'
import { useParticipantContext } from '../context/participant-context'
import { CenteredColumn } from '../../../components/CenteredColumn'
import { BoldText } from '../../../components/BoldText'
import { formatCPF, formatCellphone } from '../../../utilities/formatters'
import * as ParticipantActions from '../../../stores/participant/participant-action'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { RequestingSelector } from '@aberto/react-common'
import { currentParticipant } from '../../../selectors/participant/participant-selector'

const ModalFooter: FC = () => {
  const particiantContext = useParticipantContext()

  return (
    <Row>
      <CenteredColumn md={{ span: 12, offset: 6 }}>
        <PrimaryButton
          style={{ marginBottom: 0 }}
          onClick={particiantContext.openChangeCellphoneModal}
        >
          Alterar telefone
        </PrimaryButton>
      </CenteredColumn>
    </Row>
  )
}

export const ParticipantModal: FC = () => {
  const particiantContext = useParticipantContext()
  const dispatch = useDispatch()

  const participantFromAPI = useSelector(state =>
    currentParticipant(state)
  ) as any

  const isGettingParticipant = useSelector(state =>
    RequestingSelector.selectRequesting(state, [
      ParticipantActions.REQUEST_GET_BY_CELL_PHONE,
    ])
  )

  useEffect(() => {
    if (particiantContext.selectedParticipant.id)
      dispatch(
        ParticipantActions.getByCellPhone(
          particiantContext.selectedParticipant.cellPhone
        ) as any
      )
  }, [particiantContext.selectedParticipant.id])

  return (
    <Modal
      open={particiantContext.isParticipantModalOpened}
      footer={<ModalFooter />}
      onCancel={particiantContext.closeParticipantModal}
    >
      <CenteredColumn>
        <h3>{particiantContext.selectedParticipant.name || '-'}</h3>
      </CenteredColumn>
      <CenteredColumn>
        <p>
          <BoldText style={{}}>Tel:</BoldText>{' '}
          {formatCellphone(particiantContext.selectedParticipant.cellPhone)}{' '}
          <BoldText>CPF:</BoldText>{' '}
          {formatCPF(particiantContext.selectedParticipant.document)}
        </p>
      </CenteredColumn>
      <CenteredColumn>
        <Card
          style={{
            width: 300,
            boxShadow: '0px 14px 24px -5px rgba(0,0,0,0.24)',
          }}
        >
          <CenteredColumn style={{ marginLeft: -50 }}>Saldo</CenteredColumn>
          <CenteredColumn>
            <h4>
              {isGettingParticipant ? (
                <Spin />
              ) : (
                participantFromAPI?.balance?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                }) || 0
              )}
            </h4>
          </CenteredColumn>
        </Card>
      </CenteredColumn>
    </Modal>
  )
}
