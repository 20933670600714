import { environment } from '../../environments'
import promoApi from '../../api/promo-api'
import { EffectUtility } from '@aberto/react-common'
import qs from 'qs'

const endpoint = environment.promoApi.endpoints.participants

export const createParticipantEffect = async data => {
  return await EffectUtility.post(promoApi, endpoint.createParticipants, data)
}

export const updateParticipantEffect = (cellPhone, data) =>
  EffectUtility.put(
    promoApi,
    endpoint.updateParticipants.replace(':cellPhone', cellPhone),
    data
  )

export const searchParticipantEffect = async (pagination, filter, qsSort) => {
  const sort = [qsSort]

  const queryString = qs.stringify(
    { pagination, filter, sort },
    { allowDots: true, skipNulls: true }
  )

  return await EffectUtility.get(
    promoApi,
    `${endpoint.searchParticipants}?${queryString}`
  )
}

export const searchParticipantNotifyEffect = async (storeId, pagination, filter, qsSort) => {
  const sort = [qsSort]

  const queryString = qs.stringify(
    { storeId, pagination, filter, sort },
    { allowDots: true, skipNulls: true }
  )

  return await EffectUtility.get(
    promoApi,
    `${endpoint.getParticipantsNotify}?${queryString}`
  )
}

export const markParticipantAsNotified = (participantId) =>
  EffectUtility.put(
    promoApi,
    endpoint.markParticipantAsNotified.replace(':participantId', participantId)
  )

export const updateParticipantMarkNotifyEffect = (cellPhone, data) =>
  EffectUtility.put(
    promoApi,
    endpoint.updateParticipants.replace(':cellPhone', cellPhone),
    data
  )

export const getParticipantByCellPhoneEffect = async (
  cellPhone,
  storeId = null
) => {
  const queryString = qs.stringify(
    { storeId },
    { allowDots: true, skipNulls: true }
  )
  return (await promoApi.get(
    `${endpoint.getParticipantsByCellPhone.replace(
      ':cellPhone',
      cellPhone
    )}?${queryString}`
  ))?.data
}

export const getStatementsEffect = async (cellPhone, currentPage, storeId) => {
  const queryString = qs.stringify(
    { currentPage, storeId },
    { allowDots: true, skipNulls: true }
  )

  return await promoApi.get(
    `${endpoint.getStatements.replace(':cellPhone', cellPhone)}?${queryString}`
  )
}

export const getActiveBonusEffect = async (cellPhone, purchaseValue) => {
  return await EffectUtility.get(
    promoApi,
    `${endpoint.getActiveBonus.replace(
      ':cellPhone',
      cellPhone
    )}?purchaseValue=${purchaseValue}`
  )
}

export const validateCode = async (cellPhone, code) => {
  return await EffectUtility.post(
    promoApi,
    endpoint.validate.replace(':cellPhone', cellPhone).replace(':code', code)
  )
}

export const sendParticipantCodeEffect = async cellPhone => {
  return await EffectUtility.post(
    promoApi,
    endpoint.sendCode.replace(':cellPhone', cellPhone)
  )
}

export const changeCellphone = async (oldCellPhone, newCellphone) => {
  return await promoApi.post(
    endpoint.changeCellphone.replace(':oldCellphone', oldCellPhone).replace(':newCellphone', newCellphone)
  )
}

export const finalizeChangeCellphone = async (changeOrderId, verificationCode) => {
  return await promoApi.post(
    endpoint.finalizeChangeCellphone.replace(':changeCellphoneOrderId', changeOrderId),
    {
      verificationCode
    }
  )
}

export const resendChangeCellphoneValidationCode = async (changeOrderId) => {
  return await promoApi.post(
    endpoint.resendChangeCellphoneValidationCode.replace(':changeCellphoneOrderId', changeOrderId)
  )
}