import {
  ContainerButton,
  InputForm,
  PasswordInputForm,
  PrimaryButton,
} from '../../../global.styles'
import { CardVerification } from '../store-authentication.styles'
import { Col, Form, Row } from 'antd'
import { useCognitoContext } from '../../../context/authentication-provider'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import RouteEnum from '../../../constants/RouteEnum'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'

const Login = () => {
  const authContext = useCognitoContext()

  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const handleFormFinish = async (data: any) => {
    const { email, password } = data

    await authContext.signIn(email, password)
  }

  const goToForgotPassword = () => {
    const email = form.getFieldValue('email')

    if (email) dispatch(push(`${RouteEnum.ForgotPassword}?email=${email}`))
    else dispatch(push(RouteEnum.ForgotPassword))
  }

  return (
    <CardVerification>
      <h3>Login</h3>

      {authContext.signInError && (
        <small style={{ color: 'tomato' }}>
          Credenciais do usuário inválidas
        </small>
      )}

      <Form
        id="newSale"
        form={form}
        layout="vertical"
        onFinish={handleFormFinish}
        requiredMark={true}
      >
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              hasFeedback={false}
              id="email"
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputForm
                placeholder="exemplo@email.com"
                autoFocus={true}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              hasFeedback={false}
              id="password"
              name="password"
              label="Senha"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <PasswordInputForm
                iconRender={visible =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                type="password"
                placeholder="**********"
                allowClear
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <a onClick={goToForgotPassword}>Esqueceu a senha?</a>
          </Col>

          <Col span={24}>
            <ContainerButton>
              <PrimaryButton
                loading={authContext.signInIsLoading}
                onClick={form.submit}
              >
                Entrar
              </PrimaryButton>
            </ContainerButton>
          </Col>
        </Row>
      </Form>
    </CardVerification>
  )
}

export default Login
