import RouteEnum from '../../constants/RouteEnum'
import {
  ContainerGlobal,
} from '../../global.styles'
import { Route, Switch } from 'react-router-dom'
import OrderValueStep from './steps/order-value-step'
import BenefitOverviewStep from './steps/benefit-overview-step'
import BenefitSummaryStep from './steps/summary-step'
import CellphoneStep from './steps/cellphone-step'
import OrderStepProvider from './steps/context/order-step-context'
import UserPinValidationOrderStep from './steps/user-pin-validation-step'
import UserInfoStep from './steps/user-info-step'

export const OrderCreate = () => {
  return (
    <ContainerGlobal>
      <OrderStepProvider>
        <Switch>
          <Route
            exact
            path={RouteEnum.CellphoneOrderStep}
            component={CellphoneStep}
          />
          <Route
            exact
            path={RouteEnum.CheckValueOrderStep}
            component={OrderValueStep}
          />
          <Route
            exact
            path={RouteEnum.BenefitOverviewOrderStep}
            component={BenefitOverviewStep}
          />
          <Route
            exact
            path={RouteEnum.BenefitErrorStep}
            component={() => <BenefitOverviewStep isError={true} />}
          />
          <Route exact path={RouteEnum.UserInfoStep} component={UserInfoStep} />
          <Route
            exact
            path={RouteEnum.SummaryOrderStep}
            component={BenefitSummaryStep}
          />
          <Route
            exact
            path={RouteEnum.UserPinOrderStep}
            component={UserPinValidationOrderStep}
          />
          <Route
            exact
            path={RouteEnum.BenefitSummary}
            component={() => <BenefitOverviewStep isLastStep={true} />}
          />
        </Switch>
      </OrderStepProvider>
    </ContainerGlobal>
  )
}
