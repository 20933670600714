import { connectRouter } from 'connected-react-router'
import { NotificationReducer, RequestingReducer } from '@aberto/react-common'
import { combineReducers } from 'redux'
import { participantReducer } from './participant/participant-reducer'
import { orderReducer } from './order/order-reducer'
import { summaryReducer } from './summary/summary-reducer'
import { storeReducer } from './store/store-reducer'
import { SellerReducer } from './sellers/seller-reducer'

export default history => {
  const reducerMap = {
    router: connectRouter(history),
    participant: participantReducer,
    order: orderReducer,
    store: storeReducer,
    requesting: RequestingReducer,
    notification: NotificationReducer,
    sellers: SellerReducer,
    summary: summaryReducer,
  }

  return combineReducers(reducerMap)
}
