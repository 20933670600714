import styled from 'styled-components'

const TransactionListContent = styled.div`
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  padding: 15px;
  width: 100%;
  border-radius: 10px;
`

export default TransactionListContent
