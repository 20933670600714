const baseReducer = (initialState, methods) => {
  return (state = initialState, action) => {
    const method = methods[action.type]
    if (!method || action.error) {
      return state
    }
    return method(state, action)
  }
}
export default baseReducer
