import { Checkbox, Form } from 'antd'
import { push } from 'connected-react-router'
import { FC, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RouteEnum from '../../../constants/RouteEnum'

import { RequestingSelector } from '@aberto/react-common'
import * as ParticipantSelector from '../../../selectors/participant/participant-selector'
import * as OrderActions from '../../../stores/order/order-action'
import * as ParticipantActions from '../../../stores/participant/participant-action'
import { OrderStepContext } from './context/order-step-context'
import { Wrap, WrapOnlyShadow } from './steps-style'
import { PinChecker } from '../../../components/pin-checker/pin-checker'
import { ApiException, isApiException } from '../../../api/promo-api'

const UserPinValidationOrderStep: FC = () => {
  const participant = useSelector(state =>
    ParticipantSelector.currentParticipant(state)
  ) as any
  const orderStepContext = useContext(OrderStepContext)
  const dispatch = useDispatch()

  const [form] = Form.useForm()

  const [modalIsOpen, setIsOpen] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const [routeEnum, setRouteEnum] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [useMasterPin, setUseMasterPin] = useState(true)
  const [timeToEnablePinMaster, setTimeToEnablePinMaster] = useState(20)
  const [enablePinMasterUsage, setEnablePinMasterUsage] = useState(false)

  useEffect(() => {
    if (timeToEnablePinMaster <= 0) {
      setEnablePinMasterUsage(true)
      return
    }

    setTimeout(() => setTimeToEnablePinMaster(value => value - 1), 1000)
  }, [timeToEnablePinMaster])

  const inputRef = useRef(null)

  const currentParticipantCellPhone = orderStepContext.cellphone

  const order = useSelector((x: any) => x.order.order)

  const isRequestingOrder = useSelector(state =>
    RequestingSelector.selectRequesting(state, [
      OrderActions.REQUEST_VALIDATE_CODE,
    ])
  )

  const handleEnter = (event: any) => {
    const isNumber = Number(event.key.toLowerCase())
    if (!isNaN(isNumber)) {
      const form = event.target.form
      const index = [...form].indexOf(event.target)
      form.elements[index + 1].focus()
      event.preventDefault()
    }

    if (event.key.toLowerCase() === 'backspace') {
      const form = event.target.form
      const index = [...form].indexOf(event.target)
      form.elements[index].value = ''
      form.elements[index - 1].focus()
      event.preventDefault()
    }
  }

  function closeModal() {
    form.resetFields()
    setIsOpen(false)
    dispatch(ParticipantActions.clearCurrentParticipant() as any)

    setRouteEnum(dispatch(push(RouteEnum.Home)) as any)
  }

  function onChange() {
    const fieldsValue = Object.keys(form.getFieldsValue()).map(function (key) {
      return form.getFieldsValue()[key]
    })

    setIsFormValid(
      fieldsValue.filter(field => {
        return isNaN(Number(field))
      }).length === 0
    )
  }

  const resendCode = (
    resendToStoreManager = false,
    resendType: 'SMS' | 'Whatsapp' = 'SMS'
  ) => {
    if (!resendToStoreManager)
      return dispatch(OrderActions.resendCode(order.id, resendType) as any)

    dispatch(OrderActions.resendCodeToManager(order.id, resendType) as any)
  }

  const onFinishForm = async (_data: any) => {
    let verificationCode = ''

    for (const key in _data) {
      verificationCode += _data[key]
    }

    const response = await dispatch(
      OrderActions.codeValidate({
        verificationCode,
        orderId: order.id,
        validateVerificationCodeByStoreCode:
          useMasterPin && enablePinMasterUsage,
      }) as any
    )

    if (isApiException(response)) {
      setEnablePinMasterUsage(true)
      return setErrorMessage((response as any).message.message)
    }

    if (
      !participant ||
      participant.status === 1 ||
      !participant.name ||
      !participant.document
    )
      dispatch(push(RouteEnum.UserInfoStep))
    else dispatch(push(RouteEnum.BenefitSummary))
  }

  return (
    <>
      <WrapOnlyShadow style={{ marginTop: 50 }}>
        <Checkbox
          disabled={!enablePinMasterUsage}
          checked={useMasterPin}
          value={useMasterPin}
          onChange={e => setUseMasterPin(e.target.checked)}
        >
          {enablePinMasterUsage
            ? 'Utilizar PinMaster?'
            : `Habilitando o PinMaster em 00:${timeToEnablePinMaster
                .toString()
                .padStart(2, '0')}`}
        </Checkbox>
        <PinChecker
          setErrorMessage={setErrorMessage}
          errorMessage={errorMessage}
          onFinish={onFinishForm}
          text={'Validar código'}
          isLoading={isRequestingOrder}
          cellPhone={currentParticipantCellPhone?.replace(
            /^(\d\d)(\d{5})(\d{4}).*/,
            '($1) $2-$3'
          )}
          reducesize={null}
          resend={resendCode}
          isVerificationForOrder={true}
        />
      </WrapOnlyShadow>
    </>
  )
}

export default UserPinValidationOrderStep
