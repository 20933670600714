import styled from 'styled-components'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Input, Button, DatePicker, Pagination, Select } from 'antd'
import InputMask from 'react-input-mask'

export const Container = styled.div`
  margin: 0 auto;
  max-width: 700px;
  height: 100%;
`
export const ContainerLogged = styled.div`
  margin: 40px auto;
  max-width: 700px;
  height: 100%;
`
export const ContainerFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`
export const BackButton = styled(ArrowLeftOutlined)`
  font-size: 30px;
  color: #001d42;
  margin-bottom: 20px;
`
export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px 0;
`
export const RightContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
`
export const LeftContainerButton = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
`
export const MainButton = styled(Button)`
  height: 30px !important;
  padding: 10px 25px !important;
  background: #001d42 !important;
  border: 2px solid #001d42 !important;
  color: #ffffff !important;
  font-size: 13px !important;
  font-weight: lighter !important;
  width: 180px;
  margin-right: 0 !important;

  span {
    font-family: 'Poppins', sans-serif !important;
    font-weight: lighter !important;
  }
`
export const MainButtonPage = styled(Button)`
  height: 30px !important;
  padding: 10px 25px !important;
  background: #001d42 !important;
  border: 2px solid #001d42 !important;
  color: #ffffff !important;
  font-size: 13px !important;
  font-weight: lighter !important;
  float: right;
  width: 180px;
  margin-right: 0 !important;

  span {
    font-family: 'Poppins', sans-serif !important;
    font-weight: lighter !important;
  }
`
export const MainButtonExtractPage = styled(Button)`
  height: 30px !important;
  padding: 10px 25px !important;
  background: white !important;
  border: 2px solid #001d42 !important;
  color: #001d42 !important;
  font-size: 13px !important;
  font-weight: lighter !important;
  float: right;
  width: 180px;
  margin-right: 0 !important;

  span {
    font-family: 'Poppins', sans-serif !important;
    font-weight: lighter !important;
  }
`

export const OtherButton = styled(Button)`
  height: 30px !important;
  padding: 10px 30px !important;
  width: 180px;
  background: #fff !important;
  border: 2px solid #27f3b5 !important;
  color: #27f3b5 !important;
  font-size: 13px !important;
  font-weight: 100 !important;
`
export const PlusButton = styled(Button)`
  height: 30px !important;
  padding: 10px 30px !important;
  width: 180px;
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  color: #27f3b5 !important;
  font-size: 13px !important;
  font-weight: 100 !important;
  margin-bottom: 20px;
`
export const FilterButtons = styled.div`
  display: flex;
  flex-direction: row;
`
export const LabelForm = styled.div`
  display: flex;
  flex-direction: row;
`
export const PickerDateForm = styled(DatePicker)`
  background: red !important;
  border: none !important;
`

export const MainButtonDrawer = styled(Button)`
  background: #001d42 !important;
  color: #ffffff !important;
  border: 2px solid #001d42 !important;
  height: 35px !important;
  width: 180px;
  margin: 5px;
  font-size: 14px !important;
`
export const OtherButtonDrawer = styled(Button)`
  background: #fff !important;
  color: #27f3b5 !important;
  border: 2px solid #27f3b5 !important;
  height: 35px !important;
  width: 180px;
  margin: 5px;
  font-size: 14px !important;
`

export const ButtonEdit = styled.button`
  background: transparent;
  color: #001c3f;
  border: none;
  margin: 0 5px;
  font-size: 15px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`

export const PaginationGeral = styled(Pagination)`
  //width: 100% !important;
  margin-top: 30px;
  text-align: right; ;
`

export const GroupSubtitle = styled.h5`
  font-weight: 700;
  font-size: 16px !important;
  white-space: nowrap;
  padding-right: 20px;
  display: flex;
  align-items: center;
  margin: 40px 0 20px;

  &::after {
    content: '';
    background: var(--tb-green) !important;
    display: block;
    height: 1px !important;
    width: 100%;
    margin-left: 10px;
    min-width: 10px;

`

export const InputMaskCustom = styled(InputMask)`
  background: #ffffff;
  border-radius: 13px;
  border: 1px solid #001d42;
  width: 100%;
  height: 30px;
  margin: 5px 0 !important;
  text-align: left;
  padding-left: 15px;
  color: #001d42;
  font-size: 0.9rem;

  &::placeholder {
    font-size: 13px !important;
    font-family: 'Poppins', sans-serif;
    color: #c1bfbf;
  }

  &:disabled {
    border: 1px solid #e6e6e6;
    background: #f5f5f5 !important;
    color: #001d42;
  }
`
export const InputForm = styled(Input)`
  background: #ffffff;
  border-radius: 13px;
  border: 1px solid #001d42;
  width: 100%;
  height: 30px;
  font-size: 0.9rem;
  font-weight: lighter !important;
  margin: 5px 0 !important;
  text-align: left;
  padding-left: 15px;

  &::placeholder {
    font-size: 13px !important;
    font-family: 'Poppins', sans-serif;
  }
  &:disabled {
    border: 1px solid #e6e6e6;
    background: #f5f5f5 !important;
    color: #001d42;
  }
`

export const SelectCustom = styled(Select)`
  background: #ffffff;
  border-radius: 13px;
  border: 1px solid #001d42;
  width: 100%;
  height: 30px;
  margin: 5px 0 !important;
  text-align: left;
  padding-left: 15px;
  color: black;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500 !important;

  &::placeholder {
    color: #c1bfbf;
  }

  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    font-family: 'Poppins', sans-serif;
    color: #001d42;

    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }
`

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  background: #001d42 !important;
  height: 20px;
  z-index: 1000;
  color: #9398a7;
  margin: 0;
  padding: 0;
`
export const SpanBold = styled.span`
  font-weight: bold;
`
