// TODO: ver oque pode remover

enum RouteEnum {
  Login = '/login',
  ForgotPassword = '/esqueci-minha-senha',
  ForgotPasswordInsertNewPassword = '/esqueci-minha-senha/confirmacao',
  ConfirmPassword = '/confirmar-nova-senha',
  Home = '/',
  Sales = '/ofertas',
  Faq = '/faq',
  Training = '/treinamento',
  LandingPage = '/landingpage',
  Dashboard = '/dashboard',
  LoggedTerms = '/termo-aceito',

  TransactionList = '/vendas',

  SellerList = '/colaboradores',
  SellerCreate = '/colaboradores/novo',
  SellerUpdate = '/colaboradores/editar/:sellerId',

  CellphoneOrderStep = '/',
  CheckValueOrderStep = '/venda/valor',
  BenefitOverviewOrderStep = '/venda/verificar-beneficio',
  BenefitErrorStep = '/venda/error',
  SummaryOrderStep = '/venda/resumo',
  UserPinOrderStep = '/venda/validar-pin',
  UserInfoStep = '/venda/cliente',
  BenefitSummary = '/venda/resumo-beneficio',
  ParticipantList = '/clientes',
  ParticipantNotifyList = '/clientes-notificacao',
  ParticipantTransactionList = '/clientes/:id/extrato',

  SellersAvailable = '/lojas-participantes',
}

export default RouteEnum;
