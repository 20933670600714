import { push } from 'connected-react-router'
import { createContext, FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import RouteEnum from '../../../../constants/RouteEnum'

type ContextType = {
  hasApiError: boolean
  cellphone: string
  purchasePrice: number
  orderId: string
  shouldUseBurnBalance: boolean
  shouldUsePinCodeBurn: boolean
  successModalOpened: boolean
  setShouldUseBurnBalance: (value: boolean) => void
  setShouldUsePinCodeBurn: (value: boolean) => void
  setPurchasePrice: (value: number) => void
  setCellphone: (value: string) => void
  isCellphoneValid: () => boolean
  setOrderId: (value: string) => void
  setSuccessModalOpened: (Value: boolean) => void
  bonusToUse: string
  setBonusToUse: (value: string) => void
  clearState: () => void
  setApiError: () => void
}

export const OrderStepContext = createContext<ContextType>({
  cellphone: '',
  hasApiError: true,
  purchasePrice: 0,
  shouldUseBurnBalance: false,
  shouldUsePinCodeBurn: false,
  setShouldUseBurnBalance: (value: boolean) => null,
  setShouldUsePinCodeBurn: (value: boolean) => null,
  setPurchasePrice: (value: number) => null,
  setCellphone: (value: string) => null,
  isCellphoneValid: () => false,
  orderId: '',
  setOrderId: () => null,
  successModalOpened: false,
  setSuccessModalOpened: () => null,
  bonusToUse: '',
  setBonusToUse: (name: string) => null,
  clearState: () => null,
  setApiError: () => null,
})

const OrderStepProvider: FC<any> = ({ children }) => {
  const dispatch = useDispatch()

  const [cellphone, setCellphone] = useState('')
  const [shouldUseBurnBalance, setShouldUseBurnBalance] = useState(true)
  const [shouldUsePinCodeBurn, setShouldUsePinCodeBurn] = useState(false)
  const [purchasePrice, setPurchasePrice] = useState(0)
  const [orderId, setOrderId] = useState('')
  const [successModalOpened, setSuccessModalOpened] = useState(false)
  const [bonusToUse, setBonusToUse] = useState('')
  const [hasApiError, setHasApiError] = useState(false)

  const isCellphoneValid = () => cellphone.length >= 10

  const clearState = () => {
    setPurchasePrice(0)
    setOrderId('')
    setBonusToUse('')
  }

  const setApiError = () => {
    setHasApiError(true)
    dispatch(push(RouteEnum.BenefitErrorStep))
  }

  return (
    <OrderStepContext.Provider
      value={{
        hasApiError,
        setApiError,
        shouldUseBurnBalance,
        setShouldUseBurnBalance,
        cellphone,
        purchasePrice,
        setCellphone,
        setPurchasePrice,
        isCellphoneValid,
        setOrderId,
        shouldUsePinCodeBurn,
        setShouldUsePinCodeBurn,
        orderId,
        successModalOpened,
        setSuccessModalOpened,
        bonusToUse,
        setBonusToUse,
        clearState,
      }}
    >
      {children}
    </OrderStepContext.Provider>
  )
}

export default OrderStepProvider;
