export const orders = state => state.order.orders
export const pagination = state => state.order.search.pagination
export const currentOrder = state => state.order.currentOrder
export const orderResume = state => state.order.orderResume
export const checkBenefits = state => state.order.checkBenefits
export const hasFoundBenefits = state =>
  state.order.checkBenefits.benefitsFound !== null
export const filter = state => state.order.search.filter
export const sort = state => state.order.search.sort

export const bonusOverview = state => state.order.orderBonusOverview
