import { ActionUtility } from '@aberto/react-common'
import { Dispatch } from 'redux'
import { RootReducerType } from '../RootReducerType'
import * as Effects from './seller-effect'
import { SellerForm } from '../../views/sellers/components/seller-form'

const REQUEST_SEARCH = 'SELLERS.REQUEST_SEARCH'
const REQUEST_SEARCH_FINISHED = 'SELLERS.REQUEST_SEARCH_FINISHED'

const REQUEST_CREATE_SELLER = 'SELLERS.REQUEST_CREATE_SELLER'
const REQUEST_CREATE_SELLER_FINISHED = 'SELLERS.REQUEST_CREATE_SELLER_FINISHED'

const REQUEST_UPDATE_SELLER = 'SELLERS.REQUEST_UPDATE_SELLER'
const REQUEST_UPDATE_SELLER_FINISHED = 'SELLERS.REQUEST_UPDATE_SELLER_FINISHED'

const REQUEST_GET_IDENTIFICATION_INFO =
  'SELLERS.REQUEST_GET_IDENTIFICATION_INFO'

const REQUEST_GET_IDENTIFICATION_INFO_FINISHED =
  'SELLERS.REQUEST_GET_IDENTIFICATION_INFO_FINISHED'

export interface SellerSearchType {
  pagination: {
    pageSize: number
    pageNumber: number
  }
}

const getSummary = (request: SellerSearchType) => {
  return async (dispatch: Dispatch, stateFn: () => RootReducerType) => {
    return await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_SEARCH,
      Effects.search,
      request
    )
  }
}

// TODO: Adicionar no redux o response quando precisar
const getById = (id: string) => {
  return async (dispatch: Dispatch, stateFn: () => RootReducerType) => {
    return await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_SEARCH,
      Effects.getById,
      id
    )
  }
}

const createSeller =
  (request: SellerForm) =>
  async (dispatch: Dispatch, stateFn: () => RootReducerType) => {
    return await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_SEARCH,
      Effects.create,
      request
    )
  }

const updateSeller =
  (sellerId: string, request: SellerForm) =>
  async (dispatch: Dispatch, stateFn: () => RootReducerType) => {
    return await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_SEARCH,
      Effects.update,
      sellerId,
      request
    )
  }

const getIdentificationInfo = () => async (dispatch: Dispatch) => {
  return await ActionUtility.createThunkEffect(
    dispatch,
    REQUEST_GET_IDENTIFICATION_INFO,
    Effects.getIdentificationInfo
  )
}

export {
  getSummary,
  createSeller,
  updateSeller,
  getById,
  getIdentificationInfo,
  REQUEST_SEARCH_FINISHED,
  REQUEST_SEARCH,
  REQUEST_CREATE_SELLER,
  REQUEST_CREATE_SELLER_FINISHED,
  REQUEST_UPDATE_SELLER,
  REQUEST_UPDATE_SELLER_FINISHED,
  REQUEST_GET_IDENTIFICATION_INFO,
  REQUEST_GET_IDENTIFICATION_INFO_FINISHED,
}
