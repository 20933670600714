import promoApi from '../../api/promo-api'
import { EffectUtility } from '@aberto/react-common'
import QueryString from 'qs'
import { SellerSearchType } from './seller-action'
import { SellerForm } from '../../views/sellers/components/seller-form'

const search = async (requestParams: SellerSearchType) => {
  const queryStringParams = QueryString.stringify(requestParams, {
    allowDots: true,
    skipNulls: true,
  })

  return await EffectUtility.get(
    promoApi,
    `sellers/search?${queryStringParams}`
  )
}

const getById = async (id: string) =>
  await EffectUtility.get(promoApi, `sellers/${id}`)

const getIdentificationInfo = async () =>
  await EffectUtility.get(promoApi, `sellers/identification-info`)

const create = async (request: SellerForm) =>
  await EffectUtility.post(promoApi, `sellers`, request)

const update = async (sellerId: string, request: SellerForm) =>
  await EffectUtility.put(promoApi, `sellers/${sellerId}`, request)

export { search, create, update, getById, getIdentificationInfo }
