import Page from '../../components/layout/page'
import { ContainerGlobalLogin } from './store-authentication.styles'
import { withoutAuthentication } from '../../context/authentication-provider'
import RouteEnum from '../../constants/RouteEnum'
import { Route, Switch } from 'react-router-dom'
import ForgotPassword from './components/forgot-password'
import ForgotPasswordInsertNewPassword from './components/forgot-password-insert-new-password'
import ConfirmNewPassword from './components/confirm-new-password'
import Login from './components/login'

const StoreAuthentication = () => {
  return (
    <Page>
      <ContainerGlobalLogin>
        <Switch>
          <Route exact path={RouteEnum.Login} component={Login} />
          <Route
            exact
            path={RouteEnum.ForgotPassword}
            component={ForgotPassword}
          />
          <Route
            path={RouteEnum.ForgotPasswordInsertNewPassword}
            component={ForgotPasswordInsertNewPassword}
          />
          <Route
            path={RouteEnum.ConfirmPassword}
            component={ConfirmNewPassword}
          />
        </Switch>
      </ContainerGlobalLogin>
    </Page>
  )
}

export default withoutAuthentication(StoreAuthentication)
