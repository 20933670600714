/* eslint-disable react/prop-types */
import { Col, Form, Row } from 'antd'
import { useEffect, useRef, useState } from 'react'

import {
  PinCode,
  PinCodeInput,
  PinCodeVerificationContainer,
  PinSpan,
} from './pin-verification.styles'
import { SendButton } from './pin-checker.styles'
import { ContainerButton, PrimaryButton } from '../../global.styles'

export const PinChecker = ({
  reducesize,
  resend,
  text,
  cellPhone,
  onFinish,
  isLoading,
  errorMessage,
  setErrorMessage,
  isVerificationForOrder = false,
}) => {
  const [counter, setCounter] = useState(20)
  const [resendCode, setResendCode] = useState(true)
  const [isDisabled, setIsDisabled] = useState(true)
  const [isFormValid, setIsFormValid] = useState(false)

  const inputRef = useRef(null)
  const [form] = Form.useForm()

  const handleResendCode = (...props) => {
    form.resetFields()
    setErrorMessage('')
    setResendCode(true)
    setCounter(20)
    setIsDisabled(true)
    resend(...props)
  }

  function onChange(e) {
    const fieldsValue = Object.keys(form.getFieldsValue()).map(function (key) {
      return form.getFieldsValue()[key]
    })

    setIsFormValid(
      fieldsValue.filter(field => {
        return isNaN(Number(field))
      }).length === 0
    )

    handleEnter2(e)
  }

  const handleEnter2 = event => {
    const isNumber = Number(event.target.value.toLowerCase())
    if (!isNaN(isNumber)) {
      const form = event.target.form
      const index = [...form].indexOf(event.target)
      form.elements[index + 1].focus()
      event.preventDefault()
    }
  }

  const handleEnter = event => {
    if (event.key.toLowerCase() === 'backspace') {
      const form = event.target.form
      const index = [...form].indexOf(event.target)
      form.elements[index].value = ''
      form.elements[index - 1]?.focus()
      event.preventDefault()
    }
  }

  useEffect(() => {
    inputRef.current.focus()
  }, [inputRef])

  useEffect(() => {
    let timer = null

    if (counter) {
      timer = setInterval(() => setCounter(oldCounter => oldCounter - 1), 1000)
    } else {
      setResendCode(false)
      setIsDisabled(false)
    }

    return () => clearInterval(timer)
  }, [counter])

  useEffect(() => {
    setErrorMessage('')
    setResendCode(true)
    setCounter(20)
    setIsDisabled(true)
  }, [])

  return (
    <PinCodeVerificationContainer>
      <Form onFinish={onFinish} form={form} layout="horizontal">
        <PinSpan>
          Enviamos um código de verificação para o número:
          <span>{cellPhone}</span>
        </PinSpan>

        <PinCode reducesize={reducesize}>
          <Form.Item name="cod-one">
            <PinCodeInput
              inputMode="tel"
              mask="0"
              onChange={onChange}
              showError={!!errorMessage}
              autoFocus={true}
              ref={inputRef}
            />
          </Form.Item>

          <Form.Item name="cod-two">
            <PinCodeInput
              inputMode="tel"
              onKeyUp={handleEnter}
              showError={!!errorMessage}
              mask="0"
              onChange={onChange}
            />
          </Form.Item>

          <Form.Item name="cod-three">
            <PinCodeInput
              inputMode="tel"
              onKeyUp={handleEnter}
              showError={!!errorMessage}
              mask="0"
              onChange={onChange}
            />
          </Form.Item>

          <Form.Item name="cod-four">
            <PinCodeInput
              inputMode="tel"
              onKeyUp={handleEnter}
              showError={!!errorMessage}
              mask="0"
              onChange={onChange}
            />
          </Form.Item>

          <Form.Item name="cod-five">
            <PinCodeInput
              inputMode="tel"
              onKeyUp={handleEnter}
              showError={!!errorMessage}
              mask="0"
              onChange={onChange}
            />
          </Form.Item>

          <Form.Item name="cod-six">
            <PinCodeInput
              inputMode="tel"
              onKeyUp={handleEnter}
              showError={!!errorMessage}
              mask="0"
              onChange={onChange}
            />
          </Form.Item>
        </PinCode>
        {errorMessage && (
          <Form.Item style={{ textAlign: 'center' }}>
            <span style={{ color: 'red' }}>{errorMessage}</span>
          </Form.Item>
        )}
        <ContainerButton span={24} lg={24}>
          <PrimaryButton
            htmlType="submit"
            disabled={!isFormValid}
            loading={isLoading}
          >
            {text}
          </PrimaryButton>
        </ContainerButton>

        <PinSpan>Reenviar código para cliente via:</PinSpan>

        {isVerificationForOrder ? (
          <Row>
            <Col
              md={10}
              offset={2}
              style={{
                alignItems: 'revert',
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              <SendButton
                onClick={x => handleResendCode(false, 'SMS')}
                disabled={isDisabled}
              >
                {!resendCode ? 'SMS' : <span>SMS em {`0:${counter}`}</span>}
              </SendButton>
            </Col>
            <Col md={10} offset={1}>
              <SendButton
                onClick={x => handleResendCode(false, 'Whatsapp')}
                disabled={isDisabled}
              >
                {!resendCode ? (
                  'Whatsapp'
                ) : (
                  <span>Whatsapp em {`0:${counter}`}</span>
                )}
              </SendButton>
            </Col>
          </Row>
        ) : (
          <ContainerButton span={24} lg={24}>
            <SendButton
              onClick={x => handleResendCode(false)}
              disabled={isDisabled}
            >
              {!resendCode ? (
                'SMS'
              ) : (
                <span>
                  SMS em: {`0:${counter.toString().padStart(2, '0')}`}
                </span>
              )}
            </SendButton>
          </ContainerButton>
        )}

        {isVerificationForOrder && (
          <>
            <PinSpan>Enviar código para gerente da loja via:</PinSpan>
            <Row>
              <Col
                md={10}
                offset={2}
                style={{
                  alignItems: 'revert',
                  display: 'flex',
                  justifyContent: 'right',
                }}
              >
                <SendButton
                  onClick={x => handleResendCode(true, 'SMS')}
                  disabled={isDisabled}
                >
                  {!resendCode ? 'SMS' : <span>SMS em {`0:${counter}`}</span>}
                </SendButton>
              </Col>
              <Col md={10} offset={1}>
                <SendButton
                  onClick={x => handleResendCode(true, 'Whatsapp')}
                  disabled={isDisabled}
                >
                  {!resendCode ? (
                    'Whatsapp'
                  ) : (
                    <span>Whatsapp em {`0:${counter}`}</span>
                  )}
                </SendButton>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </PinCodeVerificationContainer>
  )
}
