import { FC, useState } from 'react'
import { Button, Table, TablePaginationConfig, Tooltip } from 'antd'
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
  FileDoneOutlined,
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import * as ParticipantActions from '../../../stores/participant/participant-action'
import { RequestingSelector } from '@aberto/react-common'
import { formatCPF, formatCellphone } from '../../../utilities/formatters'
import { Participant } from '../../../@types/participant'
import { push } from 'connected-react-router'
import RouteEnum from '../../../constants/RouteEnum'
import moment from 'moment'
import styled from 'styled-components'

const CustomButton = styled(Button)`
&.ant-btn-link:hover {
  color: darkblue;
}
&.ant-btn-link:focus{
  color: darkblue;
}
`

export const ParticipantListTable: FC = () => {
  const [isDescendingSort, setIsDescendingSort] = useState<boolean>(false)
  const dispatch = useDispatch()

  const participantRows = useSelector(
    (state: any) => state.participant.search.result
  )

  const handleChangeSortOnClick = () => {
    setIsDescendingSort(!isDescendingSort)
    dispatch(ParticipantActions.setSort(!isDescendingSort) as any)
    dispatch(ParticipantActions.searchParticipant() as any)
  }

  const handleOnChangePagination = (pagination: TablePaginationConfig) => {
    dispatch(ParticipantActions.setPagination(pagination.current!) as any)
    dispatch(ParticipantActions.searchParticipant() as any)
  }

  const isRequestingData = useSelector(state =>
    RequestingSelector.selectRequesting(state, [
      ParticipantActions.REQUEST_SEARCH_PARTICIPANT,
    ])
  )

  const columns = [
    {
      title: (
        <>
          Nome
          <Button
            icon={
              isDescendingSort ? (
                <SortDescendingOutlined />
              ) : (
                <SortAscendingOutlined />
              )
            }
            type="text"
            onClick={handleChangeSortOnClick}
          />
        </>
      ),
      dataIndex: 'name',
      render: (title: string) => (title ? title : '-'),
    },
    {
      title: 'Celular',
      dataIndex: 'cellPhone',
      render: formatCellphone,
    },
    {
      title: 'CPF',
      dataIndex: 'document',
      render: (title: string) => (title ? formatCPF(title) : '-'),
    },
    {
      "title": "Data de cadastro",
      "dataIndex": "createdDate",
      render: (title: string) => moment(title).format('DD/MM/YYYY')
    },
    {
      render: (participant: Participant) => {
        return (
          <>
            {/* TODO: Voltar esse código quando o backend estiver funcional a alteração no digital account */}
            {/* {profile === 'Manager' && (
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() =>
                  participantContext.openParticipantModal(participant)
                }
              />
            )} */}
            <Tooltip title="Extrato">
              <CustomButton
                type="link"
                icon={<FileDoneOutlined />}
                onClick={() =>
                  dispatch(
                    push(
                      RouteEnum.ParticipantTransactionList.replace(
                        ':id',
                        participant.cellPhone
                      )
                    )
                  )
                }
              />
            </Tooltip>
          </>
        )
      },
    },
  ]

  return (
    <Table
      columns={columns}
      loading={isRequestingData}
      dataSource={participantRows?.data}
      onChange={handleOnChangePagination}
      pagination={{
        total: participantRows?.numberOfRows,
      }}
      rowKey={'cellPhone'}
    />
  )
}
