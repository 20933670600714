import IMask from 'imask';
import moment from 'moment';

const DATE_FORMAT = 'MM/DD/YYYY'
const MASKED = IMask.createMask({
  blocks: {
    DD: {from: 1, mask: IMask.MaskedRange, to: 31},
    MM: {from: 1, mask: IMask.MaskedRange, to: 12},
    YYYY: {from: 1900, mask: IMask.MaskedRange, to: Number.MAX_VALUE},
  },
  format: (date: Date) => moment(date).format(DATE_FORMAT),
  mask: Date,
  parse: (date: string) => moment(date, DATE_FORMAT),
  pattern: DATE_FORMAT,
})

export const maskedDate = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const input = event.target as HTMLInputElement;
  if (input.value.length >= 10) {
    return;
  } else {
    input.value = MASKED.resolve(input.value);
  }
}