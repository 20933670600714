import { FC, useEffect, useState } from 'react'
import Page from '../../components/layout/page'
import { Row, Col, Button, Space } from 'antd'
import PdfRender from './pdf-render'
import { useGetTerm } from '../../api/queries/useGetTerm'
import { useAcceptTerm } from '../../api/mutations/useAcceptTerm'
import { withOnlyAuthenticated } from '../../context/authentication-provider'

const Terms: FC<{ isViewingOnly?: boolean }> = ({ isViewingOnly = false }) => {
  const [geolocation, setGeolocation] = useState({} as any)
  const { data, isLoading, isError } = useGetTerm()
  const acceptTerm = useAcceptTerm()

  useEffect(() => {
    if (navigator.geolocation)
      navigator.geolocation.getCurrentPosition(setGeolocation)
  }, [])

  return (
    <Page>
      <Row>
        <Col
          md={{ span: 12, offset: 6 }}
          xs={24}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <h1>Termo de aceite</h1>
          {isLoading ? (
            'Carregando termo...'
          ) : data?.fileUrl ? (
            <div
              style={{
                overflow: 'scroll',
                height: 500,
                width: '110%',
                overflowX: 'auto',
              }}
            >
              <PdfRender url={data?.fileUrl!} />
              <p>CNPJ: {data?.storeId}</p>
              <p>Loja: {data?.storeName}</p>
              <Space>
                <a href={data?.fileUrl} target="_blank" rel="noreferrer">
                  <Button disabled={isError || isLoading}>
                    Baixar termo de aceite
                  </Button>
                </a>
                {!isViewingOnly && (
                  <Button
                    disabled={isError || isLoading}
                    type="primary"
                    onClick={() => acceptTerm.mutate(geolocation?.coords)}
                  >
                    Continuar e aceitar
                  </Button>
                )}
              </Space>
            </div>
          ) : (
            <p>
              Aconteceu um problema ao carregar o PDF, favor tentar novamente
              atualizando a página
            </p>
          )}
        </Col>
      </Row>
    </Page>
  )
}

export default Terms
