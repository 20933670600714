import { ActionUtility } from '@aberto/react-common'
import * as Effects from './participant-effects'

export const REQUEST_GET_BY_CELL_PHONE = 'participant.REQUEST_GET_BY_CELL_PHONE'
export const REQUEST_GET_BY_CELL_PHONE_FINISHED =
  'participant.REQUEST_GET_BY_CELL_PHONE_FINISHED'

export const SET_CURRENT_PARTICIPANT = 'participant.SET_CURRENT_PARTICIPANT'

export const CLEAR_CURRENT_PARTICIPANT = 'participant.CLEAR_CURRENT_PARTICIPANT'

export const CLEAR_PARTICIPANT = 'participant.CLEAR_PARTICIPANT'

export const REQUEST_CREATE_PARTICIPANT =
  'participant.REQUEST_CREATE_PARTICIPANT'
export const REQUEST_CREATE_PARTICIPANT_FINISHED =
  'participant.REQUEST_CREATE_PARTICIPANT_FINISHED'

export const REQUEST_SEARCH_PARTICIPANT =
  'participant.REQUEST_SEARCH_PARTICIPANT'

export const REQUEST_SEARCH_PARTICIPANT_NOTIFY =
  'participant.REQUEST_SEARCH_PARTICIPANT_NOTIFY'

export const REQUEST_MARK_PARTICIPANT_AS_NOTIFIED =
  'participant.REQUEST_MARK_PARTICIPANT_AS_NOTIFIED'

export const REQUEST_SEARCH_PARTICIPANT_FINISHED =
  'participant.REQUEST_SEARCH_PARTICIPANT_FINISHED'

export const SET_SORT = 'participant.SET_SORT'

export const SET_FILTER = 'participant.SET_FILTER'

export const SET_PAGINATION = 'participant.SET_PAGINATION'

export const REQUEST_GET_STATEMENTS = 'participant.REQUEST_GET_STATEMENTS'

export const REQUEST_GET_STATEMENTS_NEXT_PAGE =
  'participant.REQUEST_GET_STATEMENTS_NEXT_PAGE'

export const REQUEST_GET_STATEMENTS_NEXT_PAGE_FINISHED =
  'participant.REQUEST_GET_STATEMENTS_NEXT_PAGE_FINISHED'

export const REQUEST_GET_STATEMENTS_FINISHED =
  'participant.REQUEST_GET_STATEMENTS_FINISHED'

export const SET_STATEMENTS_PAGINATION = 'participant.SET_STATEMENTS_PAGINATION'

export const SET_STATEMENTS_PHONE = 'participant.SET_STATEMENTS_PHONE'

export const SET_STATEMENTS_STOREID = 'participant.SET_STATEMENTS_STOREID'

export const REQUEST_CHANGE_CELLPHONE_NUMBER = "participant.REQUEST_CHANGE_CELLPHONE_NUMBER"
export const REQUEST_CHANGE_CELLPHONE_NUMBER_FINISHED = "participant.REQUEST_CHANGE_CELLPHONE_NUMBER_FINISHED"

export const REQUEST_FINISHE_CHANGE_CELLPHONE_NUMBER = "participant.REQUEST_FINISHE_CHANGE_CELLPHONE_NUMBER"
export const REQUEST_FINISHE_CHANGE_CELLPHONE_NUMBER_FINISHED = "participant.REQUEST_FINISHE_CHANGE_CELLPHONE_NUMBER_FINISHED"

export const REQUEST_RESENT_CHANGE_PARTICIPANT_CELLPHONE_VALIDATION_CODE = "participant.REQUEST_RESENT_CHANGE_PARTICIPANT_CELLPHONE_VALIDATION_CODE"
export const REQUEST_RESENT_CHANGE_PARTICIPANT_CELLPHONE_VALIDATION_CODE_FINISHED = "participant.REQUEST_RESENT_CHANGE_PARTICIPANT_CELLPHONE_VALIDATION_CODE_FINISHED"

export const changeCellphoneNumber = (oldCellphone, newCellphone) => {
  return async (dispatch, getState) => {
    return await await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_CHANGE_CELLPHONE_NUMBER,
      Effects.changeCellphone,
      oldCellphone,
      newCellphone
    )
  }
}

export const confirmChangeCellphoneNumber = (changeOrderId, validationCode) => {
  return async (dispatch, getState) => {
    return await await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_FINISHE_CHANGE_CELLPHONE_NUMBER,
      Effects.finalizeChangeCellphone,
      changeOrderId,
      validationCode
    )
  }
}

export const resendChangeCellphoneValidationNumber = (changeOrderId) => {
  return async (dispatch, getState) => {
    return await await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_RESENT_CHANGE_PARTICIPANT_CELLPHONE_VALIDATION_CODE,
      Effects.resendChangeCellphoneValidationCode,
      changeOrderId
    )
  }
}

export function createParticipant(request) {
  return async (dispatch, getState) => {
    return await await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_CREATE_PARTICIPANT,
      Effects.createParticipantEffect,
      request
    )
  }
}

export function setSort(isDescending) {
  return (dispatch, getState) => {
    dispatch(ActionUtility.createAction(SET_SORT, isDescending))
  }
}

export function setFilter(identifier) {
  return (dispatch, getState) => {
    dispatch(ActionUtility.createAction(SET_FILTER, identifier))
  }
}

export function setStatementsStoreId(storeId) {
  return (dispatch, getState) => {
    dispatch(ActionUtility.createAction(SET_STATEMENTS_STOREID, storeId))
  }
}

export function setStatementsPhone(phone) {
  return (dispatch, getState) => {
    dispatch(ActionUtility.createAction(SET_STATEMENTS_PHONE, phone))
  }
}

export function setPagination(pageNumber) {
  return (dispatch, getState) => {
    dispatch(ActionUtility.createAction(SET_PAGINATION, pageNumber))
  }
}

export function setStatementsPagination(pageNumber) {
  return (dispatch, getState) => {
    dispatch(ActionUtility.createAction(SET_STATEMENTS_PAGINATION, pageNumber))
  }
}

export function getStatements(isPreviousPage = false) {
  return async (dispatch, getState) => {
    if (getState().participant.statements.page === 1 || isPreviousPage) {
      await ActionUtility.createThunkEffect(
        dispatch,
        REQUEST_GET_STATEMENTS,
        Effects.getStatementsEffect,
        getState().participant.statements.currentId,
        getState().participant.statements.page,
        getState().participant.statements.selectedStoreId
      )
    }

    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_GET_STATEMENTS_NEXT_PAGE,
      Effects.getStatementsEffect,
      getState().participant.statements.currentId,
      getState().participant.statements.page + 1,
      getState().participant.statements.selectedStoreId
    )
  }
}

export function searchParticipant() {
  return async (dispatch, getState) => {
    return await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_SEARCH_PARTICIPANT,
      Effects.searchParticipantEffect,
      getState().participant.search.pagination,
      getState().participant.search.filter,
      getState().participant.search.sort
    )
  }
}

export function searchParticipantNotify() {
  return async (dispatch, getState) => {
    return await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_SEARCH_PARTICIPANT_NOTIFY,
      Effects.searchParticipantNotifyEffect,
      "50967133000100",
      getState().participant.search.pagination,
      getState().participant.search.filter,
      getState().participant.search.sort
    )
  }
}

export function markParticipantAsNotified(participantId) {
  return async (dispatch) => {
    return await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_MARK_PARTICIPANT_AS_NOTIFIED,
      Effects.markParticipantAsNotified,
      participantId,
    )
  }
}

export function clearParticipant(id) {
  return (dispatch, getState) => {
    dispatch(ActionUtility.createAction(CLEAR_PARTICIPANT, id))
  }
}

export function clearCurrentParticipant() {
  return (dispatch, getState) => {
    dispatch(ActionUtility.createAction(CLEAR_CURRENT_PARTICIPANT))
  }
}

export function getByCellPhone(cellPhone, storeId) {
  return async (dispatch, getState) => {
    const model = await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_GET_BY_CELL_PHONE,
      Effects.getParticipantByCellPhoneEffect,
      cellPhone,
      storeId
    )
    return model
  }
}
