import { useQuery } from '@tanstack/react-query'
import postApiInstance from '../promo-api'

export const useGetTerm = () =>
  useQuery<{
    hasNewTerm: boolean
    fileUrl: string
    fileContent: string
    version: string
    storeId: string
    storeName: string
  }>({
    queryKey: ['get-tems'],
    queryFn: async () => {
      const response = await postApiInstance.get('/store/term')

      let fileContent =
        'Aconteceu um problema ao buscar o termo, favor tente novamente'

      return { ...response.data, fileContent }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
