import development from './development'
import production from './production'

const getEnvironment = () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') return production

  console.log('dev mode', process.env.REACT_APP_ENVIRONMENT)

  return development
}

export const environment = getEnvironment()
