import { FC, useEffect, useState } from 'react'
import { Space, Spin, Table, TablePaginationConfig, Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { RequestingSelector } from '@aberto/react-common'
import { REQUEST_GET_SUMMARY } from '../../../stores/summary/summary-action'
import { SearchResponse } from '../../../stores/sellers/seller-reducer'
import { useDispatch } from 'react-redux'
import * as SellersActions from '../../../stores/sellers/seller-action'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import RouteEnum from '../../../constants/RouteEnum'
import postApiInstance from '../../../api/promo-api'

const TransactionListTable: FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      SellersActions.getSummary({
        pagination: {
          pageSize: 10,
          pageNumber: 1,
        },
      }) as any
    )
  }, [dispatch])

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'CPF',
      dataIndex: 'document',
      key: 'document',
      render: (cpf: string) => (!cpf ? '-' : cpf),
    },
    {
      title: 'Celular',
      dataIndex: 'cellphone',
      key: 'participantCellphone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return (
          <Space>
            <Link to={RouteEnum.SellerUpdate.replace(':sellerId', id)}>
              <Tooltip title="Editar">
                <EditOutlined />
              </Tooltip>
            </Link>
            <Tooltip title="Desativar">
              <DeleteOutlined
                onClick={async () => {
                  // TODO: Melhorar
                  await postApiInstance.post(`/sellers/disable/${id}`)

                  dispatch(
                    SellersActions.getSummary({
                      pagination: {
                        pageSize: 10,
                        pageNumber: 1,
                      },
                    }) as any
                  )
                }}
              />
            </Tooltip>
          </Space>
        )
      },
    },
  ]

  const data = useSelector(
    (x: any) => x.sellers.searchResponse
  ) as SearchResponse

  const isLoading = useSelector(state =>
    RequestingSelector.selectRequesting(state, [SellersActions.REQUEST_SEARCH])
  )

  const handlePagination = (data: TablePaginationConfig) => {
    dispatch(
      SellersActions.getSummary({
        pagination: {
          pageNumber: data.current!,
          pageSize: data.pageSize!,
        },
      }) as any
    )
  }

  return (
    <Table
      dataSource={data?.data || []}
      columns={columns}
      loading={isLoading}
      onChange={handlePagination}
      pagination={{
        total: data?.numberOfRows || 0,
      }}
    />
  )
}

export default TransactionListTable
