import React, {
  createContext,
  useContext,
  FC,
  useState,
  useEffect,
} from 'react'
import { Amplify, Auth } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import RouteEnum from '../constants/RouteEnum'
import { push } from 'connected-react-router'
import { SignInResponse } from '../@types/auth-response'
import { Redirect, useLocation } from 'react-router-dom'
import PageFallback from '../components/page-fallback/PageFallback'
import { environment } from '../environments'
import { useQuery } from '@tanstack/react-query'
import postApiInstance from '../api/promo-api'
import { useGetTerm } from '../api/queries/useGetTerm'
import Terms from '../views/terms/terms'

interface AuthenticationProviderProps {
  getCurrentUser(): Promise<void>
  signIn(username: string, password: string): Promise<void>
  signOut(): Promise<void>
  confirmPassword(password: string): Promise<void>
  forgetPassword(
    username: string
  ): Promise<{ success: Boolean; reason: string }>
  finalizeForgetPassword(
    username: string,
    code: string,
    password: string
  ): Promise<{ success: Boolean; reason: string }>
  user: SignInResponse
  userIsLoading: boolean
  signInIsLoading: boolean
  signOutIsLoading: boolean
  confirmPasswordIsLoading: boolean
  signInError: boolean
  confirmPasswordError: boolean
  confirmPasswordErrorMessage: string
  isLoadingAuthUser: boolean
  forgetPasswordIsLoading: boolean
  temporaryUser: SignInResponse
}

const FIRST_ACCESS_LABEL = 'NEW_PASSWORD_REQUIRED'

const awsmobile = {
  aws_project_region: environment.authentication.aws_project_region,
  aws_cognito_identity_pool_id:
    environment.authentication.aws_cognito_identity_pool_id,
  aws_cognito_region: environment.authentication.aws_cognito_region,
  aws_user_pools_id: environment.authentication.aws_user_pools_id,
  aws_user_pools_web_client_id:
    environment.authentication.aws_user_pools_web_client_id,
}

Amplify.configure(awsmobile)

const AuthContext = createContext<AuthenticationProviderProps>({} as any)

const AuthenticationProvider: FC<any> = ({ children }): any => {
  const [user, setUser] = useState<any>(null)
  const [temporaryUser, setTemporaryUser] = useState<any>(null)
  const [userIsLoading, setUserIsLoading] = useState(true)
  const [signInError, setSignInError] = useState(false)
  const [confirmPasswordError, setConfirmPasswordError] = useState(false)
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState('')
  const [signInIsLoading, setSignInIsLoading] = useState(false)
  const [signOutIsLoading, setSignOutIsLoading] = useState(false)
  const [forgetPasswordIsLoading, setForgetPasswordIsLoading] = useState(false)
  const [confirmPasswordIsLoading, setConfirmPasswordIsLoading] =
    useState(false)
  const [isLoadingAuthUser, setIsLoadingAuthUser] = useState<boolean>(true)

  const dispatch = useDispatch()

  const getCurrentUser = async () => {
    setUserIsLoading(true)
    const authenticatedUser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    }).catch(console.warn)
    setUser(authenticatedUser)
    setUserIsLoading(false)
  }

  const signIn = async (username: string, password: string) => {
    try {
      setSignInIsLoading(true)
      setSignInError(false)

      const authResponse = await Auth.signIn({ username, password })

      if (authResponse.challengeName !== FIRST_ACCESS_LABEL) {
        setUser(authResponse)
        location.reload()
        return
      }

      setTemporaryUser(authResponse)
      dispatch(push(RouteEnum.ConfirmPassword))
    } catch (error) {
      setSignInError(true)
    } finally {
      setSignInIsLoading(false)
    }
  }

  const signOut = async () => {
    setUser(null)
    localStorage.clear()
    await Auth.signOut()
    dispatch(push(RouteEnum.Login))
    location.reload()
  }

  const confirmPassword = async (password: string) => {
    try {
      setConfirmPasswordIsLoading(true)
      setConfirmPasswordError(false)
      setConfirmPasswordErrorMessage('')

      const user = await Auth.completeNewPassword(temporaryUser, password)

      const result = await Auth.updateUserAttributes(user, {
        email_verified: true,
      }).catch(console.log)

      console.log(result)

      setUser(user)
    } catch (e) {
      const error = e as Error

      const isErrorFromWeekPassword =
        error.message.includes(
          'Password does not conform to policy: Password must satisfy regular expression pattern'
        ) ||
        error.message.includes(
          'Password does not conform to policy: Password not long enough'
        )

      if (isErrorFromWeekPassword)
        setConfirmPasswordErrorMessage(
          'A senha deve ter no mínimo 6 caracteres, incluindo número, letras e carácter especial'
        )
      else
        setConfirmPasswordErrorMessage(
          `Aconteceu um erro inesperado, tente novamente em alguns minutos. \nCódigo de erro: ${error.message}`
        )

      setConfirmPasswordError(true)
    } finally {
      setConfirmPasswordIsLoading(false)
    }
  }

  const refreshUser = async () => {
    setIsLoadingAuthUser(true)

    const authUser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    }).catch(() => {
      setUser(null)
    })
    setIsLoadingAuthUser(false)

    if (authUser === undefined) return

    const isFirstAccess = authUser.challengeName === FIRST_ACCESS_LABEL

    if (isFirstAccess) return

    setUser(authUser)
  }

  const forgetPassword = async (username: string) => {
    try {
      setForgetPasswordIsLoading(true)

      await Auth.forgotPassword(username)

      return { success: true, reason: '' }
    } catch (error: any) {
      return {
        success: false,
        reason:
          error.message ===
          'Attempt limit exceeded, please try after some time.'
            ? 'Tentativas de troca de senha excedeu o limite, tente novamente mais tarde.'
            : 'Aconteceu um erro ao tentar enviar um código, tente novamente mais tarde.',
      }
    } finally {
      setForgetPasswordIsLoading(false)
    }
  }

  const finalizeForgetPassword = async (
    username: string,
    code: string,
    password: string
  ) => {
    try {
      setForgetPasswordIsLoading(true)

      await Auth.forgotPasswordSubmit(username, code, password)

      return { success: true, reason: '' }
    } catch (error: any) {
      return {
        success: false,
        reason: 'Senha não é forte o suficiente e/ou código inválido',
      }
    } finally {
      setForgetPasswordIsLoading(false)
    }
  }

  useEffect(() => {
    refreshUser()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        getCurrentUser,
        signIn,
        signOut,
        confirmPassword,
        user,
        userIsLoading,
        signInIsLoading,
        signOutIsLoading,
        confirmPasswordIsLoading,
        signInError,
        confirmPasswordError,
        confirmPasswordErrorMessage,
        temporaryUser,
        isLoadingAuthUser,
        forgetPassword,
        forgetPasswordIsLoading,
        finalizeForgetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useCognitoContext = () => {
  const context = useContext(AuthContext)

  return context
}

const useProfile = () => {
  const [profile, setProfile] = useState('')
  const _location = useLocation()

  useEffect(() => {
    const runAsync = async () => {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      }).catch(console.log)

      setProfile(user?.attributes?.profile || '')
    }

    runAsync()
  }, [_location.pathname])

  return profile
}

const withProfileAllowed = (Component: React.FC): React.FC<any> => {
  return () => {
    const profile = useProfile()

    if (!profile) return <PageFallback />

    if (profile === 'Manager' || profile === 'Owner') return <Component />

    return <Redirect to={RouteEnum.Home} />
  }
}

const withManagerProfileAllowed = (Component: React.FC): React.FC<any> => {
  return () => {
    const profile = useProfile()

    if (!profile) return <PageFallback />

    if (profile === 'Manager') return <Component />

    return <Redirect to={RouteEnum.Home} />
  }
}

const withoutAuthentication = (Component: React.FC): React.FC<any> => {
  return () => {
    const { isLoadingAuthUser, user, signInError } = useCognitoContext()
    const userId = user?.attributes?.sub as string

    if (isLoadingAuthUser) return <PageFallback />

    if (signInError || !user) return <Component />

    return <Redirect to={RouteEnum.Home} />
  }
}

const withOnlyAuthenticated = (Component: React.FC): React.FC<any> => {
  return () => {
    const { isLoadingAuthUser, user, signInError } = useCognitoContext()
    const { data, isLoading, isError } = useGetTerm()
    const userId = user?.attributes?.sub as string

    if (isLoadingAuthUser) return <></>

    if (signInError || !user) return <></>

    if (isLoading) return <></>

    if (data?.hasNewTerm) return <Redirect to={RouteEnum.Home} />

    if (!isLoadingAuthUser && !signInError && user && !isLoading && !isError)
      return <Component />

    return <></>
  }
}

const withAuthentication = (Component: React.FC): React.FC<any> => {
  return () => {
    const { isLoadingAuthUser, user, signInError } = useCognitoContext()
    const { data, isLoading, isError } = useGetTerm()
    const userId = user?.attributes?.sub as string

    if (isLoadingAuthUser) return <PageFallback />

    if (signInError || !user) {
      Auth.signOut()
      return <Redirect to={RouteEnum.Login} />
    }

    if (isLoading) return <></>

    if (data?.hasNewTerm && user?.attributes?.profile === 'Manager')
      return <Terms isViewingOnly={false} />

    if (!isLoadingAuthUser && !signInError && user && !isLoading && !isError)
      return <Component />

    return <PageFallback />
  }
}

export {
  useCognitoContext,
  withAuthentication,
  withoutAuthentication,
  withOnlyAuthenticated,
  useProfile,
  withProfileAllowed,
  withManagerProfileAllowed,
}
export default AuthenticationProvider
