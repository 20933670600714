import { ActionUtility } from '@aberto/react-common'
import { Dispatch } from 'redux'
import { RootReducerType } from '../RootReducerType'
import * as Effects from './summary-effect'

const REQUEST_GET_SUMMARY = 'Summary.REQUEST_GET_SUMMARY'
const REQUEST_GET_SUMMARY_FINISHED = 'Summary.REQUEST_GET_SUMMARY_FINISHED'

const REQUEST_DOWNLOAD_SUMMARY_CSV = 'Summary.REQUEST_DOWNLOAD_SUMMARY_CSV'
const REQUEST_DOWNLOAD_SUMMARY_CSV_FINISHED =
  'Summary.REQUEST_DOWNLOAD_SUMMARY_CSV_FINISHED'

export type BaseFilter = {
  participantId: string
  initialRangeDate: string
  finalRangeDate: string
}

export interface OrderSearchType {
  filter: BaseFilter
  pagination: {
    pageSize: number
    pageNumber: number
  }
}

const getSummary = (filter: OrderSearchType) => {
  return async (dispatch: Dispatch, stateFn: () => RootReducerType) => {
    return await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_GET_SUMMARY,
      Effects.getSummary,
      filter
    )
  }
}

const downloadCSV = (filter: BaseFilter) => {
  return async (dispatch: Dispatch, stateFn: () => RootReducerType) => {
    return await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_DOWNLOAD_SUMMARY_CSV,
      Effects.downloadCSV,
      filter
    )
  }
}

export {
  getSummary,
  downloadCSV,
  REQUEST_GET_SUMMARY_FINISHED,
  REQUEST_GET_SUMMARY,
  REQUEST_DOWNLOAD_SUMMARY_CSV,
  REQUEST_DOWNLOAD_SUMMARY_CSV_FINISHED,
}
