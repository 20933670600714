import styled from 'styled-components'
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { Col, Descriptions, Input, Radio, Row } from 'antd'
import { CardSwitchVisualization } from '../participant/participant.styles'
import {
  ContainerButtonForm,
  PrimaryButton,
  SecondaryButton,
} from '../../global.styles'

const IconContainer = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 50%;
  color: ${({ theme }) => theme.alert};
`

export const ContainerSwitchVisualization = styled.div``

export const ContainerButtonSwitchVisualization = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 600px) {
    display: none;
  }
`

export const SaleCard = styled<any>(CardSwitchVisualization)`
  width: ${props => (props.showlistactive ? '320px' : 'auto')} !important;
  margin-left: 25px;
`

export const CardCheck = styled.div<any>`
  position: absolute;
  top: 50%;
  left: ${props => (props.showlistactive ? '-20px' : '10px')};
  transform: translateY(-50%);
  width: ${props => (props.showlistactive ? '47px' : '37px')};
  height: ${props => (props.showlistactive ? '47px' : '37px')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.green};
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
`

export const CardUncheck = styled(CardCheck)`
  border: 1px solid ${({ theme }) => theme.warring} !important;
`

export const CheckIcon = styled<any>(CheckCircleOutlined)`
  color: ${({ theme }) => theme.green};
  font-size: ${props => (props.showlistactive ? '25px' : '20px')};
`

export const CloseIcon = styled<any>(ExclamationCircleOutlined)`
  color: ${({ theme }) => theme.warring};
  font-size: ${props => (props.showlistactive ? '25px' : '20px')};
`

export const SaleCardContent = styled.div<any>`
  width: 100%;
  padding: ${props => (props.showlistactive ? '10px 35px 10px' : '10px 60px')};

  @media (max-width: 600px) {
    padding: ${props =>
      props.showlistactive ? '10px 35px 10px' : '10px 10px 10px 60px'};
  }
`

export const SumaryContent = styled.div`
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  width: 100%;

  p {
    color: ${({ theme }) => theme['dark-blue']};
    font: normal normal normal 13px/20px Poppins !important;
  }
`

export const SumaryIcon = styled(IconContainer)`
  color: ${({ theme }) => theme['dark-blue']};
  border: 1px solid ${({ theme }) => theme['dark-blue']} !important;
`

export const DateLabelFlex = styled.div`
  display: flex;
  justify-content: space-between !important;
  width: 100%;
`

export const InputFormBalance = styled(Input)`
  border-radius: 13px;
  width: 100%;
  height: 30px;
  font-size: 0.9rem;
  margin: 5px 0 !important;
  text-align: left;
  padding-left: 15px;

  &::placeholder {
    font-size: 13px !important;
    font-family: 'Poppins', sans-serif;
  }

  &:disabled {
    border: 1px solid #27f3b5;
    background: #27f3b51a !important;
    color: #27f3b5;
  }
`

export const DescriptionsCustom = styled(Descriptions)`
  border: 1px solid #ebebeb !important;
  margin: 20px 0;

  .ant-descriptions-item-label {
    background-color: #f9f9fa !important;
  }

  .ant-descriptions-item-content {
    background-color: #ffffff !important;
  }

  .ant-descriptions-item-label {
    padding: 0 10px !important;
  }
`

export const CheckboxContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`

export const RadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  gap: 30px;

  .ant-radio-wrapper {
    color: ${({ theme }) => theme['dark-blue']} !important;
    font-size: 1rem !important;
    font-weight: normal !important;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${({ theme }) => theme['dark-blue']} !important;
  }

  .ant-radio-inner::after {
    background-color: ${({ theme }) => theme['dark-blue']} !important;
  }

  .ant-radio-inner {
    background-color: ${({ theme }) => theme.white} !important;
  }
`

export const NewOrderButtonContainer = styled.div<{ alignCenter?: boolean }>`
  display: flex;
  align-items: flex-end;
  //justify-content: flex-end;

  ${({ alignCenter }) => `
    align-items: center;
    justify-content: center;
  `}

  margin-bottom: 5px;

  @media (max-width: 790px) {
    margin: 0px auto !important;
  }
`

export const NewOrderSecondaryButton = styled(SecondaryButton)`
  width: 100% !important;

  @media (max-width: 790px) {
    //margin-bottom: 15px !important;
    width: 60% !important;
  }
`

export const NewOrderSecondaryButtonExtra = styled(SecondaryButton)`
  width: 100% !important;
`

export const NewOrderPrimaryButtonExtra = styled(PrimaryButton)`
  width: 100% !important;
`

export const AlertDrawer = styled.div`
  color: ${({ theme }) => theme.alert};
  background: ${({ theme }) => theme['light-gray']};
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border-radius: 10px;
  padding: 1rem 1.5rem 0.5rem;
  margin-top: 20px;
  display: grid;
  place-items: center;

  p {
    font-weight: bold;
    font-size: 0.85rem;
    text-align: center;
  }
`

export const SearchContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`

export const ColNewOrder = styled(Col)`
  align-items: flex-end !important;
  text-align: center !important;
  justify-content: center !important;
  align-items: center !important;
  float: center !important;
`

export const NotFoundIconContainer = styled(IconContainer)`
  border: 1px solid ${({ theme }) => theme.alert};
`

const SummaryContainer = styled(Row)`
  padding: 0 30px;
  justify-content: center;
  align-items: center;

  p {
    font: normal 1rem Poppins !important;
    display: block;
    margin-bottom: 0 !important;
  }

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`

export const ParticipantNotFound = styled(SummaryContainer)`
  p {
    color: ${({ theme }) => theme.alert};
  }
`

export const OrderSummaryContainer = styled(SummaryContainer)`
  p {
    color: ${({ theme }) => theme['dark-blue']};
  }
`
export const OrderSummaryContainerIcon = styled(IconContainer)`
  border: 1px solid ${({ theme }) => theme['dark-blue']};
  color: ${({ theme }) => theme['dark-blue']};
`
