import postApiInstance from '../../api/promo-api'
import { environment } from '../../environments';

const endpoint = environment.promoApi.endpoints.participants;

export const useGetParticipantNotifyList = () => {
  const getParticipantNotifyList = ({ search, page, storeId }: any) => postApiInstance.get(endpoint.getParticipantsNotify, {
      params: {
        page,
        limit: 50,
        storeId,
        search
      }
    }
  );
  
  const markParticipantAsNotified = (participantId: string) => postApiInstance
    .put(endpoint.markParticipantAsNotified.replace(':participantId', participantId));

  return { getParticipantNotifyList, markParticipantAsNotified };
}