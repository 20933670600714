import React from 'react';
import postApiInstance from '../../api/promo-api';
import { environment } from '../../environments';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

export type SellersItem = {
  address: {
    city: string;
    district: string;
    number: string;
    state: string;
    street: string;
    zipCode: string;
  };
  location: {
    longitude: number;
    latitude: number;
  };
  name: string;
};

export type Sellers = {
  saleName: string;
  chainImage: string;
  stores: SellersItem[];
};

export const useSellersAvailable = () => {
  const [sellersAvailable, setSellersAvailable] = React.useState<Sellers>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [hasError, setError] = React.useState<boolean>(false);
  const { search } = useLocation();

  const getSellersAvailable = (saleId: string, tenantId: string) =>
    axios.get<Sellers>(
      `https://api-hml.aberto.com.br/self-promo-b2c/sales-hub/${saleId}/eligible-stores?tenantId=${tenantId}`,
      {
        headers: {
          'Ocp-Apim-Subscription-Key': '0191041c191d4415acdddbfd6d572eaa',
        },
      }
    );

  React.useEffect(() => {
    const searchParams = Object.fromEntries(
      new URLSearchParams(search).entries()
    );

    const load = () => {
      if (searchParams.saleId && searchParams.tenantId) {
        getSellersAvailable(searchParams.saleId, searchParams.tenantId)
          .then(({ data }) => {
            if (data.saleName) {
              setSellersAvailable(data);
            } else {
              setError(true);
            }
          })
          .catch(() => setError(true))
          .finally(() => setIsLoading(false));
      } else {
      }
    };
    load();
  }, []);

  return { sellersAvailable, isLoading, hasError };
};
