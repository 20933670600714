import { environment } from '../../environments'
import promoApi from '../../api/promo-api'
import { EffectUtility } from '@aberto/react-common'
import QueryString from 'qs'
import { OrderSearchType, BaseFilter } from './summary-action'

const endpoint = environment.promoApi.endpoints.summary

const getSummary = async (requestParams: OrderSearchType) => {
  const queryStringParams = QueryString.stringify(requestParams, {
    allowDots: true,
    skipNulls: true,
  })

  return await EffectUtility.get(
    promoApi,
    `${endpoint.getSummary}?${queryStringParams}`
  )
}

const downloadCSV = async (requestParams: BaseFilter) => {
  const queryStringParams = QueryString.stringify(requestParams, {
    allowDots: true,
    skipNulls: true,
  })

  return await promoApi.get(`${endpoint.report}?${queryStringParams}`, {
    responseType: 'blob',
  })
}

export { getSummary, downloadCSV }
