import styled from 'styled-components'
import {
  InputMaskForm,
  PrimaryButton,
  SecondaryButton,
} from '../../global.styles'

export const PinCodeVerificationContainer = styled.span`
  max-width: 100%;
`
export const PinContainer = styled.span`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: 300;
  text-align: center;
  margin: auto;
  width: 100%;
`

export const PinSubtitle = styled.div`
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #001d42;

  span {
    font-weight: bold;
  }
`

export const PinSpan = styled.div`
  font-size: 12px;
  color: #707070;
  margin: 10px auto;
  text-align: center;
  //display: flex;

  span {
    font-weight: bold;
  }

  a {
    color: #001d42;
    font-weight: bold;
  }
`

export const PinNoPhone = styled.div`
  font-size: 12px;
  max-width: 350px;
  color: #707070;
  margin: 10px auto;
  text-align: center;

  span {
    font-weight: bold;
  }

  a {
    color: #001d42;
    font-weight: bold;
  }
`

export const PinButton = styled(PrimaryButton)`
  background: #001d42 !important;
  border: 2px solid #001d42 !important;
  color: #ffffff !important;

  width: 330px;

  span {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 100 !important;
  }
`

export const PinCode = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;

  input {
    display: grid;
    place-items: center;
    text-align: center;
    font-size: 21px;
    color: #001d42;
    height: ${props => (props.reducesize ? '45px' : '60px')};
    width: ${props => (props.reducesize ? '45px' : '66px')};
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    border: none;

    @media (max-width: 700px) {
      height: 40px;
      width: 46px;
      margin: 5px;
    }
  }
`
export const InputMaskFormCode = styled(InputMaskForm)`
  border: 1px solid ${props => (props.showError ? 'red' : 'transparent')} !important;
`

export const PinCodeInput = styled(InputMaskForm)`
  border: 1px solid ${props => (props.showError ? 'red' : 'transparent')} !important;
  width: 20px;
  height: 20px;
`

export const SecondaryButtonPin = styled(SecondaryButton)`
  height: 30px !important;
  min-width: 9.5rem !important;
`
export const TextCell = styled.div`
  place-items: center;
  color: #00000029;
  text-align: center;
`

export const TextCod = styled.div`
  place-items: center;
  color: #001d42;
  text-align: center;
  a {
    color: #001d42 !important;
  }
`
