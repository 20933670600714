import { FC, useEffect } from 'react'
import { Form, Row } from 'antd'
import {
  BackButton,
  BackButtonText,
  PrimaryButton,
  SecondaryButton,
} from '../../../global.styles'
import * as Actions from '../../../stores/order/order-action'
import {
  ColNewOrder,
  NewOrderButtonContainer,
  NewOrderSecondaryButton,
} from '../order.styles'
import { useSelector, useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import {
  BackButtonGroup,
  CustomGroupTitle,
  Wrap,
  CustomGroupTitleLevel3,
} from './steps-style'
import { goBack, push } from 'connected-react-router'
import RouteEnum from '../../../constants/RouteEnum'
import { OrderSummary } from '../components/order-summary'
import {
  bonusOverview,
  checkBenefits,
  hasFoundBenefits,
} from '../../../selectors/order/order-selector'
import { activeBonus } from '../../../selectors/participant/participant-selector'
import BenefitOverviewStepItem from './benefit-overview-step-item'

const BenefitSummaryStep: FC = () => {
  const dispatch = useDispatch()

  const orderBonusOverview = useSelector(bonusOverview)
  const currentActiveBonus = useSelector(activeBonus)

  useEffect(() => {
    if (!currentActiveBonus?.activeBonus?.length)
      dispatch(push(RouteEnum.CheckValueOrderStep))
  }, [dispatch])

  const handleDoNotUseBenefit = () =>
    dispatch(push(RouteEnum.BenefitOverviewOrderStep))

  const handleGoToPinVerification = async () => {
    await dispatch(Actions.sendOrder() as any)
    dispatch(push(RouteEnum.UserPinOrderStep))
  }

  return (
    <>
      <Wrap>
        <CustomGroupTitleLevel3>
          Valor da compra do cliente sem descontos: R${' '}
          {orderBonusOverview.purchaseValue}
        </CustomGroupTitleLevel3>

        <CustomGroupTitleLevel3>
          O cliente tem um bônus de: R$ {orderBonusOverview.bonusUsed}
        </CustomGroupTitleLevel3>

        <hr />

        <CustomGroupTitle style={{ marginTop: '10px' }}>
          Cobrar do cliente: R$ {orderBonusOverview.paymentFinalValue}
        </CustomGroupTitle>

        <br />
        <br />
        <br />

        <BenefitOverviewStepItem />

        {/* <Row style={{ marginTop: '50px' }}>
          <div className="ant-col ant-col-24 ant-col-md-10">
            <NewOrderButtonContainer>
              <SecondaryButton onClick={handleDoNotUseBenefit}>
                Cancelar e não utilizar o bônus
              </SecondaryButton>
            </NewOrderButtonContainer>
          </div>
          <div className="ant-col ant-col-24 ant-col-md-offset-4 ant-col-md-10">
            <NewOrderButtonContainer>
              <PrimaryButton
                loading={false}
                onClick={handleGoToPinVerification}
              >
                Continuar e utilizar o bônus
              </PrimaryButton>
            </NewOrderButtonContainer>
          </div>
        </Row> */}
      </Wrap>
    </>
  )
}

export default BenefitSummaryStep
