import { Col, Form, Row } from 'antd'
import { push } from 'connected-react-router'
import QueryString from 'qs'
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import RouteEnum from '../../../constants/RouteEnum'
import { useCognitoContext } from '../../../context/authentication-provider'
import {
  ContainerButton,
  InputForm,
  PasswordInputForm,
  PrimaryButton,
  SecondaryButton,
} from '../../../global.styles'
import { ColNewOrder, NewOrderButtonContainer } from '../../order/order.styles'
import { CardVerification } from '../store-authentication.styles'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'

const ForgotPasswordInsertNewPassword: FC = () => {
  const filtersFromQueryString = QueryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })

  const authContext = useCognitoContext()

  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [
    sendForgetPasswordCodeErrorMessage,
    setSendForgetPasswordCodeErrorMessage,
  ] = useState('')

  const handleFormFinish = async (data: any) => {
    setSendForgetPasswordCodeErrorMessage('')
    const { code, password, confirmPassword } = data

    if (password !== confirmPassword) {
      setSendForgetPasswordCodeErrorMessage('As senhas digitadas não conferem')
      return
    }

    const { success, reason } = await authContext.finalizeForgetPassword(
      filtersFromQueryString.email as string,
      code,
      password
    )

    setSendForgetPasswordCodeErrorMessage(reason)

    if (success) dispatch(push(RouteEnum.Login))
  }

  const goBackToLogin = () => {
    dispatch(push(RouteEnum.Login))
  }

  useEffect(() => {
    if (!filtersFromQueryString.email) dispatch(push(RouteEnum.ForgotPassword))
  }, [filtersFromQueryString])

  return (
    <CardVerification>
      <h3>Esqueci minha senha</h3>

      <small style={{ color: 'tomato' }}>
        {sendForgetPasswordCodeErrorMessage}
      </small>

      <Form
        id="forgot-password"
        form={form}
        layout="vertical"
        onFinish={handleFormFinish}
        requiredMark={true}
      >
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              hasFeedback={false}
              id="code"
              name="code"
              label="Código de confirmação"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputForm placeholder="123456" autoFocus={true} allowClear />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              hasFeedback={false}
              id="password"
              name="password"
              label="Digite a nova senha"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <PasswordInputForm
                iconRender={visible =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                placeholder="**********"
                autoFocus={true}
                allowClear
                type="password"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              hasFeedback={false}
              id="confirmPassword"
              name="confirmPassword"
              label="Digite a senha novamente"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <PasswordInputForm
                iconRender={visible =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                placeholder="**********"
                autoFocus={true}
                allowClear
                type="password"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <ColNewOrder md={12} sm={24}>
            <NewOrderButtonContainer>
              <SecondaryButton onClick={goBackToLogin}>
                Cancelar
              </SecondaryButton>
            </NewOrderButtonContainer>
          </ColNewOrder>

          <ColNewOrder md={12} sm={24}>
            <NewOrderButtonContainer>
              <PrimaryButton
                onClick={form.submit}
                loading={authContext.forgetPasswordIsLoading}
              >
                Finalizar
              </PrimaryButton>
            </NewOrderButtonContainer>
          </ColNewOrder>
        </Row>
      </Form>
    </CardVerification>
  )
}

export default ForgotPasswordInsertNewPassword
