import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
import { FC, ReactNode, createContext, useContext, useState } from "react";
import { ChangeParticipantCellphoneSteps } from "../components/change-participant-cellphone-modal";
import { Participant } from "../../../@types/participant";
import { useDispatch } from "react-redux";
import * as ParticipantActions from "../../../stores/participant/participant-action";
import { ApiException, isApiException } from "../../../api/promo-api";

// TODO: padronizar?
interface RequestError {
    error: ApiException,
    success: string
}

interface ParticiantContexProps {
    isParticipantModalOpened: boolean
    changePasswordModalStep: ChangeParticipantCellphoneSteps
    newCellphone: string
    isChangeCellphoneModalOpened: boolean
    selectedParticipant: Participant
    openParticipantModal: (participant: Participant) => void
    closeParticipantModal: () => void
    goToOtpOnChangePasswordModal: () => void
    setNewCellphone: (cellphone: string) => void
    openChangeCellphoneModal: () => void
    closeChangeCellphoneModal: () => void
    changeCellphoneOrderResponse: RequestError
    finalizeChangeCellphoneOrderResponse: RequestError
    finalizeChangeCellphone: (verificationCode: string) => Promise<void>,
    resendVerificationCode: () => Promise<void>
}

const ParticipantContext = createContext<ParticiantContexProps>({} as ParticiantContexProps)

export const useParticipantContext = () => useContext(ParticipantContext)

export const ParticipantProvider: FC<{ children: ReactNode | ReactNode[] }> = ({ children }) => {
    const dispatch = useDispatch()

    const [isParticipantModalOpened, setIsParticipantModalOpened] = useState(false)
    const [isChangeCellphoneModalOpened, setIsChangeCellphoneModalOpened] = useState(false)
    const [selectedParticipant, setSelectedParticipant] = useState<Participant>({} as any)
    const [changePasswordModalStep, setChangePasswordModalStep] = useState(ChangeParticipantCellphoneSteps.ValidationStep)
    const [newCellphone, setNewCellphone] = useState('')
    const [changeCellphoneOrderResponse, setChangeCellphoneOrderResponse] = useState<RequestError>({ error: {} as ApiException, success: '' })
    const [finalizeChangeCellphoneOrderResponse, setFinalizeChangeCellphoneOrderResponse] = useState<RequestError>({ error: {} as ApiException, success: '' })

    const openParticipantModal = (participant: any) => {
        setSelectedParticipant(participant)
        setIsParticipantModalOpened(true)
    }

    const closeParticipantModal = () => {
        setIsParticipantModalOpened(false)
    }

    const openChangeCellphoneModal = () => {
        setIsChangeCellphoneModalOpened(true)
        setChangePasswordModalStep(ChangeParticipantCellphoneSteps.ValidationStep)
    }

    const closeChangeCellphoneModal = () => {
        setIsChangeCellphoneModalOpened(false)
        closeParticipantModal()
        setChangePasswordModalStep(ChangeParticipantCellphoneSteps.ValidationStep)
        setNewCellphone('')
    }

    const goToOtpOnChangePasswordModal = async () => {
        setChangeCellphoneOrderResponse({ error: {} as ApiException, success: '' })

        const response = await dispatch(ParticipantActions.changeCellphoneNumber(selectedParticipant.cellPhone, newCellphone) as any)

        if (isApiException(response)) {
            setChangeCellphoneOrderResponse({ error: response, success: "" })
            return
        }


        setChangeCellphoneOrderResponse({ error: {} as ApiException, success: response.data.id })

        if (response.data.shouldUseOtpStage)
            setChangePasswordModalStep(ChangeParticipantCellphoneSteps.OtpStep)
        else
            setChangePasswordModalStep(ChangeParticipantCellphoneSteps.SuccessAlert)
    }

    const finalizeChangeCellphone = async (verificationCode: string) => {
        const response = await dispatch(ParticipantActions.confirmChangeCellphoneNumber(changeCellphoneOrderResponse.success, verificationCode) as any)

        if (!isApiException(response)) {
            setFinalizeChangeCellphoneOrderResponse({ error: {} as ApiException, success: response })

            setChangePasswordModalStep(ChangeParticipantCellphoneSteps.SuccessAlert)

            dispatch(ParticipantActions.searchParticipant() as any)
            return
        }

        setFinalizeChangeCellphoneOrderResponse({ error: response, success: "" })
    }

    const resendVerificationCode = async () => {
        await dispatch(ParticipantActions.resendChangeCellphoneValidationNumber(changeCellphoneOrderResponse.success) as any)
    }

    return <ParticipantContext.Provider value={{
        isParticipantModalOpened,
        changePasswordModalStep,
        newCellphone,
        isChangeCellphoneModalOpened,
        selectedParticipant,
        openParticipantModal,
        closeParticipantModal,
        goToOtpOnChangePasswordModal,
        setNewCellphone,
        openChangeCellphoneModal,
        closeChangeCellphoneModal,
        changeCellphoneOrderResponse,
        finalizeChangeCellphone,
        finalizeChangeCellphoneOrderResponse,
        resendVerificationCode
    }}>
        {children}
    </ParticipantContext.Provider>
}