import { Col, Form, Row } from 'antd'
import { push } from 'connected-react-router'
import QueryString from 'qs'
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import RouteEnum from '../../../constants/RouteEnum'
import { useCognitoContext } from '../../../context/authentication-provider'
import {
  ContainerButton,
  InputForm,
  PrimaryButton,
  SecondaryButton,
} from '../../../global.styles'
import { ColNewOrder, NewOrderButtonContainer } from '../../order/order.styles'
import { CardVerification } from '../store-authentication.styles'

const ForgotPassword: FC = () => {
  const authContext = useCognitoContext()
  const dispatch = useDispatch()
  const [
    sendForgetPasswordCodeErrorMessage,
    setSendForgetPasswordCodeErrorMessage,
  ] = useState('')
  const [form] = Form.useForm()

  const filtersFromQueryString = QueryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })

  const handleContinue = async (data: any) => {
    setSendForgetPasswordCodeErrorMessage('')

    const { email } = data

    const { success, reason } = await authContext.forgetPassword(email)

    setSendForgetPasswordCodeErrorMessage(reason)

    if (success)
      dispatch(
        push(`${RouteEnum.ForgotPasswordInsertNewPassword}?email=${email}`)
      )
  }

  const goBackToLogin = () => dispatch(push(RouteEnum.Login))

  useEffect(() => {
    form.setFieldValue('email', filtersFromQueryString.email)
  }, [filtersFromQueryString])

  return (
    <CardVerification>
      <h3>Esqueci minha senha</h3>

      <small style={{ color: 'tomato' }}>
        {sendForgetPasswordCodeErrorMessage}
      </small>

      <Form
        id="forgot-password"
        form={form}
        layout="vertical"
        onFinish={handleContinue}
        requiredMark={true}
      >
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              hasFeedback={false}
              id="email"
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputForm
                placeholder="exemplo@email.com"
                autoFocus={true}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <ColNewOrder md={12} sm={24}>
            <NewOrderButtonContainer>
              <SecondaryButton onClick={goBackToLogin}>
                Cancelar
              </SecondaryButton>
            </NewOrderButtonContainer>
          </ColNewOrder>

          <ColNewOrder md={12} sm={24}>
            <NewOrderButtonContainer>
              <PrimaryButton
                onClick={form.submit}
                loading={authContext.forgetPasswordIsLoading}
              >
                Continuar
              </PrimaryButton>
            </NewOrderButtonContainer>
          </ColNewOrder>
        </Row>
      </Form>
    </CardVerification>
  )
}

export default ForgotPassword
