export const defaultTheme = {
  'dark-blue': '#001D42',

  white: '#FFF',

  green: '#27f3b5',
  'light-green': '#27F3B51A',

  violet: '#8B5CF6',
  purple: '#2A2634',
  black: '#121214',
  gray: '#A7A7A7',
  'light-gray': '#F3F3F3',

  warring: '#F8C413',
  alert: '#FA5A54',

  background: '#FBFFFD',
  'border-gray': '#D6D6D65C',

  'font-regular': 'Poppins, Regular',
}
