/* eslint-disable prettier/prettier */
import { FC, useContext, useEffect, useState } from 'react'
import { RequestingSelector } from '@aberto/react-common'
import { Form, Row } from 'antd'
import {
  CurrencyInput,
  InputFormCustom,
  PrimaryButton,
} from '../../../global.styles'
import { ColNewOrder, NewOrderButtonContainer } from '../order.styles'
import { useSelector, useDispatch } from 'react-redux'
import { CustomGroupTitle, Wrap, WrapOnlyShadow } from './steps-style'
import { push } from 'connected-react-router'
import RouteEnum from '../../../constants/RouteEnum'
import { OrderStepContext } from './context/order-step-context'
import { ParticipantSummary } from '../components/participant-summary-header'
import {
  REQUEST_CALCULATE_CASHBACK,
  calculateCashback,
} from '../../../stores/order/order-action'
import { isApiException } from '../../../api/promo-api'
import { currentParticipant } from '../../../selectors/participant/participant-selector'

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
}

const OrderValueStep: FC = () => {
  const dispatch = useDispatch()
  const orderStepContext = useContext(OrderStepContext)

  const [form] = Form.useForm()

  const [purchasePrice, setPurchasePrice] = useState(0)

  const participantSummary = useSelector(state => currentParticipant(state))

  const isRequestingCashback = useSelector(state =>
    RequestingSelector.selectRequesting(state, [REQUEST_CALCULATE_CASHBACK])
  )

  const cashBackSimulator = async () => {
    let price = form.getFieldValue('purchasePrice').replace(/\D/g, '')
    price = Number(price / 100)

    orderStepContext.setPurchasePrice(price)

    const useAcumulatedCashback = !!(
      participantSummary?.balance && orderStepContext.shouldUseBurnBalance
    )
    const cellphone = orderStepContext.cellphone
    orderStepContext.setShouldUsePinCodeBurn(false)

    dispatch(
      calculateCashback({
        cellphone,
        purchaseValue: price,
        useAcumulatedCashback: useAcumulatedCashback,
        pinCode: form.getFieldValue('pinCode'),
      }) as any
    ).then((response: any) => {
      if (isApiException(response)) orderStepContext.setApiError()
    })

    dispatch(push(RouteEnum.BenefitOverviewOrderStep))
  }

  useEffect(() => {
    if (!orderStepContext.isCellphoneValid())
      dispatch(push(RouteEnum.CellphoneOrderStep))
  }, [orderStepContext, dispatch])

  return (
    <>
      <ParticipantSummary isOrderValueStep={true} />
      <Form form={form} onFinish={cashBackSimulator}>
        {orderStepContext.shouldUsePinCodeBurn && (
          <WrapOnlyShadow style={{ marginBottom: '5%' }}>
            <CustomGroupTitle>
              Insira o código do Cupom de Desconto
            </CustomGroupTitle>
            <Row gutter={20}>
              <ColNewOrder md={{ span: 10, offset: 8 }}>
                <Form.Item
                  id="pinCode"
                  name="pinCode"
                  rules={[
                    {
                      required: true,
                      message: 'O código do cupom deve ser informado',
                    },
                  ]}
                >
                  <InputFormCustom
                    autoFocus={true}
                    placeholder="Código do Cupom de Desconto"
                  />
                </Form.Item>
              </ColNewOrder>
            </Row>
          </WrapOnlyShadow>
        )}
        <WrapOnlyShadow>
          <CustomGroupTitle>
            Insira o valor total da compra do cliente sem descontos
          </CustomGroupTitle>

          <Row gutter={20}>
            <ColNewOrder md={{ span: 10, offset: 8 }}>
              <Form.Item
                id="purchasePrice"
                name="purchasePrice"
                rules={[{ required: true }]}
              >
                <CurrencyInput
                  autoFocus={true}
                  currency="BRL"
                  onChange={(b: any, value: any, a: any) =>
                    setPurchasePrice(value)
                  }
                  config={currencyConfig}
                />
              </Form.Item>
            </ColNewOrder>
          </Row>
          <Row>
            <ColNewOrder md={{ span: 10, offset: 8 }}>
              <NewOrderButtonContainer alignCenter>
                <PrimaryButton
                  loading={isRequestingCashback}
                  onClick={form.submit}
                  disabled={purchasePrice <= 0}
                >
                  Calcular bônus do cliente
                </PrimaryButton>
              </NewOrderButtonContainer>
            </ColNewOrder>
          </Row>
        </WrapOnlyShadow>
      </Form>
    </>
  )
}

export default OrderValueStep
