import produce from 'immer'
import moment from 'moment'
import { Reducer } from 'react'
import baseReducer from '../../utilities/base-reducer'
import * as Actions from './summary-action'

export interface SummaryType {
  response: Actions.OrderSearchType
}

export enum OrderStatus {
  Generated,
  AppliedWaitingValidation,
  Confirmed,
  Reversed,
}

export const OrderStatusLabelName = {
  [OrderStatus.Generated]: 'Gerado',
  [OrderStatus.AppliedWaitingValidation]: 'Aplicado - Aguardando Validação',
  [OrderStatus.Confirmed]: 'Confirmado',
}

export type SummaryResponse = {
  total: number
  count: number
  items: {
    id: string
    date: string
    participantName: string
    participantCellphone: string
    participantDocument: string
    value: number
    redemptionValue: number
    payedValue: number
    creditValue: number
    status: OrderStatus
    store: string
    sellerName: string
  }[]
}

const initialState = {
  response: {},
} as SummaryType

export const summaryReducer = baseReducer(initialState, {
  [Actions.REQUEST_GET_SUMMARY_FINISHED](state: any, { payload }: any) {
    return produce(state, (draftState: SummaryType) => {
      draftState.response = payload
    })
  },
  [Actions.REQUEST_DOWNLOAD_SUMMARY_CSV_FINISHED](
    state: any,
    { payload }: any
  ) {
    return produce(state, (draftState: SummaryType) => {
      const link = document.createElement('a')
      link.target = '_blank'
      link.download = 'relatorio_prominho.csv'
      link.href = URL.createObjectURL(
        new Blob([payload.data], { type: 'application/octet-stream' })
      )
      link.click()
    })
  },
}) as Reducer<
  SummaryType,
  {
    type: any
    payload: any
  }
>
