import { FC, useEffect, useRef, useState } from 'react'
import {
  useProfile,
  withProfileAllowed,
} from '../../context/authentication-provider'
import { Col, Row } from 'antd'
import Page from '../../components/layout/page'
import { DefaultListContent } from '../../components/styled-components/default-list-content'
import { GroupTitle } from '@aberto/react-common'
import { ParticipantTransactionListTable } from './components/participant-transaction-list-table'
import { useDispatch, useSelector } from 'react-redux'
import * as ParticipantActions from '../../stores/participant/participant-action'
import { useParams } from 'react-router-dom'
import { ParticipantSummary } from '../order/components/participant-summary-header'
import { SelectCustom } from '../../components/styled-components/global.styled'
import { getIdentificationInfo } from '../../stores/store/store-actions'
import { convertIdentificationInfoToSelectOptions } from '../../helpers/select-helper'

export const ParticipantTransactionListComponent: FC = () => {
  const dispatch = useDispatch()

  const profile = useProfile()

  const storeIdentificationInfo = useSelector(
    (state: any) => state.store.identificationInfo
  )

  const selectedStoreId = useSelector(
    (state: any) => state.participant.statements.selectedStoreId
  )

  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    dispatch(ParticipantActions.setStatementsPhone(id) as any)
    if (profile !== 'Owner' && profile) {
      dispatch(ParticipantActions.getStatements() as any)
      dispatch(ParticipantActions.getByCellPhone(id) as any)
    } else dispatch(getIdentificationInfo() as any)
  }, [profile])

  const handleSelectOnChange = (storeId: any) => {
    dispatch(ParticipantActions.setStatementsPagination(1) as any)
    dispatch(ParticipantActions.setStatementsStoreId(storeId) as any)
    dispatch(ParticipantActions.getStatements() as any)
    dispatch(ParticipantActions.getByCellPhone(id, storeId) as any)
  }

  return (
    <Page>
      <Row>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 8, offset: 8 }}>
          {(profile !== 'Owner' || selectedStoreId) && (
            <ParticipantSummary isOrderValueStep={false} />
          )}
        </Col>
      </Row>
      {profile == 'Owner' && (
        <Row style={{ marginTop: '10px' }}>
          <Col xs={{ span: 20, offset: 2 }} md={{ span: 6, offset: 9 }}>
            <p>Loja: </p>
            <SelectCustom
              onChange={handleSelectOnChange}
              options={convertIdentificationInfoToSelectOptions(
                storeIdentificationInfo
              )}
            />
          </Col>
        </Row>
      )}
      <Row style={{ textAlign: 'center' }}>
        <Col
          md={{ span: 12, offset: 6 }}
          xs={24}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <DefaultListContent>
            <GroupTitle>Extrato</GroupTitle>
            <br />
            <ParticipantTransactionListTable />
          </DefaultListContent>
        </Col>
      </Row>
    </Page>
  )
}

export const ParticipantTransactionList = withProfileAllowed(
  ParticipantTransactionListComponent
)
