import styled from 'styled-components'

export const HeaderContainer = styled.div`
  width: 700px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`
export const HeaderContainerLanding = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`
export const Logo = styled.a`
  display: block;
  width: 160px;

  img {
    width: 100%;
  }
`
export const Icon = styled.div`
  margin-left: 10px;
`
export const UserBox = styled.a`
  color: #ffffff;
  position: relative;
`
export const Dropdown = styled.div`
  position: absolute;
  top: 64px;
  left: 0;
  max-width: 100px;
`
