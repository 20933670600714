import { Checkbox } from 'antd'
import styled from 'styled-components'

const WrapOnlyShadow = styled.div`
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  padding: 15px;
  margin: -15px;
`
//TODO: Ajustar para que espaçamento entre o header do participant (WrapParticipantSummary) e o Wrap fique correto com um não entrando dentro do outro
const Wrap = styled(WrapOnlyShadow)`
  position: relative;
  -webkit-transform: translate(-50%, -50%);
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 60vw;
`

const WrapCenter = styled(Wrap)`
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
  min-width: 60vw;
`

const ParticipantLabels = styled.span`
  && {
    font-weight: 1900;
    padding: 0;
    font-size: 17px;
    width: 0;
  }
`

const ParticipantValues = styled(ParticipantLabels)`
  && {
    font-weight: 100;
    font-size: 16px;
  }
`

const WrapParticipantSummary = styled(WrapOnlyShadow)`
  margin-top: 50px;
  margin-bottom: 50px;
`

const CustomGroupTitle = styled.h2`
  text-align: center !important;
  font-family: Poppins;
  font-weight: 700;
  font-size: 20px;
  width: 100%;
`

const CustomGroupTitleLevel3 = styled.h3`
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  padding-right: 10px;
  width: 100%;
`

const CustomCheckBox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #27f3b5;
    border-color: #27f3b5;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #40a9ff; /* Cor da borda desejada */
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox-input:hover + .ant-checkbox-inner {
    border-color: #40a9ff; /* Cor da borda desejada */
  }
`

const BackButtonGroup = styled.div`
  display: inline-block;
  cursor: pointer;
`

export {
  CustomCheckBox,
  Wrap,
  CustomGroupTitle,
  BackButtonGroup,
  CustomGroupTitleLevel3,
  WrapOnlyShadow,
  WrapParticipantSummary,
  ParticipantLabels,
  ParticipantValues,
  WrapCenter,
}
