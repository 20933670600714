import { Layout, Spin } from 'antd'

const { Content } = Layout

export default function PageFallback() {
  return (
    <Content
      className="site-layout-background"
      style={{
        minHeight: 'calc(100vh - 64px)',
      }}
    >
      <div style={{ paddingTop: '45vh', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    </Content>
  )
}
