import React from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import App from './App'
import rootStore from './stores/rootStore'
import AuthenticationProvider from './context/authentication-provider'

const container = document.getElementById('root')
const root = createRoot(container)
const initialState = {}
const history = createBrowserHistory()
const store = rootStore(initialState, history)

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleCheck, fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas, faCircleCheck)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthenticationProvider>
        <App history={history} dispatch={store.dispatch} />
      </AuthenticationProvider>
    </Provider>
  </React.StrictMode>
)
