import styled from 'styled-components'
import { ContainerGlobal, InputMaskForm } from '../../global.styles'

import tradebackLogo from '../../assets/img/icon-logo.svg'

export const ContainerGlobalLogin = styled(ContainerGlobal)`
  margin: 0 auto;
  max-width: 100%;
  height: 80vh;
  display: grid;
  place-items: center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    opacity: 0.2;
    z-index: -1;
    width: 100%;
    margin: 0 auto;
    background-size: contain;
  }
`

export const CardVerification = styled.div`
  max-width: 350px;
  box-shadow: 0px 0px 20px #00000024;
  background: #ffffff;
  padding: 30px 25px 25px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  opacity: 1 !important;

  h3 {
    font: normal normal 600 18px/27px Poppins;
    letter-spacing: 0px;
    color: ${({ theme }) => theme['dark-blue']};
    margin: 30px 0 10px;
  }
`

export const InputMaskFormLogin = styled(InputMaskForm)`
  box-shadow: 0px 3px 25px #0000001a;
  border: 1px solid #fff !important;
  pading: 20px !important;
  height: 40px !important;
`

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.alert};
  text-align: center;
  padding: 10px 30px;
`
