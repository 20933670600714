import { useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import '../layout.css';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import RouteEnum from '../../../constants/RouteEnum';
import { HeaderContainer } from './headers.styles';
import { defaultTheme } from '../../../@themes/defaultTheme';
import {
  useCognitoContext,
  useProfile,
  withOnlyAuthenticated,
} from '../../../context/authentication-provider';
import { keyPressEventWrapper } from '../../../helpers/key-press-event-wrapper';
import { useLocation } from 'react-router-dom';

const { Header } = Layout;

const HeaderComponent = () => {
  const authContext = useCognitoContext();
  const dispatch = useDispatch();
  const location = useLocation();

  const [userName, setUserName] = useState('Lojinha Tradeback');
  const [openDropdown, setOpenDropdown] = useState(false);
  const [headerLandingPage, setHeaderLandingPage] = useState(false);

  useEffect(() => {
    const location = window.location.href;

    if (location.includes(RouteEnum.LandingPage)) {
      setHeaderLandingPage(true);
    }
  }, [headerLandingPage, location]);

  const profile = useProfile();

  const items2 = [];
  const whitoutMenu = [RouteEnum.SellersAvailable];

  if (profile !== 'Owner')
    items2.push({ key: RouteEnum.Home, label: 'Cashback' });

  //f (profile === 'Manager')
  //  items2.push({ key: RouteEnum.Sales, label: 'Ofertas' });

  if (profile === 'Manager' || profile === 'Owner') {
    items2.push({ key: RouteEnum.TransactionList, label: 'Vendas realizadas' });
    items2.push({
      label: 'Clientes',
      children: [
        {
          key: RouteEnum.ParticipantList,
          label: 'Consultar clientes',
        },
        {
          key: RouteEnum.ParticipantNotifyList,
          label: 'Comunicar clientes',
        },
      ],
    });
  }

  items2.push({ key: RouteEnum.Dashboard, label: 'Dashboard' });
  if (profile === 'Manager')
    items2.push({ key: RouteEnum.SellerList, label: 'Colaboradores' });

  items2.push({ key: RouteEnum.Faq, label: 'FAQ' });
  items2.push({ key: RouteEnum.Training, label: 'Treinamentos' });
  if (profile === 'Manager')
    items2.push({ key: RouteEnum.LoggedTerms, label: 'Termo de aceite' });
  items2.push({ key: 'SAIR', label: 'Sair' });

  const showMenu =
    whitoutMenu.findIndex(route => route === location.pathname) === -1;

  return (
    <div>
      {withOnlyAuthenticated(() => (
        <Header
          style={{
            display: 'flex',
            alignItems: 'center',
            display: showMenu ? 'block' : 'none',
          }}
        >
          <Menu
            mode="horizontal"
            onSelect={({ key }) => {
              if (key === 'SAIR') {
                authContext.signOut();
                return;
              }

              dispatch(push(key));
            }}
            style={{
              backgroundColor: defaultTheme['dark-blue'],
              color: 'white',
              border: 0,
              flex: 1,
              minWidth: 0,
            }}
            items={items2}
          ></Menu>
        </Header>
      ))()}
    </div>
  );
};

HeaderComponent.propTypes = {};

export { HeaderComponent as Header };
