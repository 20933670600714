import React, { FC, useEffect } from 'react'
import moment, { Moment } from 'moment'
import { Col, Collapse, DatePicker, Form, Row } from 'antd'
import styled from 'styled-components'
import { PrimaryButton } from '../../../global.styles'
import { SelectCustom } from '../../../components/styled-components/global.styled'
import { useSelector, useDispatch } from 'react-redux'
import { useProfile } from '../../../context/authentication-provider'
import {
  getIdentificationInfo,
  REQUEST_IDENTIFICATION_INFO,
} from '../../../stores/store/store-actions'
import { RequestingSelector } from '@aberto/react-common'
import { convertIdentificationInfoToSelectOptions } from '../../../helpers/select-helper'
import { maskedDate } from '../../../helpers/date-mask-helper'

export interface Filter {
  initialDateRange: moment.Moment
  finalDateRange: moment.Moment
  storeId?: string
}

const RangePicker = styled(DatePicker.RangePicker)`
  border-radius: 500px;
  width: 100%;
  border: 1px solid #001d42;
  margin-top: 4px;
`

export interface DashboardFilterProps {
  filter: Filter
  setFilter: React.Dispatch<Filter>
}

export const DashboardFilter: FC<DashboardFilterProps> = ({
  filter,
  setFilter,
}) => {
  const [form] = Form.useForm()

  const dispatch = useDispatch()

  const storeIdentificationInfo = useSelector(
    (state: any) => state.store.identificationInfo
  )

  const profile = useProfile()

  useEffect(() => {
    if (profile == 'Owner') dispatch(getIdentificationInfo() as any)
  }, [profile])

  const handleSubmit = ({
    period,
    storeId,
  }: {
    period: Moment[]
    storeId: string
  }) => {
    setFilter({
      initialDateRange: period && period[0],
      finalDateRange: period && period[1],
      storeId,
    })
  }

  const isLoadingStores = useSelector(state =>
    RequestingSelector.selectRequesting(state, [REQUEST_IDENTIFICATION_INFO])
  )

  return (
    <Collapse defaultActiveKey={'filter'}>
      <Collapse.Panel header="Filtros" key={'filter'}>
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Row>
            <Col lg={{ span: 7, offset: 1 }} sm={24}>
              <Form.Item label="Periodo" name="period">
                <RangePicker
                  defaultValue={[
                    filter.initialDateRange,
                    filter.finalDateRange,
                  ]}
                  format={'DD/MM/YYYY'}
                  onKeyDown={maskedDate}
                />
              </Form.Item>
            </Col>
            {profile == 'Owner' && (
              <Col lg={{ span: 7, offset: 1 }} sm={24}>
                <Form.Item label="Loja" name="storeId">
                  <SelectCustom
                    allowClear={true}
                    loading={isLoadingStores}
                    options={convertIdentificationInfoToSelectOptions(
                      storeIdentificationInfo
                    )}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            <Col lg={{ span: 7, offset: 1 }} sm={24}>
              <PrimaryButton htmlType="submit" type="primary">
                Filtrar
              </PrimaryButton>
            </Col>
          </Row>
        </Form>
      </Collapse.Panel>
    </Collapse>
  )
}
