import produce from 'immer'
import { Reducer } from 'react'
import baseReducer from '../../utilities/base-reducer'
import * as Actions from './seller-action'

export interface SellerType {
  searchResponse: SearchResponse
  identificationInfo: IdentificationInfoResponse[]
}

export enum OrderStatus {
  Generated,
  AppliedWaitingValidation,
  Confirmed,
  Reversed,
}

export const OrderStatusLabelName = {
  [OrderStatus.Generated]: 'Gerado',
  [OrderStatus.AppliedWaitingValidation]: 'Aplicado - Aguardando Validação',
  [OrderStatus.Confirmed]: 'Confirmado',
}

export type SearchResponse = {
  numberOfRows: number
  data: {
    name: string
    cellphone: string
    document: string
    email: string
  }[]
}

export type IdentificationInfoResponse = {
  id: string
  name: string
}

const initialState = {
  searchResponse: {} as SearchResponse,
  identificationInfo: [],
}

export const SellerReducer = baseReducer(initialState, {
  [Actions.REQUEST_SEARCH_FINISHED](state: any, { payload }: any) {
    return produce(state, (draftState: SellerType) => {
      draftState.searchResponse = payload
    })
  },
  [Actions.REQUEST_GET_IDENTIFICATION_INFO_FINISHED](
    state: any,
    { payload }: any
  ) {
    return produce(state, (draftState: SellerType) => {
      draftState.identificationInfo = payload
    })
  },
}) as Reducer<
  SellerType,
  {
    type: any
    payload: any
  }
>
