import { ConfigProvider, Layout, notification } from 'antd';
import 'antd/dist/antd.css';
import { ConnectedRouter } from 'connected-react-router';
import { Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import WebFont from 'webfontloader';
import RouteEnum from './constants/RouteEnum';
import { Header } from './components/layout/headers/header';
import PageFallback from './components/page-fallback/PageFallback';

import HomePage from './views/Home/home-page';
import ptBr from 'antd/lib/locale/pt_BR';

import { NotificationActions } from '@aberto/react-common';
import { useDispatch, useSelector } from 'react-redux';
import { defaultTheme } from './@themes/defaultTheme';
import { GlobalStyle } from './global.styles';
import { ThemeProvider } from 'styled-components';
import StoreAuthentication from './views/store-authentication/store-authentication';
import TransactionList from './views/transaction-list/transaction-list';
import './legacy-css.css';
import { Dashboard } from './views/dashboard/dashboard';
import { ParticipantList } from './views/participant/participant-list';
import { ParticipantNotifyList } from './views/participant-notify/participant-notify-list';
import { ParticipantTransactionList } from './views/participant/participant-transaction-list';
import sellerList from './views/sellers/seller-list';
import sellerForm from './views/sellers/components/seller-form';
import Sales from './views/sales/sales';
import Faq from './views/faq/faq';
import Training from './views/training/training';
import SellersAvailable from './views/sellers-available/sellers-available';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { pdfjs } from 'react-pdf';
import Terms, { LoggedTerms } from './views/terms/terms';
import { withOnlyAuthenticated } from './context/authentication-provider';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

const { Content } = Layout;

const queryClient = new QueryClient();

WebFont.load({
  google: {
    families: ['Poppins: 100, 300, 700', 'Rubik: 400, 500', 'sans-serif'],
  },
});

const App = props => {
  const dispatch = useDispatch();
  const notificationItems = useSelector(state => state.notification.items);

  const removeNotificationById = notificationId => {
    dispatch(NotificationActions.removeById(notificationId));
  };

  useEffect(() => {
    if (notificationItems?.length) {
      notificationItems.forEach(({ id, message, description, type }) => {
        removeNotificationById(id);
        const notificationParams = {
          message,
          description,
        };
        switch (type) {
          case 'error':
            notification.error(notificationParams);
            break;
          case 'warning':
            notification.warning(notificationParams);
            break;
          default:
            notification.success(notificationParams);
            break;
        }
      });
    }
  }, [notificationItems]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyle />
        <div className="App">
          <ConnectedRouter history={props.history}>
            <ConfigProvider locale={ptBr}>
              <Layout id="site" style={{ height: '100%' }}>
                <Header />

                <Content>
                  <Suspense fallback={<PageFallback />}>
                    <Switch>
                      <Route
                        exact
                        path={[
                          RouteEnum.Login,
                          RouteEnum.ForgotPassword,
                          RouteEnum.ForgotPasswordInsertNewPassword,
                          RouteEnum.ConfirmPassword,
                        ]}
                        component={StoreAuthentication}
                      />
                      <Route
                        exact
                        path={[RouteEnum.SellerList]}
                        component={sellerList}
                      />
                      <Route
                        exact
                        path={[RouteEnum.SellerCreate, RouteEnum.SellerUpdate]}
                        component={sellerForm}
                      />
                      <Route
                        exact
                        path={[RouteEnum.ParticipantTransactionList]}
                        component={ParticipantTransactionList}
                      />
                      <Route
                        exact
                        path={RouteEnum.TransactionList}
                        component={TransactionList}
                      />
                      <Route
                        exact
                        path={RouteEnum.Dashboard}
                        component={Dashboard}
                      />
                      <Route
                        exact
                        path={RouteEnum.ParticipantList}
                        component={ParticipantList}
                      />
                      <Route
                        exact
                        path={RouteEnum.ParticipantNotifyList}
                        component={ParticipantNotifyList}
                      />
                      <Route exact path={RouteEnum.Sales} component={Sales} />
                      <Route exact path={RouteEnum.Faq} component={Faq} />
                      <Route
                        exact
                        path={RouteEnum.SellersAvailable}
                        component={SellersAvailable}
                      />
                      <Route
                        exact
                        path={RouteEnum.Training}
                        component={Training}
                      />
                      <Route
                        exact
                        path={RouteEnum.LoggedTerms}
                        component={() => <Terms isViewingOnly={true} />}
                      />
                      <Route path={RouteEnum.Home} component={HomePage} />
                    </Switch>
                  </Suspense>
                </Content>
              </Layout>
            </ConfigProvider>
          </ConnectedRouter>
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
