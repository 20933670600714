import Page from '../../components/layout/page';
import { withProfileAllowed } from '../../context/authentication-provider';
import { FC, useCallback, useEffect, useState } from 'react';
import { Button, Col, Row, Table, TablePaginationConfig, Tooltip } from 'antd';
import { GroupTitle } from '@aberto/react-common';
import { DefaultListContent } from '../../components/styled-components/default-list-content';
import { InputForm } from '../../global.styles';
import { defaultDebounce } from '../../utilities/DebounceUtility';
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import styled, { css } from 'styled-components';
import { ParticipantNotify } from '../../@types/participant';
import { formatCurrency, formatCellphone } from '../../utilities/formatters';
import moment from 'moment';
import { useGetParticipantNotifyList } from './useParticipantsNotify';
import { useGetTerm } from '../../api/queries/useGetTerm';

const CustomButton = styled(Button)`
  &.ant-btn-link {
    background: green;
    color: white;
    border-radius: 9999px;
  }
  &.ant-btn-link.notified {
    background: transparent;
    color: green;
  }
  &.ant-btn-link:hover {
    background: darkgreen;
  }
  &.ant-btn-link.notified:hover {
    background: transparent;
    color: darkgreen;
  }
`;

const ObsText = styled.div``;

const ParticipantNotifyListComponent: FC = () => {
  const { getParticipantNotifyList, markParticipantAsNotified } =
    useGetParticipantNotifyList();
  const { data: termsData, isError } = useGetTerm();
  const [isDescendingSort, setIsDescendingSort] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [participantsList, setParticipantsList] = useState<{
    numberOfRows: number;
    data: ParticipantNotify[];
  }>({
    numberOfRows: 0,
    data: [],
  });
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);

    const loadParticipantsNotify = async () => {
      const { data } = await getParticipantNotifyList({
        search,
        page,
        storeId: termsData?.storeId,
      });

      setParticipantsList(data);
      setLoading(false);
    };

    if (termsData?.storeId) {
      loadParticipantsNotify();
    }
  }, [search, page, termsData]);

  const handleChangeSortOnClick = () => {
    setIsDescendingSort(!isDescendingSort);
  };

  const handleOnChangePagination = (pagination: TablePaginationConfig) => {
    setPage(pagination.current || 1);
  };

  const handleSearchOnChange = (event: any) => {
    setSearch(event.target.value ?? '');
  };

  const formatNotification = (participant: ParticipantNotify) => {
    return encodeURI(`Olá, ${participant.name}!
Aqui é da unidade *${
      termsData?.storeName
    }*. Você possui um saldo de *${formatCurrency(
      participant.balance
    )}* em cashback em nossa unidade, com validade até *${moment(
      participant.nextExpiration
    ).format(
      'DD/MM/YYYY'
    )}*. Gostaríamos de convidar você para vir até a loja e aproveitar seu benefício!
Esperamos vê-lo em breve!

Atenciosamente,
Equipe *${termsData?.storeName}*`);
  };

  const markAsNotified = useCallback(
    async (participant: ParticipantNotify) => {
      window.open(
        `https://wa.me/+55${participant.cellphone}?text=${formatNotification(
          participant
        )}`,
        '_blank'
      );

      setParticipantsList(prev => {
        return {
          numberOfRows: prev?.numberOfRows!,
          data: prev.data.map(newPrev =>
            newPrev.participantId === participant.participantId
              ? {
                  ...newPrev,
                  ...{ notified: true },
                }
              : newPrev
          ),
        };
      });

      await markParticipantAsNotified(participant.participantId);
    },
    [participantsList]
  );

  const columns = [
    {
      title: (
        <>
          Nome
          <Button
            icon={
              isDescendingSort ? (
                <SortDescendingOutlined />
              ) : (
                <SortAscendingOutlined />
              )
            }
            type="text"
            onClick={handleChangeSortOnClick}
          />
        </>
      ),
      dataIndex: 'name',
      render: (title: string) => (title ? title : '-'),
    },
    {
      title: 'Celular',
      dataIndex: 'cellphone',
      width: '150px',
      render: formatCellphone,
    },
    {
      title: (
        <>
          Saldo
          <Button
            icon={
              isDescendingSort ? (
                <SortDescendingOutlined />
              ) : (
                <SortAscendingOutlined />
              )
            }
            type="text"
            onClick={handleChangeSortOnClick}
          />
        </>
      ),
      dataIndex: 'balance',
      width: '150px',
      render: (balance: number) => formatCurrency(balance),
    },
    {
      title: 'Próxima expiração',
      dataIndex: 'nextExpiration',
      width: '170px',
      render: (date: string) => moment(date).format('DD/MM/YYYY'),
    },
    {
      title: 'Notificar',
      render: (participant: ParticipantNotify) => {
        return (
          <Row style={{ justifyContent: 'center' }}>
            <Tooltip title={`Notificar ${participant.name}`}>
              <CustomButton
                type="link"
                className={participant.notified ? 'notified' : ''}
                icon={<WhatsAppOutlined />}
                onClick={() => markAsNotified(participant)}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <Page>
        <Row style={{ textAlign: 'center' }}>
          <Col
            md={{ span: 12, offset: 6 }}
            xs={24}
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <DefaultListContent>
              <GroupTitle>Clientes</GroupTitle>
              <br />
              <ObsText>
                * Você poderá <b>comunicar o cliente</b> via WhatsApp após{' '}
                <b>48h</b> depois da concessão do Cashback.
              </ObsText>
              <br />
              <InputForm
                allowClear={true}
                onChange={defaultDebounce(handleSearchOnChange)}
                style={{ width: '90%' }}
                placeholder="Buscar por Nome ou celular"
              />
              <Table
                columns={columns}
                loading={isLoading}
                dataSource={participantsList?.data}
                onChange={handleOnChangePagination}
                pagination={{
                  total: participantsList?.numberOfRows,
                }}
                rowKey={'participantId'}
              />
            </DefaultListContent>
          </Col>
        </Row>
      </Page>
    </>
  );
};

export const ParticipantNotifyList = withProfileAllowed(
  ParticipantNotifyListComponent
);
