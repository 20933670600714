import { produce } from 'immer'
import { BaseReducer } from '@aberto/react-common'
import * as Actions from './store-actions'

export const initialState = {
  identificationInfo: [],
}

export const storeReducer = new BaseReducer(initialState, {
  [Actions.REQUEST_IDENTIFICATION_INFO_FINISHED](state: any, { payload }: any) {
    return produce(state, (draft: any) => {
      draft.identificationInfo = payload.data
    })
  },
})
