import { FC, useContext, useEffect } from 'react'
import { RequestingSelector } from '@aberto/react-common'
import { Col, Divider, Row } from 'antd'
import { PrimaryButton, SecondaryButton } from '../../../global.styles'
import * as Actions from '../../../stores/order/order-action'
import { ColNewOrder, NewOrderButtonContainer } from '../order.styles'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import RouteEnum from '../../../constants/RouteEnum'
import { OrderSummary } from '../components/order-summary'
import { OrderStepContext } from './context/order-step-context'
import { useSelector } from 'react-redux'
import { clearParticipant } from '../../../stores/participant/participant-action'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDollar,
  faCircleCheck,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { isApiException } from '../../../api/promo-api'
import { keyPressEventWrapper } from '../../../helpers/key-press-event-wrapper'
import * as ParticipantSelector from '../../../selectors/participant/participant-selector'

const BenefitOverviewStepItem: FC<{
  isLastStep?: boolean
  isError?: boolean
}> = ({ isLastStep = false, isError = false }) => {
  const dispatch = useDispatch()
  const orderStepContext = useContext(OrderStepContext)

  const participant = useSelector(state =>
    ParticipantSelector.currentParticipant(state)
  ) as any

  const isLoading = useSelector(state =>
    RequestingSelector.selectRequesting(state, [
      Actions.REQUEST_CALCULATE_CASHBACK,
    ])
  )

  const isLoadingFinish = useSelector(state =>
    RequestingSelector.selectRequesting(state, [Actions.REQUEST_APPLY_CASHBACK])
  )

  const order = useSelector((state: any) => state.order.order)

  const handleCancel = () => {
    dispatch(push(RouteEnum.CellphoneOrderStep))

    dispatch(Actions.clearCurrentOrder() as any)
    dispatch(Actions.clearOrder() as any)
    dispatch(clearParticipant() as any)
  }

  const handleGoToUserData = async () => {
    const applyResponse = await dispatch(Actions.applyCashback(order.id) as any)

    if (isApiException(applyResponse)) {
      orderStepContext.setApiError()
      return
    }

    if (applyResponse.data.shouldUseVerificationCode)
      dispatch(push(RouteEnum.UserPinOrderStep))
    else {
      if (!participant || participant.status === 1)
        dispatch(push(RouteEnum.UserInfoStep))
      else dispatch(push(RouteEnum.BenefitSummary))
    }
  }

  useEffect(() => {
    if (
      (orderStepContext.purchasePrice && orderStepContext.cellphone) ||
      isError
    ) {
    } else dispatch(push(RouteEnum.CellphoneOrderStep))
  }, [])

  useEffect(() => {
    if (isError && !orderStepContext.hasApiError)
      dispatch(push(RouteEnum.CellphoneOrderStep))
  }, [isError])

  const event = async (event: KeyboardEvent) => {
    if (document.activeElement == document.body) {
      if (event.key == 'Backspace')
        keyPressEventWrapper(event, handleCancel, 'Backspace')
      else {
        switch (window.location.pathname) {
          case RouteEnum.BenefitOverviewOrderStep:
            await keyPressEventWrapper(event, handleGoToUserData, 'Enter')
            break
          case RouteEnum.BenefitErrorStep:
          case RouteEnum.BenefitSummary:
            keyPressEventWrapper(event, handleCancel, 'Enter')
            break
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', event)

    return () => {
      document.removeEventListener('keydown', event)
    }
  }, [order])

  return (
    <>
      <Row onKeyDown={event => console.log(event.key)}>
        <Col span={24}>
          <span style={{ fontSize: '20px', color: '#001D42' }}>
            Valor da compra do cliente sem descontos:{' '}
          </span>
          <span style={{ fontSize: '20px', fontWeight: '500' }}>
            {(order?.value || 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        </Col>
      </Row>
      <Row style={{ marginTop: '5px' }}>
        <Col span={24}>
          <span style={{ fontSize: '20px', color: '#001D42' }}>
            Valor de bônus resgatado / desconto:
          </span>
          <span style={{ fontSize: '20px', fontWeight: '500' }}>
            {' '}
            {(order?.benefit?.burn?.value || 0).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            }) || 0}
          </span>
        </Col>
      </Row>
      <Divider style={{ margin: 9 }} />
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <span style={{ fontSize: '20px', color: '#001D42' }}>
            Valor final a pagar:
          </span>
          <span style={{ fontSize: '20px', fontWeight: '500' }}>
            {' '}
            {(order?.value - order?.benefit?.burn?.value || 0)?.toLocaleString(
              'pt-br',
              {
                style: 'currency',
                currency: 'BRL',
              }
            ) || 0}
          </span>
        </Col>
      </Row>
      <Row style={{ marginTop: '15px', marginBottom: '-15px' }}>
        <Col span={24} style={{ textAlign: 'center' }}>
          {isError && (
            <FontAwesomeIcon icon={faXmark} size="2x" color="tomato" />
          )}
          {isLastStep && !isError && (
            <FontAwesomeIcon icon={faCircleCheck} size="2x" color="#27f3b5" />
          )}

          {!isLastStep && !isError && (
            <FontAwesomeIcon icon={faDollar} size="2x" color="#27f3b5" />
          )}
          <span
            style={{ fontSize: '20px', color: '#001D42', marginLeft: '10px' }}
          >
            {isError && 'Erro ao registrar a venda!'}
            {isLastStep && !isError && 'Venda registrada com sucesso!'}
            {!isLastStep &&
              !isError &&
              order?.benefit?.messageTitle?.replaceAll('@', ' ')}
          </span>
        </Col>
      </Row>
      <OrderSummary
        registerSale={handleGoToUserData}
        isLoading={isLoading}
        visible={true}
        isLastStep={isLastStep}
        isError={isError}
      />

      <Row>
        {isLastStep || isError ? (
          <ColNewOrder md={10} offset={7}>
            <NewOrderButtonContainer>
              <PrimaryButton
                loading={false}
                onClick={() => dispatch(push(RouteEnum.CellphoneOrderStep))}
              >
                Nova venda
              </PrimaryButton>
            </NewOrderButtonContainer>
          </ColNewOrder>
        ) : (
          <>
            <ColNewOrder md={{ span: 10, offset: 2 }}>
              <NewOrderButtonContainer alignCenter>
                <SecondaryButton onClick={handleCancel}>
                  Cancelar
                </SecondaryButton>
              </NewOrderButtonContainer>
            </ColNewOrder>
            <ColNewOrder md={10}>
              <NewOrderButtonContainer>
                <PrimaryButton
                  loading={isLoading || isLoadingFinish}
                  onClick={handleGoToUserData}
                >
                  Continuar e obter o bônus
                </PrimaryButton>
              </NewOrderButtonContainer>
            </ColNewOrder>
          </>
        )}
      </Row>
    </>
  )
}

export default BenefitOverviewStepItem
